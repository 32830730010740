import _ from "lodash";
import Api from "@/service/api/_mix_api";

const state = {
    student_agency: [],
    student_agency_payers: []
};
const mutations = {
    setStudentAgency(state, payload) {
        state.student_agency = [...payload];
    },
    setStudentAgencyPayers(state, payload) {
        state.student_agency_payers = [...payload];
    },
    toggleCollapse(state, index) {
        state.student_agency[index].isCollapsed = !state.student_agency[index].isCollapsed;
    }
};
const actions = {
    studentAgency(context, payload) {
        const isForced = !!payload;
        if (isForced || _.isEmpty(context.getters.student_agency)) {
            return Api.student_agency.index(payload.uuid).then((response) => {
                if (response.status === 200) {
                    if(response.data.data.length > 0) {
                        const studentAgencies = response.data.data.map(item => ({
                            ...item,
                            isCollapsed: true // Initially collapsed
                        }));

                        context.commit('setStudentAgency', [...studentAgencies]);
                    } else {
                        context.commit('setStudentAgency', [...response.data.data]);
                    }
                }
            })
        }
    },
    studentAgencyPayers(context, id) {
        return Api.student_agency.payers(id).then((response) => {
            if (response.status === 200) {
                if(response.data.data.length > 0) {
                    context.commit('setStudentAgencyPayers', [...response.data.data]);
                }
            }
        })
        
    }
};
const getters = {
    student_agency: state => state.student_agency || [],
    student_agency_payers: state => state.student_agency_payers || []
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}