import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/files",
        name: "files",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"File Management", breadcrumb: 'Files' },
        component: lazyLoad("protected/file/file-list")
    },
    {
        path: "/add-file/:folder_id?",
        name: "add-file",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"File Management", breadcrumb: {
                label: "Add New File",
                parent: "files"
            } },
        component: lazyLoad("protected/file/file-add")
    },
    {
        path: "/edit-file/:file_id",
        name: "edit-file",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"File Management", breadcrumb: {
                label: "Edit File",
                parent: "files"
            }},
        component: lazyLoad("protected/file/file-edit")
    },
    {
        path: "/delete-file",
        name: "delete-file",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"File Management", breadcrumb: {
                label: "Deleted Files",
                parent: "files"
            } },
        component: lazyLoad("protected/file/file-delete")
    },
];
