import Api from "@/service/api/_mix_api";
import _ from "lodash";
import {softDelete, search, sorting} from '@/service/localFilters';

const state = {
    combine_charges: [],
    funders: [],
    localFilters: {},
};
const mutations = {
    setCombineCharges(state, payload) {
        state.combine_charges = payload;
    },
    setFunders(state, payload) {
        state.funders = [...payload];
    },
    setLocalFilters(state, payload) {
        state.localFilters = {...payload};
    }
};
const actions = {
    updateCombineCharges(context, payload) {
        context.commit('setCombineCharges', [...payload]);
    },
    fetchCombineCharges(context, payload) {
        const isForced = !!payload;
        if (isForced || _.isEmpty(context.getters.combine_charges)) {
            return Api.billing.getCombineCharges().then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    context.commit('setCombineCharges', [...response.data.data]);
                }
            })
        }
    },
    fetch(context, payload) {
        const isForced = !!payload;
        if (isForced || _.isEmpty(context.getters.funders)) {
            return Api.funder.index().then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    context.commit('setFunders', [...response.data.data]);
                }
            })
        }
    }
};
const getters = {
    getCombineCharges: state => {
        return state.combine_charges;
    },
    funders: (state) => state.funders || [],
    fundersWithFilters: state => {
        const filter = [
            // Trashed or published
            (collection) => softDelete(collection, state.localFilters),
            // Search
            (collection) => search(collection, state.localFilters, ['name', 'email', 'phone','fax']),
            // Sorting
            (collection) => sorting(collection, state.localFilters)
        ];
        return filter.reduce((collection, value) => {
            return value(collection);
        }, (state.funders || []));
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}