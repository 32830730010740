import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/graph/abc-behavior",
        name: "graph-abc-behavior",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"certification-add", breadcrumb: 'Graph' },
        component: lazyLoad("protected/Graph/StudentsAbcBehavior")
    },
    {
        path: "/graph/student-behavior",
        name: "graph-student-behavior",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"certification-add", breadcrumb: 'Graph' },
        component: lazyLoad("protected/Graph/StudentsBehavior")
    },
    {
        path: "/graph/student-skill-behavior",
        name: "graph-student-skill-behavior",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Students Behavior Graph", breadcrumb: 'Graph' },
        component: lazyLoad("protected/Graph/StudentSkillBehavior")
    },
    {
        path: "/graph/student-note-helper-graph",
        name: "student-note-helper-graph",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Students Note Helper Graph", breadcrumb: 'Graph' },
        component: lazyLoad("protected/Graph/PercentageGraph")
    },
    {
        path: "/graph/fluency-rate",
        name: "graph-student-fluency-rate",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"certification-add", breadcrumb: 'Graph' },
        component: lazyLoad("protected/Graph/FluencyGraph")
    },
    {
        path: "/graph/data-import-list",
        name: "graph-data-import-list",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"certification-add", breadcrumb: 'Graph' },
        component: lazyLoad("protected/Graph/GraphDataImportList")
    },
];
