import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/auto-generate-session",
        name: "auto-generate-session",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"generate-session-note-view", breadcrumb: 'Auto Generate Session Notes' },
        component: lazyLoad("protected/organization/AutoGenerateSessionNotes/AutoGenerateSession")
    },
    {
        path: "/sessions-queue",
        name: "sessions-queue",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Auto Generate Session", breadcrumb: 'Sessions Queue' },
        component: lazyLoad("protected/organization/AutoGenerateSessionNotes/SessionsQueue")
    },
    {
        path: "/auto-generate-session-import",
        name: "auto-generate-session-import",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"session-data-import-view", breadcrumb: 'Session Data Import' },
        component: lazyLoad("protected/organization/AutoGenerateSessionNotes/AutoGenerateImport")
    },
    {
        path: "/pending-target-data",
        name: "pending-target-data",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Auto Generate Session", breadcrumb: 'Pending Target Data' },
        component: lazyLoad("protected/organization/AutoGenerateSessionNotes/PendingTargetData")
    },
    {
        path: "/session-report",
        name: "session-report",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Auto Generate Session", breadcrumb: 'Session Report' },
        component: lazyLoad("protected/organization/AutoGenerateSessionNotes/SessionReport")
    },
    {
        path: "/invoice-import-list",
        name: "invoice-import-list",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Auto Generate Session" },
        component: lazyLoad("protected/organization/AutoGenerateSessionNotes/SessionDatas")
    },
    {
        path: "/sheets-link",
        name: "sheets-link",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Auto Generate Session", breadcrumb: 'Sheets Link' },
        component: lazyLoad("protected/organization/AutoGenerateSessionNotes/SheetsLink")
    },
];
