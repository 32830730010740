import _ from "lodash";
import Api from "@/service/api/_mix_api";
import httpErrorHandler from "@/httpErrorHandler";

const state = {
    isFetching: false,
    relationshipToInsured: [],
};
const mutations = {
    setIsFetching(state, payload) {
        state.isFetching = !!payload;
    },
    setRelationshipToInsured(state, data) {
        state.relationshipToInsured = data;
    },
};
const actions = {
    getRelationship(context, query) {
        const isForced = !!query;
        if (isForced || _.isEmpty(context.getters.relationshipToInsured)) {
            context.commit('setIsFetching', true);
            Api.relationship_to_insured.index(query)
            .then( response => {
                if (response.status >= 200 && response.status < 300) {
                    context.commit('setRelationshipToInsured', [... response.data.data])
                }
            }).finally(() => context.commit('setIsFetching', false))
        }
    },
};
const getters = {
    isFetching: state => state.isFetching || false,
    relationshipToInsured: state => state.relationshipToInsured || [],
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}