import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/add-location/",
        name: "add-location",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"certification-add", breadcrumb: {
                label: "Add New Location",
                parent: "locations"
            } },
        component: lazyLoad("protected/organization/Location/Add-location")
    },
    {
        path: "/edit-location/:location_id",
        name: "edit-location",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"certification-edit", breadcrumb: {
                label: "Edit Location",
                parent: "locations"
            } },
        component: lazyLoad("protected/organization/Location/Edit-location")
    },
    {
        path: "/locations/",
        name: "locations",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"certification-list", breadcrumb: 'Locations'},
        component: lazyLoad("protected/organization/Location/List-location")
    },
    {
        path: "/delete-locations",
        name: "delete-locations",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"certification-list", breadcrumb: {
                label: "Delete Locations",
                parent: "locations"
            }},
        component: lazyLoad("protected/organization/Location/Delete-location")
    },
];
