import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/assessment/category/:parent_id?",
        name: "add-assessment-category",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"assessments-category-add" },
        component: lazyLoad("protected/organization/AssessmentCategory/add-category")
    },
    {
        path: "/assessment/categorylist",
        name: "assessment-category",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"assessments-category-list" },
        component: lazyLoad("protected/organization/AssessmentCategory/category")
    },
    {
        path: "/assessment/child-category/:parent_id",
        name: "child-assessment-category",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"assessments-category-add" },
        component: lazyLoad("protected/organization/AssessmentCategory/child-category")
    },
    {
        path: "/assessment/category/edit-category/:cat_id",
        name: "edit-assessment-category",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"assessments-category-edit"},
        component: lazyLoad("protected/organization/AssessmentCategory/edit-category")
    },
    {
        path: "/assessment/category/delete",
        name: "delete-assessment-category",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"assessments-category-list" },
        component: lazyLoad("protected/organization/AssessmentCategory/category-delete")
    },
];
