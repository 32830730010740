import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import createMutationsSharer from "vuex-shared-mutations";

import sidenav from "@/store/modules/sidenav";
import auth from './store/modules/auth'
import helper from './store/modules/helper'
import help_desk from "./store/modules/help_desk";
import student from "./store/modules/student";
import week_days from "@/store/modules/week_days";
import language from "@/store/modules/language"
import global_notifications from "@/store/modules/global_notifications";
// import student_list from "./store/modules/student_list.js.deprecated";
import crud_filters from "@/store/modules/crud_filters";
import staff_list from "./store/modules/staff_list";
import sample_files from "./store/modules/sample_files";
import invoice from "./store/modules/invoice";
import role from "./store/modules/role";
import funder from "./store/modules/funder";
import funder_details from "./store/modules/funder_details";
import agency from "./store/modules/agency.js";
import agency_details from "@/store/modules/agency_details";
import agency_type from "./store/modules/agency_type.js";
import taxonomy from "./store/modules/taxonomy.js";
import timezone from "./store/modules/timezone";
import certification from "./store/modules/certification";
import place_of_service from "./store/modules/place_of_service";
import service from "./store/modules/service";
import service_line from "./store/modules/service_line";
import student_status from "@/store/modules/student_status";
import student_details from "@/store/modules/student_details";
import states from "@/store/modules/states";
import gender from "@/store/modules/gender";
import user_type from "@/store/modules/user_type";
import tabs_for_soft_delete from "@/store/modules/tabs_for_soft_delete";
import experience_type from "@/store/modules/experience_type";
import staff_details from "@/store/modules/staff_details";
import user_status from "@/store/modules/user_status";
import pay_code from "@/store/modules/pay_code";
import kareo_funder from "@/store/modules/kareo_funder";
import service_details from "@/store/modules/service_details";
import service_line_details from "@/store/modules/service_line_details";
import authorization from "@/store/modules/authorization";
import certification_type from "@/store/modules/certification_type";
import certification_details from "@/store/modules/certification_details";
import degree_type from "@/store/modules/degree_type";
import student_assign_staff from "@/store/modules/student_assign_staff";
import student_payers from "@/store/modules/student_payers";
import student_authorization from "@/store/modules/student_authorization";
import staff_assign_student from "@/store/modules/staff_assign_student";
import staff_certification from "@/store/modules/staff_certification";
import modules from "@/store/modules/modules";
import payer_type from "@/store/modules/payer_type";
import coverage_type from "@/store/modules/coverage_type";
import agency_payer from "@/store/modules/agency_payer";
import payroll_type from "@/store/modules/payroll_type";
import referring_provider from "@/store/modules/referring_provider";
import student_referring_provider from "@/store/modules/student_referring_provider";
import student_agency from "@/store/modules/student_agency";
import router from "@/store/modules/router";
import funder_plan from "@/store/modules/funder_plan";
import delegated_permission from "@/store/modules/delegated_permission";
import permissions from "@/store/modules/permissions";
import user_group from "@/store/modules/user_group";
import marital_status from "@/store/modules/marital_status";
import relationship_to_insured from "@/store/modules/relationship_to_insured";
import release_info_confirmation_type from "@/store/modules/release_info_confirmation_type";
import co_payement_type from "@/store/modules/co_payement_type";
import insurance_type from "@/store/modules/insurance_type";
import authorization_submission_type from "@/store/modules/authorization_submission_type";
import authorization_distribution_type from "@/store/modules/authorization_distribution_type";
import authorization_status from "@/store/modules/authorization_status";
import interval from "@/store/modules/interval";
import rendering_provider_type from "@/store/modules/rendering_provider_type";
import student_services from "@/store/modules/student_services";
import session_group from "@/store/modules/session_group";

Vue.use(Vuex)

export default new Vuex.Store({
    strict: true,
    modules: {
        global_notifications,
        router,
        crud_filters,
        auth: auth,
        sidenav,
        helper: helper,
        help_desk,
        student,
        // student_list, // deprecated
        staff_list,
        sample_files,
        invoice,
        permissions,
        role,
        user_group,
        funder,
        funder_details,
        agency,
        agency_details,
        agency_type,
        taxonomy,
        language,
        certification,
        timezone,
        place_of_service,
        service,
        service_line,
        student_status,
        student_details,
        states,
        gender,
        user_type,
        tabs_for_soft_delete,
        experience_type,
        staff_details,
        user_status,
        pay_code,
        kareo_funder,
        service_details,
        service_line_details,
        authorization,
        certification_type,
        degree_type,
        certification_details,
        student_assign_staff,
        student_payers,
        student_authorization,
        staff_assign_student,
        staff_certification,
        modules,
        payroll_type,
        payer_type,
        coverage_type,
        agency_payer,
        week_days,
        referring_provider,
        student_referring_provider,
        student_agency,
        funder_plan,
        marital_status,
        relationship_to_insured,
        release_info_confirmation_type,
        co_payement_type,
        insurance_type,
        authorization_submission_type,
        authorization_distribution_type,
        authorization_status,
        interval,
        delegated_permission,
        rendering_provider_type,
        student_services,
        session_group
    },
    plugins: [
        createPersistedState({
            paths: [
                'auth',
                'helper',
                'crud_filters'
            ]
        }),
        createMutationsSharer({
            predicate: [
                "setProgress",
                "setUser",
                "setToken",
                "setScopes",
                "resetAll"
            ]
        })
    ],
})
