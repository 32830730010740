import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/session-form/category/add/:parent_id?",
        name: "add-sessionform-category",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"certification-add", breadcrumb: {
                label: "Add Note Helper Category",
                parent: "sessionform-category"
            } },
        component: lazyLoad("protected/SessionTemplates/Category/Add-Category")
    },
    {
        path: "/session-form/category/edit/:cid",
        name: "edit-sessionform-category",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"certification-edit", breadcrumb: {
                label: "Edit Note Helper Category",
                parent: "sessionform-category"
            } },
        component: lazyLoad("protected/SessionTemplates/Category/Edit-Category")
    },
    {
        path: "/session-form/category/:parent_id?",
        name: "sessionform-category",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"certification-list", breadcrumb: 'Note Helper Category'},
        component: lazyLoad("protected/SessionTemplates/Category/List-Category")
    },
    {
        path: "/session-form/category/delete",
        name: "delete-sessionform-category",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"certification-list"},
        component: lazyLoad("protected/SessionTemplates/Category/Delete-Category")
    },
];
