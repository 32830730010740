import HTTP from "@/service/api/_axios_setup";

export default {
    index: (query) => HTTP.get('/user-group', {params: (query || null)}),
    store(payload) {
        return HTTP.post('/user-group', payload)
    },
    update(uuid, payload) {
        return HTTP.put('/user-group/'+uuid, payload)
    },
    destroy(uuid) {
        return HTTP.delete('/user-group'+uuid)
    },
    show: (uuid) => HTTP.get('/user-grou/'+uuid),
    user_type: (query) => HTTP.get('/user-type', {params: (query || null)}),
    userStatus: (query) => HTTP.get('/user-status', {params: (query || null)})
}
