<script>
import _ from "lodash";

const defaultAvatar = require('@/assets/images/user.png');
export default {
  name: "Avatar",
  props: {
    src: {
      type: String,
      default() {
        return defaultAvatar;
      }
    },
    size: {
      type: String,
      default: 'size-md',
      validate(size) {
        return _.isNull(size) || ['size-xs','size-sm', 'size-md', 'size-xl'].includes(size);
      }
    }
  },
  computed: {
    avatar() {
      if (!_.isNull(this.src) && _.trim(this.src) !== "") {
        return this.src;
      }
      return defaultAvatar;
    }
  }
}
</script>

<template>
  <div class="avatar-image" :class="size">
    <div class="avatar-image-container">
      <img :src="avatar" alt="Avatar" />
    </div>
  </div>
</template>

<style scoped>

</style>