import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/target-accuracy",
        name: "target-accuracy",
        meta: { layout: "ProtectedVue", middleware: "auth", sidebar: "profile", user_permission: "hcodes-list", breadcrumb: 'Target Acuracy' },
        component: lazyLoad("protected/organization/TargetAccuracy/target-accuracy-list")
    },
    {
        path: "/add-target-accuracy",
        name: "add-target-accuracy",
        meta: { layout: "ProtectedVue", middleware: "auth", sidebar: "profile", user_permission: "hcodes-add", breadcrumb: {
                label: "Add New Target Accuracy",
                parent: "target-accuracy"
            } },
        component: lazyLoad("protected/organization/TargetAccuracy/target-accuracy-add")
    },
    {
        path: "/edit-target-accuracy/:ta_id",
        name: "edit-target-accuracy",
        meta: { layout: "ProtectedVue", middleware: "auth", sidebar: "profile", user_permission: "hcodes-edit", breadcrumb: {
                label: "Edit Target Accuracy",
                parent: "target-accuracy"
            } },
        component: lazyLoad("protected/organization/TargetAccuracy/target-accuracy-edit")
    },
    {
        path: "/delete-target-accuracy",
        name: "delete-target-accuracy",
        meta: { layout: "ProtectedVue", middleware: "auth", sidebar: "profile", user_permission: "hcodes-list", breadcrumb: {
                label: "Deleted Target Acuracy",
                parent: "target-accuracy"
            } },
        component: lazyLoad("protected/organization/TargetAccuracy/target-accuracy-delete")
    },
];
