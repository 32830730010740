const state = {
    status: {
        'student.index': false
    }
};
const mutations = {
    setStatus(state, {index, status}) {
        state.status = {
            ...state.status,
            [index]: !!status
        }
    }
};
const actions = {

};
const getters = {
    status: (state) => (index) => index && state.status.hasOwnProperty(index) ? state.status[index] : false
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
