import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/403",
        name: "Error403",
        component: lazyLoad('pages/errors/Error403')
    },
    {
        path: "*",
        name: "404",
        component: lazyLoad('pages/errors/Error404')
    }
];
