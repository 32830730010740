import _ from "lodash";
import Api from "@/service/api/_mix_api";

const state = {
    serviceLine: {}
};
const mutations = {
    setServiceLine(state, payload) {
        state.serviceLine = {...payload};
    }
};
const actions = {
    fetch(context, payload) {
        const isForced = !!(payload?.forced || false);
        if (isForced || _.isEmpty(context.getters.serviceLine)) {
            return Api.service_line.show(payload.id).then((response) => {
                if (response.status === 200) {
                    context.commit('setServiceLine', {...response.data.data});
                }
            })
        }
    }
};
const getters = {
    serviceLine: (state) => state.serviceLine || {}
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
