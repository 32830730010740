import { lazyLoad } from "@/routers/utils/helper";

export const routes = [
    {
        path: "/pay-code",
        name: "pay-code.index",
        meta: { middleware: "auth", user_permission: null},
        component: lazyLoad("pages/pay-code/Index")
    },
    {
        path: "/pay-code/create",
        name: "pay-code.create",
        meta: {
            middleware: "auth", user_permission: null
        },

        component: lazyLoad("pages/pay-code/parts/index/Add-pay-code")
    },
    {
        path: "/pay-code/:pay_code_id",
        name: "pay-code.edit",
        redirect: { name: 'pay-code.show' },
        meta: { middleware: "auth", user_permission: null},
        component: lazyLoad("pages/pay-code/show/Layout"),
        children: [
            {
                path: "",
                name: "pay-code.show",
                meta: { middleware: "auth", user_permission: null},
                component: lazyLoad('pages/pay-code/parts/index/Edit-pay-code')
            },
        ]
    },
];
