<script>
// import { UAParser } from 'ua-parser-js';
import HelpDeskModal from "@/components/commons/HelpDesk/HelpDeskModal.vue";
import {mapState} from "vuex";
import HelpDeskEditScreenshot from "@/components/commons/HelpDesk/HelpDeskEditScreenshot.vue";
import HelpDeskNotification from "@/components/commons/HelpDesk/HelpDeskNotification.vue";
export default {
  name: "HelpDeskWidget",
  components: {HelpDeskNotification, HelpDeskEditScreenshot, HelpDeskModal},
  computed: {
    ...mapState({
      isCapturing: (state) => state.help_desk.helpDeskFormCapturing,
      isEditingCaptured: (state) => state.help_desk.helpDeskFormEnableScreenshotEdit,
      notification: (state) => state.help_desk.helpDeskFormNotification,
      modalVisibility: (state) => {
        return state.help_desk.helpDeskFormIsVisible &&
            !state.help_desk.helpDeskFormCapturing &&
            !state.help_desk.helpDeskFormEnableScreenshotEdit;
      },
    })
  },
  methods: {
    showSendFeedbackForm() {
      this.$store.dispatch('showHelpDeskForm');
    }
  }
}
</script>

<template>
  <div class="app-helpdesk-widget">
    <div class="app-helpdesk-notification-area" v-if="notification">
      <HelpDeskNotification />
    </div>
    <div class="app-helpdesk-widget-form-area" v-if="modalVisibility">
      <HelpDeskModal />
    </div>
    <div class="app-helpdesk-widget-edit-screenshot" v-if="isEditingCaptured">
      <HelpDeskEditScreenshot />
    </div>
<!--    <div class="app-helpdesk-widget-quick-action" v-if="!isCapturing && !isEditingCaptured">-->
<!--      <button type="button" class="btn-helpdesk" @click.prevent="showSendFeedbackForm">-->
<!--        <i class="fa fa-question-circle" aria-hidden="true"></i>-->
<!--        <span class="ml-2">Send feedback</span>-->
<!--      </button>-->
<!--    </div>-->
  </div>
</template>

<style scoped>

</style>
