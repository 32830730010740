import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/mass-goal-import",
        name: "mass-goal-import",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Goal Import", breadcrumb: 'Goal Import' },
        component: lazyLoad("protected/organization/Goal/mass-goal-import-list")
    },
    {
        path: "/mass-goal-edit/:goal_id",
        name: "mass-goal-edit",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Goal Import", breadcrumb: {
                label: "Mass Goal Edit",
                parent: "mass-goal-import"
            } },
        component: lazyLoad("protected/organization/Goal/mass-goal-import-edit")
    },
];
