import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/error-list",
        name: "error-list",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Error List", breadcrumb: 'Error List' },
        component: lazyLoad("protected/ErrorListing/ErrorList")
    },
    {
        path: "/template-short-code",
        name: "template-short-code",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Template Short Code", breadcrumb: 'Short Code' },
        component: lazyLoad("protected/Goal/ConversionV2/ShortCode")
    },
    {
        path: "/goal-generator/conversion-template-list",
        name: "conversion-template-list",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"TP Conversion V-II", breadcrumb: 'Conversion Template' },
        component: lazyLoad("protected/Goal/ConversionV2/ConversionTemplateListV2")
    },
    {
        path: "/goal-generator/add-conversion-template-list",
        name: "add-conversion-template-list",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"TP Conversion V-II", breadcrumb: 'Add Conversion Template' },
        component: lazyLoad("protected/Goal/ConversionV2/AddConversionTemplateListV2")
    },
    {
        path: "/goal-generator/edit-conversion-template-list/:u_id",
        name: "edit-conversion-template",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"TP Conversion V-II", breadcrumb: 'Edit Conversion Template' },
        component: lazyLoad("protected/Goal/ConversionV2/EditConversionTemplateV2")
    },
    {
        path: "/sample-files",
        name: "sample-files",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Sample Files", breadcrumb: 'Sample Files' },
        component: lazyLoad("protected/SampleFiles/SampleFiles")
    }
];
