import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/dynamic-session-template",
        name: "dynamic-session-template",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Auto Generate Session", breadcrumb: 'Session Templates' },
        component: lazyLoad("protected/organization/DynamicSessionTemplate/listSessionTemplate")
    },
    {
        path: "/dynamic-session-template/add",
        name: "add-dynamic-session-template",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Auto Generate Session", breadcrumb: {
                label: "Add Session Template",
                parent: "dynamic-session-template"
            } },
        component: lazyLoad("protected/organization/DynamicSessionTemplate/addSessionTemplate")
    },
    {
        path: "/dynamic-session-template/edit/:temp_id",
        name: "edit-dynamic-session-template",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Auto Generate Session", breadcrumb: {
                label: "Edit Session Template",
                parent: "dynamic-session-template"
            } },
        component: lazyLoad("protected/organization/DynamicSessionTemplate/editSessionTemplate")
    },
    {
        path: "/dynamic-session-template/preview/:temp_id",
        name: "preview-dynamic-session-template",
        meta: { layout: "SingleVue",middleware: "auth", user_permission:"Auto Generate Session"},
        component: lazyLoad("protected/organization/DynamicSessionTemplate/previewSessionTemplate")
    },
];
