import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/billing-schedule",
        name: "billing-schedule",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile", user_permission:"student-list", breadcrumb: 'Billing Schedule'},
        component: lazyLoad("protected/calendar/BillingScheduleList")
    },
    {
        path: "/group-schedule",
        name: "group-schedule",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile", user_permission:"student-list", breadcrumb: 'Group Schedule'},
        component: lazyLoad("protected/calendar/GroupScheduleList")
    },

    {
        path: "/group-schedule-add",
        name: "group-schedule-add",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile", user_permission:"student-list",breadcrumb: {
                label: "Add Group Schedule",
                parent: "group-schedule"
            }},
        component: lazyLoad("protected/calendar/GroupScheduleAdd")
    },
    {
        path: "/group-schedule-edit/:schedule_id",
        name: "group-schedule-edit",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile", user_permission:"student-list",breadcrumb: {
                label: "Edit Group Schedule",
                parent: "group-schedule"
            }},
        component: lazyLoad("protected/calendar/GroupScheduleEdit")
    },
    {
        path: "/group-schedule-assign-students/:schedule_id",
        name: "group-schedule-assign-students",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile", user_permission:"student-list",breadcrumb: {
                label: "Assign Students",
                parent: "group-schedule"
            }},
        component: lazyLoad("protected/calendar/GroupScheduleAssignStudents")
    },
];
