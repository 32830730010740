import _ from "lodash";
import Api from "@/service/api/_mix_api";
import {getModules} from "@/components/commons/Layout/PermissionModules/parts/util_permission";

const state = {
    loading: false,
    permissions: []
};
const mutations = {
    setPermissions(state, payload) {
        state.permissions = _.isNull(payload) || _.isEmpty(payload) ? [] : [...payload];
    },
    setLoading(state, payload) {
        state.loading = !!payload;
    }
}
const actions = {
    fetch(context, payload) {
        const callback = _.has(payload, 'callback') && _.isFunction(payload?.callback) ? payload.callback : null;
        const forced = _.get(payload, 'forces', false);
        if (_.isEmpty(context.getters.permissions) || forced) {
            context.commit('setLoading', true);
            return Api.role.permission()
                .then( response => {
                    if (response.status >= 200 && response.status < 300) {
                        context.commit('setPermissions', [...response.data.data])
                    }
                    callback && callback(response.data);
                }).finally(() => context.commit('setLoading', false))
        }
    }
};
const getters = {
    permissions: state => state.permissions || [],
    onlyModules: (state, getters) => {
        if (_.isEmpty(getters.permissions)) {
            return [];
        }
        return getModules([...getters.permissions]);
    },
    onlyPermissions: (state, getters) => {
        return [...getters.onlyModules].reduce((carry, item) => {
            return _.concat(carry, [...item.permissions]);
        }, [])
    },
    loading: state => state.loading || false,
    permissionsInResponseFormat: (state, getters) => (value) => {
        const defaultHasPermissions = [...getters.onlyPermissions].filter(v => !!v?.default_can_do);
        const currentValue = _.isArray(value) && !_.isEmpty(value) ? [...value] : [];
        const currentValueHasPermissions = [...currentValue].map(v => _.toNumber(v.id));
        return _.concat(
            currentValue,
            [...defaultHasPermissions].filter(v => {
                return !_.includes(currentValueHasPermissions, _.toNumber(v.id))
            })
        )
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}