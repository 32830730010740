import store from '@/store';
import {Redirect, ResponseType, getMiddleware} from "@/helper/route";
import _ from "lodash";

const permissionCheck = (permissionName) => {
    if (_.isBoolean(permissionName) && !!permissionName) {
        return permissionName;
    }
    if (_.isString(permissionName)) {
        return store.getters.can(permissionName);
    }
    if (_.isArray(permissionName)) {
        return store.getters.canAny(permissionName);
    }
    if (!permissionName) {
        return store.getters.typeAdmin;
    }
    return false;
}
export default function(to, from) {
    const authenticated = store.getters.authenticated;
    if (!authenticated && getMiddleware(to) === 'auth') {
        return new Redirect('/login', from, to);
    }
    if (to.meta?.user_permission) {
        return new ResponseType(permissionCheck(to.meta?.user_permission), from, to);
    }
    return new ResponseType(true, from, to);
}
