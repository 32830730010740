import _ from "lodash";
import Api from "@/service/api/_mix_api";
import httpErrorHandler from "@/httpErrorHandler";

const state = {
    genders: []
};
const mutations = {
    setGenders(state, data) {
        state.genders = data;
    }
};
const actions = {
    getGenders(context, query) {
        const isForced = !!query;
        if (isForced || _.isEmpty(context.getters.genders)) {
            Api.gender.index(query)
            .then( response => { context.commit('setGenders', [... response.data.data]) })
            .catch(e => { context.commit('setMasterServicesErrorMsg', httpErrorHandler(e)); })
        }
    }
};
const getters = {
    genders: state => state.genders,
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}