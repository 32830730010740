<template>
  <div class="app-help-desk-modal">
    <modal name="help-desk" :scrollable="true" @before-close="onModalClose" height="auto" class="modal-respon content-main col-md-12" :clickToClose="false">
      <div class="box-header pb-0">
        <div class="row">
          <div class="col-6">
            <h2>Send feedback</h2>
          </div>
          <div class="col-6 text-right">
            <a @click.prevent="onForceClose"> <i class="fa fa-window-close text-danger h4" aria-hidden="true"></i></a>
          </div>
        </div>
      </div>
      <HelpDeskForm />
    </modal>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from "vuex";
import HelpDeskForm from "@/components/commons/HelpDesk/HelpDeskForm.vue";

export default {
  name: "HelpDeskModal",
  components: {HelpDeskForm},
  computed: {
    isVisible() {
      return this.$store.state.help_desk.helpDeskFormIsVisible
    },
    ...mapGetters([
        'hasWarningForExit'
    ])
  },
  watch: {
    isVisible: {
      handler(newValue) {
        newValue ? this.onStoreOpenEvent() : this.onStoreCloseEvent();
      }
    }
  },
  methods: {
    onStoreOpenEvent() {
      this.$modal.show('help-desk');
    },
    onStoreCloseEvent() {
      this.$modal.hide("help-desk");
      this.onModalClose();
    },
    onModalClose() {
      this.$store.commit('makeHelDeskFormHidden');
      this.$store.dispatch('resetHelpDeskForm');
    },
    onForceClose() {
      if ((this.hasWarningForExit && window.confirm("Are you sure to discard the feedback?")) || !this.hasWarningForExit) {
        this.onStoreCloseEvent();
      }
    },
    onForceLeave(event) {
      if (this.hasWarningForExit) {
        const unsavedWarningMessage = "You have unsaved changes. are you sure you wish to leave?";
        event.returnValue = unsavedWarningMessage;
        return unsavedWarningMessage;
      }
    }
  },
  mounted() {
    if (this.isVisible) {
      this.$modal.show('help-desk');
    }
    window.addEventListener('beforeunload', this.onForceLeave);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.onForceLeave);
  }
}
</script>

<style scoped>

</style>