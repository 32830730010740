<script>
import {mapGetters} from "vuex";
import Avatar from "@/components/commons/Avatar.vue";

export default {
  name: "HeaderProfileMenu",
  components: {Avatar},
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    destroyDropdown(event) {
      // && !this.$el.contains(event.target) Not required.
      if (this.show) {
        this.show = false;
      }
    }
  },
  mounted() {
    document.addEventListener('mouseup', this.destroyDropdown);
  },
  beforeDestroy() {
    document.removeEventListener('mouseup', this.destroyDropdown);
  }
}
</script>

<template>
  <div class="navbar-text nav-title flex-1 dropdown-user-nav">
    <div class="user-name d-flex flex-row align-items-center" @click.prevent="show = !show">
      <div class="user-name-only-short">Hello, {{user.name}}</div>
      <font-awesome-icon :icon="['fas', !show ? 'angle-down' : 'angle-up']" />
    </div>
    <div v-show="show" class="user-dropdown-menu">
      <div class="menu-cover-item">
        <div class="user-avatar-with-name">
          <div class="user-avatar">
            <Avatar :src="user.avatar" size="size-md" />
          </div>
          <div class="user-name">
            <div class="user-name-only">{{user.name}}</div>
            <div class="user-group-only" v-if="user.user_group">{{user.user_group.name}}</div>
          </div>
        </div>
      </div>
       <div class="menu">
         <router-link class="menu-item profile-link" to="/profile">
           <font-awesome-icon :icon="['fa-solid', 'circle-user']" class="mr-2 icon" />
           <span>Profile</span>
         </router-link>
         <a class="menu-item logout-link" @click.prevent="$store.dispatch('logout')">
           <font-awesome-icon :icon="['fas', 'arrow-right-from-bracket']" class="mr-2 icon" />
           <span>Logout</span>
         </a>
       </div>
    </div>
  </div>
</template>

<style scoped>
.dropdown-user-nav {
  position: relative;
  margin-right: 10px;
  z-index: 1000;
}
.dropdown-user-nav > .user-name {
  font-size: 14px;
  height: 16px;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
}
.dropdown-user-nav > .user-name > .user-name-only-short{
  width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown-user-nav > .user-dropdown-menu {
  position: absolute;
  top: 100%;
  background: #fff;
  min-width: 220px;
  height: auto;
  right: 0;
  border: 1px solid #ebebeb;
  box-shadow: 0 1px 1px #e9e9e9;
  border-radius: 4px;
  color: #000;
  margin-top: 13px;
}
.dropdown-user-nav > .user-dropdown-menu:before {
  position: absolute;
  bottom: 100%;
  content: "";
  right: 35px;
  border: 8px solid transparent;
  border-bottom-color: white;
}
.dropdown-user-nav > .user-dropdown-menu > .menu-cover-item {
  width: 100%;
  background: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid #e5e5e5;
}
.dropdown-user-nav > .user-dropdown-menu >  .menu-cover-item .user-avatar-with-name {
  padding: 25px;
}
.dropdown-user-nav > .user-dropdown-menu >  .menu-cover-item .user-avatar-with-name > .user-avatar  {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.dropdown-user-nav > .user-dropdown-menu >  .menu-cover-item .user-avatar-with-name > .user-avatar img{
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.dropdown-user-nav > .user-dropdown-menu >  .menu-cover-item .user-avatar-with-name .user-name {
  margin-top: 5px;
  text-align: center;
}
.dropdown-user-nav > .user-dropdown-menu >  .menu-cover-item .user-avatar-with-name .user-name .user-name-only{
  font-size: 16px;
  font-weight: 600;
}
.dropdown-user-nav > .user-dropdown-menu >  .menu-cover-item .user-avatar-with-name .user-name .user-group-only{
  font-size: 14px;
  color: #888888;
}
.dropdown-user-nav > .user-dropdown-menu > .menu {
  //padding-top: 10px;
  //padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
}
.dropdown-user-nav > .user-dropdown-menu > .menu a {
  font-size: 16px;
  font-weight: 500;
  flex: 1;
  background: #2c9aa8;
  padding: 10px;
  color: #fff;
  transition: opacity ease-in-out 0.3s;
}
.dropdown-user-nav > .user-dropdown-menu > .menu a:hover {
  opacity: 0.8;
}
.dropdown-user-nav > .user-dropdown-menu > .menu a > .icon {
  font-size: 14px;
}
.dropdown-user-nav > .user-dropdown-menu > .menu a.profile-link {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-right: 1px solid #fff;
}
.dropdown-user-nav > .user-dropdown-menu > .menu a.logout-link {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-left: 1px solid #fff;
}
</style>
