import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/referring-provider",
        name: "referring-provider.index",
        meta: {
            middleware: "auth",
            user_permission: "referring-provider.view-all",
        },
        component: lazyLoad('pages/referring_provider/Index')
    },
    {
        path: "/add-referring-provider",
        name: "referring-provider.create",
        meta: {
            middleware: "auth",
            user_permission: "referring-provider.add-or-edit",
        },
        component: lazyLoad('pages/referring_provider/AddReferingProvider')
    },
    {
        path: "/edit-referring-provider/:referring_provider_id",
        name: "referring-provider.edit",
        meta: {
            middleware: "auth",
            user_permission: "referring-provider.view-all",
        },
        component: lazyLoad('pages/referring_provider/EditReferingProvider')
    },
];