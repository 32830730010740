import _ from "lodash";
import Api from "@/service/api/_mix_api";
import * as JsSearch from "js-search";
import { search, sorting } from "@/service/localFilters";

const state = {
    certificationTypes: [],
    localFilters: {},
    certificationTypeDetails: {}
};
const mutations = {
    setCertificationTypes(state, payload) {
        state.certificationTypes = [...payload];
    },
    setLocalFilters(state, payload) {
        state.localFilters = {...payload};
    },
    setCertificationTypeDetails(state,payload) {
        state.certificationTypeDetails = {...payload};
    }
};
const actions = {
    fetch(context, payload) {
        const isForced = !!payload;
        if (isForced || _.isEmpty(context.getters.certificationTypes)) {
            return Api.certification_type.index().then((response) => {
                if (response.status === 200) {
                    context.commit('setCertificationTypes', [...response.data.data]);
                }
            })
        }
    },
    fetchDetails(context, payload) {
        const isForced = !!(payload?.forced || false);    
        // if (isForced || _.isEmpty(context.getters.certificationTypeDetails)) {
            return Api.certification_type.show(payload.id).then((response) => {
                if (response.status === 200) {
                    context.commit('setCertificationTypeDetails', {...response.data.data});
                }
            })
        // }
    }
};
const getters = {
    certificationTypes: state => state.certificationTypes || [],
    certificationTypesWithFilter: state => {
        const filter = [
            // Search
            (collection) => search(collection, state.localFilters, ['name']),
            // Sorting
            (collection) => sorting(collection, state.localFilters)
        ];
        return filter.reduce((collection, value) => {
            return value(collection);
        }, (state.certificationTypes || []));
    },
    certificationTypeDetails: state => state.certificationTypeDetails || {}
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
