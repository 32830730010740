import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/file-management",
        name: "file-management",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"File Management", breadcrumb: 'File-Management' },
        component: lazyLoad("protected/FileManagement/file-management")
    },
    // {
    //   path: "/folders-list",
    //   name: "folders-list",
    //   meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"folder-list", breadcrumb: 'Folders List' },
    //   component: lazyLoad("protected/FileManagement/folders-list")
    // },
    // {
    //   path: "/create-folder",
    //   name: "create-folder",
    //   meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"folder-list", breadcrumb: {
    //     label: "Add New Folder",
    //     parent: "file-management"
    //   } },
    //   component: lazyLoad("protected/FileManagement/create-folder")
    // },
    // {
    //   path: "/upload-file",
    //   name: "upload-file",
    //   meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"folder-list", breadcrumb: {
    //     label: "Upload File",
    //     parent: "file-management"
    //   } },
    //   component: lazyLoad("protected/FileManagement/upload-file")
    // },
    // {
    //   path: "/update-folder/:folder_id",
    //   name: "update-folder",
    //   meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"folder-list", breadcrumb: {
    //     label: "Update Folder",
    //     parent: "file-management"
    //   }},
    //   component: lazyLoad("protected/FileManagement/update-folder")
    // },
    // {
    //   path: "/update-file/:file_id",
    //   name: "update-file",
    //   meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"folder-list", breadcrumb: {
    //     label: "Update File",
    //     parent: "file-management"
    //   }},
    //   component: lazyLoad("protected/FileManagement/update-file")
    // },
    {
        path: "/view-folder-contents/:folder_id",
        name: "view-folder-contents",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"File Management", breadcrumb: {
                label: "Contents",
                parent: "file-management"
            }},
        component: lazyLoad("protected/FileManagement/folder-contents")
    },
    {
        path: "/deleted-folders",
        name: "deleted-folders",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"File Management", breadcrumb: {
                label: "Deleted Folders",
                parent: "file-management"
            } },
        component: lazyLoad("protected/FileManagement/deleted-folders")
    },
    {
        path: "/deleted-files",
        name: "deleted-files",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"File Management", breadcrumb: {
                label: "Deleted Files",
                parent: "file-management"
            } },
        component: lazyLoad("protected/FileManagement/deleted-files")
    },

    {
        path: "/shared-folders",
        name: "shared-folders",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"File Management", breadcrumb: {
                label: "Shared-Folders",
                parent: "file-management"
            } },
        component: lazyLoad("protected/FileManagement/shared-folders-list")
    },
    {
        path: "/share-folder/:folder_id",
        name: "share-folder",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"File Management", breadcrumb: {
                label: "Share Folder",
                parent: "file-management"
            } },
        component: lazyLoad("protected/FileManagement/share-folder")
    },
    {
        path: "/share-file/:file_id",
        name: "share-file",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"File Management", breadcrumb: {
                label: "Share File",
                parent: "file-management"
            } },
        component: lazyLoad("protected/FileManagement/share-file")
    },
    {
        path: "/shared-files",
        name: "shared-files",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"File Management", breadcrumb: {
                label: "Shared Files",
                parent: "file-management"
            } },
        component: lazyLoad("protected/FileManagement/shared-files")
    },
];
