import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/session-master",
        name: "session-master",
        meta: { layout:"ProtectedVue",middleware: "auth",sidebar: "profile", user_permission:"session-template-master-list"},
        component: lazyLoad("protected/SessionTemplates/Master/Master")
    },
    {
        path: "/edit-session-master/:session_master_id",
        name: "edit-session-master",
        meta: { layout:"ProtectedVue",middleware: "auth",sidebar: "profile", user_permission:"session-template-master-edit"},
        component: lazyLoad("protected/SessionTemplates/Master/Edit-master")
    },
    {
        path: "/add-session-master/",
        name: "add-session-master",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"session-template-master-add" },
        component: lazyLoad("protected/SessionTemplates/Master/Add-master")
    },
    {
        path: "/delete-session-master",
        name: "delete-session-master",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"session-template-master-delete"},
        component: lazyLoad("protected/SessionTemplates/Master/Delete-master")
    },
];
