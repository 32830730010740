import Vue from 'vue'
import Api from "@/service/api/_mix_api";
import Router from "@/routers/router";
import _ from "lodash";
const defaultToken = {
  token_type: null,
  expires_in: null,
  access_token: null,
  refresh_token: null
};
  const state= {
    progress: {
      login: false,
      logout: false,
      refresh_basic_details: false,
      refresh_scopes: false
    },
    user: null,
    token: {...defaultToken},
    scopes: null
  };
  const mutations= {
    setProgress(state, payload) {
      state.progress = {
        ...state.progress,
        ...payload
      }
    },
    setUser(state, payload) {
      state.user = _.isNull(payload) ? null : {...payload};
    },
    setToken(state, payload) {
      state.token = {
        token_type: payload.token_type,
        expires_in: payload.expires_in,
        access_token: payload.access_token,
        refresh_token: payload.refresh_token
      }
    },
    setScopes(state, payload) {
      state.scopes = _.isNull(payload) ? null : {...payload};
    },
    resetAll(state) {
      state.user = null;
      // state.userLazyCollection = {...defaultLazyCollection};
      state.token = {...defaultToken};
      state.scopes = null;
    }
    // setAuthUser(state, payload) {
    //   state.authUser= {
    //     ...state.authUser,
    //     ...payload
    //   };
    // },
    // setWrittenSignature(state, payload) {
    //   state.authUser.written_signature = payload;
    // },
    // setUploadedSignature(state, payload) {
    //   state.authUser.uploaded_signature = payload;
    // },
    // setToken(state, payload) {
    //   state.authUser=payload.user;
    //   state.userType = payload.user.user_group.userType.handle;
    //   state.userPermissions = payload.permission ? payload.permission : [];
    // },
    // logout(state){
    //   state.authUser = null
    // },
    // setPrimaryAddress(state, payload) {
    //   state.primary_address = _.isNull(payload) ? null : {...payload};
    // },
    // setMailableAddress(state, payload) {
    //   state.mailable_address = _.isNull(payload) ? null : {...payload};
    // },
    // setOtherAddresses(state, payload) {
    //   state.other_addresses = [...payload];
    // }
  };
  const actions= {
    login(context, payload) {
      context.commit('setProgress',{login: true});
      Api.authentication
          .login({...payload.data})
          .then(response => {
            if (response.status >= 200 && response.status < 300) {
              context.commit('setUser', {...response.data.data.user});
              context.commit('setToken', {...response.data.data.token});
              context.commit('setScopes', {...response.data.data.scopes});
            }
            if (_.isFunction(payload?.callback )) {
              payload.callback(response);
            }
          })
          .finally(() => context.commit('setProgress',{login: false}))
    },
    logout(context) {
      if (!!context.getters.accessToken) {
        context.commit('setProgress', {logout: true});
        Api.authentication.logout()
            .then(() => {
              context.commit('resetAll');
              Router.push({name: 'home'});
            })
            .catch((error) => console.log(error))
            .finally(() => context.commit('setProgress', {logout: false}));
      } else {
        Router.push({name: 'home'});
      }

    },
    refreshScopes(context, payload) {
      const callback = _.isFunction(payload?.callback) ? payload?.callback : null;
      context.commit('setProgress', {refresh_scopes: true})
      Api.authentication.refreshScopes()
          .then(response => {
            if (response.status >= 200 && response.status < 300) {
              context.commit('setScopes', {...response.data.data});
            }
            _.isFunction(callback) && callback(response);
          }).finally(() => context.commit('setProgress', {refresh_scopes: false}))
    },
    refreshBasicInfo(context) {
      context.commit('setProgress', {refresh_basic_details: true});
      Api.profile.showBasicDetails()
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              context.commit('setUser', {...response.data.data});
            }
          }).finally(() => context.commit('setProgress', {refresh_basic_details: false}))
    },
    remoteUpdateScope(context) {
      const oldScopes = _.cloneDeep({...context.getters.scopes});
      context.dispatch('refreshScopes', {
        callback: (response) => {
          if (response.status >= 200 && response.status < 300 && !_.isEqual(oldScopes, {...response.data.data})) {
            context.dispatch('logout').then(() => {
              Vue.$toast.open({message: "Your permissions has been updated, please login again.", type: "error"});
            });
          }
        }
      });
    }
  };
  const getters= {
    user: state => state.user,
    scopes: (state) => state.scopes || null,
    // userLazyCollection: state => state.userLazyCollection,
    authenticated: state => !!state.token?.access_token,
    refreshToken: state => state.token.refresh_token,
    accessToken: state => state.token.access_token,
    typeAdmin: state => !!(state.user?.is_admin_user || false),
    typeStaff: state => !!(state.user?.is_staff_user || false),
    typeGuardian: state => !!(state.user?.is_guardian_user || false),
    role: state => _.get(state.user, 'user_group.role', null),
    progress: state => state.progress,
    /*
      |------------------------------------------------------------------
      | All functions related to the checking permissions.
      |------------------------------------------------------------------
      | 1. can -> provide single 'handle' and return true or false.
      | 2. canAny -> provide array of 'handle' and return true or false
      |              in OR logic.
      | 3. cant -> provide single 'handle' and return true if not
      |            has the permission.
      | 4. cannot -> provide array of 'handle' and return true if doesn't
      |              has any permission.
     */
    hasPermission: (state, getters) => (handle) => {
      return  !_.isEmpty(getters.user) && !!_.get(state.scopes, handle, false);
    },
    can: (state, getters) => (handle) => {
      return  !_.isEmpty(getters.user) && !_.isEmpty(getters.scopes) && (
          (!handle && getters.typeAdmin) ||
          (handle === 'only-staff' && getters.typeStaff) ||
          (handle === 'only-guardian' && getters.typeGuardian) ||
          !!getters.scopes[handle]
      );
    },
    canAny: (state, getters) => (handles) => {
      handles = _.isArray(handles) && !_.isEmpty(handles) ? handles : [];
      if (!_.isEmpty(getters.user) && !_.isEmpty(getters.scopes)) {
        const permitted = [...handles]
            .map(handle => !!_.get(getters.scopes, handle, false))
            .filter(can => !!can);
        return !_.isEmpty(permitted);
      }
      return false;
    },
    cant: (state, getters) => (handle) => {
      return !getters.can(handle);
    },
    cannot: (state, getters) => (handles) => {
      handles = _.isArray(handles) && !_.isEmpty(handles) ? handles : [];
      if (!_.isEmpty(getters.user) && !_.isEmpty(getters.scopes)) {
        const permitted = [...handles]
            .map(handle => !!_.get(getters.scopes, handle, false))
            .filter(can => !!can);
        return _.isEmpty(permitted);
      }
      return true;
    }
  }


export default {
  state,
  getters,
  actions,
  mutations
}
