import _ from "lodash";
import Api from "@/service/api/_mix_api";

const state = {
    isFetching: false,
    types: []
};
const mutations = {
    setIsFetching(state, payload) {
        state.isFetching = !!payload;
    },
    setTypes(state, payload) {
        state.types = [...payload];
    }
}
const actions = {
    fetch(context, payload) {
        const isForced = !!(payload?.forced || false);
        if (isForced || _.isEmpty(context.getters.types)) {
            context.commit('setIsFetching', true);
            Api.payroll_type.index().then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    context.commit('setTypes', [...response.data.data]);
                }
                if (payload?.callback && _.isFunction(payload.callback)) {
                    payload.callback(response);
                }
            }).finally(() => context.commit('setIsFetching', false))
        }
    }
}
const getters = {
    isFetching: (state) => state.isFetching || false,
    types: (state) => state.types || []
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}