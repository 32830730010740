import _ from "lodash";
import Api from "@/service/api/_mix_api";

const state = {
    service: {}
};
const mutations = {
    setService(state, payload) {
        state.service = {...payload};
    }
};
const actions = {
    fetch(context, payload) {
        const isForced = !!(payload?.forced || false);
        if (isForced || _.isEmpty(context.getters.service)) {
            return Api.service.show(payload.id).then((response) => {
                if (response.status === 200) {
                    context.commit('setService', {...response.data.data});
                }
            })
        }
    }
};
const getters = {
    service: (state) => state.service || {}
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
