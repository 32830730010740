import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    // {
    //     path: "/funder",
    //     name: "funder.index",
    //     meta: {
    //         middleware: "auth",
    //         user_permission:"funder-view"
    //     },
    //     component: lazyLoad("pages/funder/Index")
    // },
    // {
    //     path: "/funder/create",
    //     name: "funder.create",
    //     meta: {
    //         middleware: "auth",
    //         user_permission:"funder-add-edit"
    //     },
    //     component: lazyLoad("pages/funder/show/AddFunder")
    // },
    // {
    //     path: "/agency/:uuid/funder/:funder_id",
    //     name: "funder.edit",
    //     redirect: { name: 'funder.show' },
    //     meta: {
    //         middleware: "auth",
    //         user_permission: "funder-view",
    //     },
    //     component: lazyLoad("pages/funder/show/Layout"),
    //     children: [
    //         {
    //             path: "",
    //             name: 'funder.show',
    //             meta: {
    //                 middleware: "auth",
    //                 user_permission: "funder-view",
    //             },
    //             component: lazyLoad('pages/funder/show/EditFunder')
    //         },
    //         {
    //             path: "plans",
    //             name: 'funder.plan',
    //             meta: {
    //                 middleware: "auth",
    //                 user_permission: "funder-view",
    //             },
    //             component: lazyLoad('pages/funder/show/Plan')
    //         },
    //     ]
    // },
    // {
    //     path: "/funder/:funder_id/show",
    //     name: "funder.show",
    //     meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"funder-add-edit",group:"payer", breadcrumb: {
    //             label: "Funder Details",
    //             parent: "funder.index"
    //         }},
    //     component: lazyLoad("pages/funder/show/Show-funder")
    // },
    // {
    //     path: "/funder/:funder_id/service-line",
    //     name: "funder.service-line",
    //     meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"funder-add-edit",group:"payer", breadcrumb: {
    //             label: "Funder Service Line",
    //             parent: "funder.index"
    //         }},
    //     component: lazyLoad("pages/funder/show/ServiceLines")
    // }

    // {
    //     path: "/delete-funder",
    //     name: "delete-funder",
    //     meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"funder-view",group:"payer", breadcrumb: {
    //             label: "Delete Funder",
    //             parent: "funders"
    //         }},
    //     component: lazyLoad("pages/funder/show/Delete-funder")
    // },
    // {
    //     path: "/payer-prompt-code/:payer_id",
    //     name: "payer-prompt-code",
    //     meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"funder-view",group:"payer-prompt-code", breadcrumb: {
    //             label: "Funder Prompt Code",
    //             parent: "payer-plans"
    //         }},
    //     component: lazyLoad("pages/funder/parts/index/payer-prompt-code")
    // }
];
