<script>
export default {
  name: "GuestLayout",

}
</script>

<template>
  <section class="o-mid-container">
    <div id="content-body">
      <div class="py-5">
        <slot />
      </div>
    </div>
  </section>
</template>

<style scoped>
.o-mid-container {
  height: 100vh;
}
.application-logo {
  padding: 15px;
  background: #12adc1;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.application-logo img{
  height: 35px;
}
.card.auth-card {
  background-color: #fbfbfb;
  border-radius: 15px;
  color: #000
}
.auth-layout {
  height: 80vh;
}
.auth-layout .auth-layout-container {
  width: 360px;
}
.auth-layout-header {
  background: #f5f5f5;
  padding: 13px;
  border-bottom: 1px solid #ebebeb;
}

.auth-layout-header .header-text {

}
.auth-layout-header .title-text {
  font-size: 16px;
}
</style>
