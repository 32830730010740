<script>
import MiniProfileMenu from "@/components/layouts/authenticated/parts/Header/MiniProfileMenu.vue";
import {mapActions} from "vuex";

export default {
  name: "MixPrimaryHeader",
  components: {MiniProfileMenu},
  methods: {
    ...mapActions('sidenav', ['toggleVisibility'])
  }
}
</script>

<template>
  <div class="navbar navbar-expand-lg app-primary-header">
    <div class="navbar-text nav-title flex toggle-btn">
      <button type="button" class="toggle-sidenav" @click.prevent="toggleVisibility">
        <font-awesome-icon :icon="['fas', 'bars-staggered']" />
      </button>
    </div>
    <MiniProfileMenu />
  </div>
</template>

<style scoped>

</style>
