import _ from "lodash";
import Api from "@/service/api/_mix_api";

const state = {
    funder: {},
    serviceLines: []
};
const mutations = {
    setFunder(state, payload) {
        state.funder = {...payload};
    },
    setServiceLines(state, payload) {
        state.serviceLines = [ ...payload ];
    },
    toggleCollapse(state, index) {
        state.serviceLines[index].isCollapsed = !state.serviceLines[index].isCollapsed;
    }
};
const actions = {
    fetch(context, payload) {    
        const isForced = !!(payload?.forced || false);    
        if (isForced || _.isEmpty(context.getters.funder)) {
            return Api.funder.show(payload.id).then((response) => {
                if (response.status === 200) {
                    context.commit('setFunder', {...response.data.data});
                }
            })
        }
    },
    fetchServiceLines(context) {
        if(context.state.funder.agency_payer_service_lines && context.state.funder.agency_payer_service_lines.length) {
            const serviceLines = context.state.funder.agency_payer_service_lines.map(item => ({
                ...item,
                isCollapsed: true // Initially collapsed
            }));
            context.commit('setServiceLines', serviceLines);
        }else {
            context.commit('setServiceLines', []);
        }
    }
};
const getters = {
    funder: (state) => state.funder || {},
    funderServiceLines: () => state.serviceLines || []
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
