import { lazyLoad } from "@/routers/utils/helper";

export const routes = [
    {
        path: "/kareo-funder",
        name: "kareo-funder.index",
        meta: { middleware: "auth", user_permission: null },
        component: lazyLoad("pages/kareo-funder/Index")
    },
    {
        path: "/kareo-funder/create",
        name: "kareo-funder.create",
        meta: {
            middleware: "auth", user_permission: null
        },

        component: lazyLoad("pages/kareo-funder/parts/index/Add-kareo-funder")
    },
    {
        path: "/kareo-funder/:kareo_funder_id",
        name: "kareo-funder.edit",
        redirect: { name: 'kareo-funder.show' },
        meta: { middleware: "auth", user_permission: null },
        component: lazyLoad("pages/kareo-funder/show/Layout"),
        children: [
            {
                path: "",
                name: 'kareo-funder.show',
                meta: {
                    middleware: "auth",
                    user_permission: null,
                },
                component: lazyLoad('pages/kareo-funder/parts/index/Edit-kareo-funder')
            },
        ]
    },
];
