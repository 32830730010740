import _ from "lodash";
import Api from "@/service/api/_mix_api";

const state = {
    staff_assign_student: []
};
const mutations = {
    setStaffAssignStudent(state, payload) {
        state.staff_assign_student = [...payload];
    }
};
const actions = {
    staffAssignStudent(context, id) {
        const isForced = !!id;
        if (isForced || _.isEmpty(context.getters.staff_assign_student)) {
            return Api.staff_assign_student.index(id).then((response) => {
                if (response.status === 200) {
                    context.commit('setStaffAssignStudent', [...response.data.data]);
                }
            })
        }
    }
};
const getters = {
    staff_assign_student: state => state.staff_assign_student || []
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}