import _ from "lodash";
import Api from "@/service/api/_mix_api";
import httpErrorHandler from "@/httpErrorHandler";

const state = {
    isFetching: false,
    maritalStatus: [],
};
const mutations = {
    setIsFetching(state, payload) {
        state.isFetching = !!payload;
    },
    setMaritalStatus(state, data) {
        state.maritalStatus = data;
    },
};
const actions = {
    getMaritalStatus(context, query) {
        const isForced = !!query;
        if (isForced || _.isEmpty(context.getters.maritalStatus)) {
            context.commit('setIsFetching', true);
            Api.marital_status.index(query)
            .then( response => {
                if (response.status >= 200 && response.status < 300) {
                    context.commit('setMaritalStatus', [... response.data.data])
                }
            }).finally(() => context.commit('setIsFetching', false))
        }
    },
};
const getters = {
    isFetching: state => state.isFetching || false,
    maritalStatus: state => state.maritalStatus || [],
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}