<script>
import {mapGetters} from "vuex";
import SidenavMenuItem from "@/components/layouts/authenticated/parts/Sidenav/SidenavMenuItem.vue";
import {PerfectScrollbar} from "vue2-perfect-scrollbar";
export default {
  name: "Sidenav",
  components: {
    PerfectScrollbar,
    SidenavMenuItem
  },
  computed: {
    ...mapGetters({
      menu: 'sidenav/menu'
    })
  },
  data() {
    return {
      scrollConfig: {
        wheelPropagation: false,
        suppressScrollX: true,
        wheelSpeed: 0.8
      }
    };
  },
  watch: {
    $route() {
      this.updateActiveMenu();
    }
  },
  methods: {
    updateActiveMenu() {
      this.$store.commit('sidenav/updateStatusFromRouteURL', this.$route);
    },
    showHelpDeskForm() {
      this.$store.dispatch('showHelpDeskForm');
    }
  },
  mounted() {
    this.updateActiveMenu();
  }
}
</script>

<template>
  <div class="app-sidenav">
    <div class="app-sidenav-brand">
        <router-link :to="{name:'dashboard'}">
          <div class="app-sidenav-brand-img">
            <img src="../../../../../assets/images/logo-with-icon.png" alt="logo">
          </div>
        </router-link>
    </div>
    <PerfectScrollbar class="app-sidenav-menu-scroll-area" :options="scrollConfig">
      <ul class="app-sidenav-menu">
        <li v-for="(item, index) in menu" :key="index" class="menu-item" :class="{'is-open': item?.has_open}" :data-childrens="item?.children?.length">
          <SidenavMenuItem :item="item" :handle="item.handle" />
          <ul class="menu-item-children" v-if="item?.children && item?.children.length > 0">
            <li v-for="(child_item, child_index) in item.children" class="menu-sub-item" :key="child_index">
              <router-link :to="child_item.route" class="menu-sub-item-link" :class="{'active': child_item?.active}">{{child_item.label}}</router-link>
            </li>
          </ul>
        </li>
      </ul>
    </PerfectScrollbar>
    <div class="app-sidenav-footer">
      <div class="help-desk-menu">
        <button class="help-desk-btn btn btn-primary btn-green btn-block mx-0" type="button" @click.prevent="showHelpDeskForm">
          <i class="fa fa-question-circle mr-2" aria-hidden="true"></i>
          <span>Need Help/Feedback</span>
        </button>
      </div>
      <div class="footer-text">
        <div class="d-flex flex-row align-items-center text-muted">
          <div><small>&copy; Copyright. Flatfull</small></div>
          <div><small class="px-2">|</small></div>
          <div><small>Version: 2.0</small></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
