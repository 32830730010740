import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/diagnosis",
        name: "diagnosis",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"diagnosis-list" },
        component: lazyLoad("protected/organization/Diagnosis/Diagnosis")
    },
    {
        path: "/add-diagnosis",
        name: "add-diagnosis",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"diagnosis-add" },
        component: lazyLoad("protected/organization/Diagnosis/Diagnosis-add")
    },
    {
        path: "/delete-diagnosis",
        name: "delete-diagnosis",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"diagnosis-delete" },
        component: lazyLoad("protected/organization/Diagnosis/Diagnosis-delete")
    },
    {
        path: "/edit-diagnosis/:diagnosis_id",
        name: "edit-diagnosis",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"diagnosis-edit" },
        component: lazyLoad("protected/organization/Diagnosis/Diagnosis-edit")
    },
    {
        path: "/import-diagnosis",
        name: "import-diagnosis",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"diagnosis-list"},
        component: lazyLoad("protected/organization/Diagnosis/Import-Diagnosis")
    },
];
