import _ from "lodash";
import Api from "@/service/api/_mix_api";
import {softDelete, search, sorting} from '@/service/localFilters';

const state = {
    isFetching: false,
    payCodes: [],
    localFilters: {}

};
const mutations = {
    setIsFetching(state, payload) {
        state.isFetching = !!payload;
    },
    setPayCodes(state, payload) {
        state.payCodes = [...payload];
    },
    setLocalFilters(state, payload) {
        state.localFilters = {...payload};
    }
};
const actions = {
    fetch(context, payload) {
        const isForced = !!(payload?.forced || false);
        if (isForced || _.isEmpty(context.getters.payCodes)) {
            context.commit('setIsFetching', true);
            Api.pay_code.index()
            .then( response => {
                if (response.status >= 200 && response.status < 300) {
                    context.commit('setPayCodes', [... response.data.data])
                }
            }).finally(() => context.commit('setIsFetching', false))
        }
    }
};
const getters = {
    payCodes: state => state.payCodes,
    payCodesWithFilters: state => {
        const filter = [
            // Trashed or published
            (collection) => softDelete(collection, state.localFilters),
            // Search
            (collection) => search(collection, state.localFilters, ['name','pay_code']),
            // Sorting
            (collection) => sorting(collection, state.localFilters)
        ];
        return filter.reduce((collection, value) => {
            return value(collection);
        }, (state.payCodes || []));
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}