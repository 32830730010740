import _ from "lodash";

export const getModules = (permissionModules) => {
    let modules = [];
    for (let index = 0; index < permissionModules.length; index++) {
        let childrenModules = permissionModules[index]?.children || [];
        modules.push(_.omit(permissionModules[index], ['children']));
        if (!_.isEmpty(childrenModules)) {
            modules = _.concat(modules, getModules(childrenModules))
        }
    }
    return modules;
};
export const getPermissionFromModule = (module) => {
    let permissions = module?.permissions || [];
    let childrenModules = module?.children || [];
    if (!_.isEmpty(childrenModules)) {
        for(let index = 0; index < childrenModules.length; index++) {
            permissions = _.concat(permissions, getPermissionFromModule(childrenModules[index]));
        }
    }
    return permissions;
}
export const getTypePermissionsInModule = (permissions, type) => {
    const allowedTypes = ['read', 'write'];
    if (_.isEmpty(permissions) && _.isNull(type) || _.isUndefined(type) || !allowedTypes.includes(type.toLowerCase())) {
        return [];
    }
    return [...permissions].filter(item => _.isEqual(item.type, type)) || [];
};
export const checkPermissionHasConditionSatisfied = (selected, permission) => {
    const conditions = (permission?.conditions || []).filter(condition => !_.isEmpty(condition.dependencies));
    if (!_.isEmpty(conditions)) {
        return conditions.filter(item => {
            const resolvedDeps = [...item.dependencies].map(item => item.id).filter(item => _.includes(selected, _.toNumber(item)));
            if (item.apply_type === 'or') {
                return resolvedDeps.length > 0;
            }
            return resolvedDeps.length === _.size(item.dependencies);
        }).length === conditions.length;
    }
    return true;
}
export const checkWriteTypePermissionSatisfiedWithModuleRead = (selected, modulePermissions, permission) => {
    if (permission?.type === 'write') {
        if (!_.isEmpty(selected) && _.isArray(selected)) {
            const filteredSelected = [...selected].map(item => _.toNumber(item));
            const readPermissions = getTypePermissionsInModule(modulePermissions, 'read');
            return !_.isEmpty(readPermissions.filter(item => _.includes(filteredSelected, _.toNumber(item.id)))
            );
        }
        return false;
    }
    return true;
}