import Vue from "vue";
import _ from "lodash";
function locateNode(vnode) {
    // @ts-expect-error
    return vnode.componentInstance && (!vnode.data || !vnode.data.transition)
        ? locateNode(vnode.componentInstance._vnode)
    : vnode
}
function canCheck(vnode, permissions, type) {
    switch (type) {
        case 'any':
            return vnode.context.$store.getters.canAny(_.isString(permissions) ? [permissions] : permissions);
        case 'not':
            return !_.isArray(permissions) ? vnode.context.$store.getters.cant(permissions) :
                vnode.context.$store.getters.cannot(permissions);
        case 'only-staff':
            return vnode.context.$store.getters.typeStaff;
        case 'only-admin':
            return vnode.context.$store.getters.typeAdmin;
        case 'only-guardian':
            return vnode.context.$store.getters.typeGuardian;
        default:
            return vnode.context.$store.getters
                .can(_.isArray(permissions) ? _.head(permissions) : permissions);
    }
}
const directiveFn = {
    bind(el, binding, vnode) {
        const value = canCheck(vnode, binding?.value, binding.arg);
        vnode = locateNode(vnode)
        const originalDisplay = (el.__vOriginalDisplay =
            el.style.display === 'none' ? '' : el.style.display)
        if (value) {
            vnode.data.show = true
            el.style.display = originalDisplay
        } else {
            el.style.display = value ? originalDisplay : 'none'
        }
    },
    update(el, binding, vnode) {
        /* istanbul ignore if */
        if (binding?.value === binding?.oldValue) return
        vnode = locateNode(vnode)
        const value = canCheck(vnode, binding.value, binding.arg);
        if (value) {
            vnode.data.show = true
            el.style.display = el.__vOriginalDisplay
        } else {
            el.style.display = value ? el.__vOriginalDisplay : 'none'
        }
    },

    unbind(el, binding,vnode,oldVnode, isDestroy) {
        if (!isDestroy) {
            el.style.display = el.__vOriginalDisplay
        }
    }
};
Vue.directive('can', directiveFn);
Vue.directive('show-if', directiveFn);