import HTTP from "@/service/api/_axios_setup";
export default {
    login: ({username, password}) => HTTP.post('/login', {username, password}),
    logout: () => HTTP.post('/logout',null, {_forced_logout: true}),
    forgotPassword: (username) => HTTP.post('/forgot-password', {username}),
    resetPassword(encrypted_email, encrypted_uuid, token, password) {
        return HTTP.post('/reset-password', {
            token,
            encrypted_email,
            encrypted_uuid,
            password
        });
    },
    refreshScopes: () => HTTP.post('/refresh-scopes')
}
