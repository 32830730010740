<script>
export default {
  name: "SidenavMenuItemIcon",
  props: {
    icon: {
      type: [String, Array],
      required: true
    }
  }
}
</script>

<template>
  <span class="menu-item-icon">
    <span class="menu-icon">
      <font-awesome-icon :icon="icon" />
    </span>
  </span>
</template>

<style scoped>

</style>
