import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/stage-status-master",
        name: "stage-status-master",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"stage-status-master-list", breadcrumb: 'Stage Status Master' },
        component: lazyLoad("protected/organization/StageStatusMaster/Stage-status-master")
    },
    {
        path: "/add-stage-status-master",
        name: "add-stage-status-master",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"stage-status-master-add", breadcrumb: {
                label: "Add New Stage Status Master",
                parent: "stage-status-master"
            } },
        component: lazyLoad("protected/organization/StageStatusMaster/Add-stage-status-master")
    },
    {
        path: "/edit-stage-status-master/:stage_status_master_id",
        name: "edit-stage-status-master",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"stage-status-master-edit", breadcrumb: {
                label: "Edit Stage Status Master",
                parent: "stage-status-master"
            }},
        component: lazyLoad("protected/organization/StageStatusMaster/Edit-stage-status-master")
    },
    {
        path: "/delete-stage-status-master",
        name: "delete-stage-status-master",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"stage-status-master-list", breadcrumb: {
                label: "Delete Stage Status Master",
                parent: "stage-status-master"
            }},
        component: lazyLoad("protected/organization/StageStatusMaster/Delete-stage-status-master")
    },
];
