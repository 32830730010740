import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    // {
    //   path: "/add-templates",
    //   name: "add-templates",
    //   meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"program-list" },
    //   component: lazyLoad("protected/Templates/addTemplates")
    // },
    {
        path: "/templates",
        name: "templates",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Templates", breadcrumb: 'Templates' },
        component: lazyLoad("protected/Templates/TemplateList")
    },
    {
        path: "/templates/comment",
        name: "templates-comment",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Templates", breadcrumb: {
                label: "Pdf Comment",
                parent: "templates"
            } },
        component: lazyLoad("protected/Templates/Comments")
    },
    {
        path: "/templates/preview",
        name: "templates-preview",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile", breadcrumb: {
                label: "Pdf Preview",
                parent: "templates"
            } },
        component: lazyLoad("protected/Templates/PreviewPdfTemplate")
    },
    {
        path: "/templates/customer-preview/:template_id",
        name: "customer-preview",
        meta: { layout: "SingleVue", breadcrumb: {
                label: "Pdf Preview",
                parent: "templates"
            } },
        component: lazyLoad("protected/Templates/CustomerPreview")
    },
    {
        path: "/document-label-customize",
        name: "document-label-customize",
        meta: { layout: "SingleVue", breadcrumb: {
                label: "Document Labels Customize",
                parent: "document-settings"
            } },
        component: lazyLoad("protected/Templates/DocumentLabelCustomize")
    },
    {
        path: "/customer-agreement/:template_id",
        name: "customer-agreement",
        meta: { layout: "SingleVue", breadcrumb: {
                label: "Agreement",
                parent: "templates"
            } },
        component: lazyLoad("protected/Templates/CustomerAgreement")
    },
    {
        path: "/admin-final-preview",
        name: "admin-final-preview",
        meta: { layout: "SingleVue", breadcrumb: {
                label: "Admin Preview",
                parent: ""
            } },
        component: lazyLoad("protected/Templates/AdminFinalPreview")
    },
    {
        path: "/admin-final-preview-word",
        name: "admin-final-preview-word",
        meta: { layout: "SingleVue", breadcrumb: {
                label: "Admin Preview Word",
                parent: ""
            } },
        component: lazyLoad("protected/Templates/AdminFinalPreviewWord")
    },
    {
        path: "/templates/word-document/comment",
        name: "templates-word-comment",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Templates", breadcrumb: {
                label: "Word Comment",
                parent: "templates"
            } },
        component: lazyLoad("protected/Templates/WordDocument/Comments")
    },
    {
        path: "/merge-field",
        name: "merge-field",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Templates", breadcrumb: 'Merge Field' },
        component: lazyLoad("protected/Templates/MergeField/MergeField")
    },
    {
        path: "/client-portal/:token",
        name: "client-portal",
        meta: { layout: "SingleVue", breadcrumb: {
                label: "Document Preview",
                parent: ""
            } },
        component: lazyLoad("protected/Templates/EndUserView/EndScreen")
    },
    {
        path: "/client-details-portal/:token/:id",
        name: "client-details-portal",
        meta: { layout: "SingleVue", breadcrumb: {
                label: "Document Details Preview",
                parent: ""
            } },
        component: lazyLoad("protected/Templates/EndUserView/EndScreenDetails")
    },
];
