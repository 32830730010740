import _ from "lodash";
import Api from "@/service/api/_mix_api";

const state = {
    isLoading: false,
    languages: []
};
const mutations = {
    setIsLoading(state, payload) {
        state.isLoading = !!payload;
    },
    setLanguages(state, payload) {
        state.types = [...payload];
    }
}
const actions = {
    fetch(context, payload) {
        const isForced = !!(payload?.forced || false);
        if (isForced || _.isEmpty(context.getters.languages)) {
            return Api.languages.index().then((response) => {
                if (response.status === 200) {
                    context.commit('setLanguages', [...response.data.data]);
                }
            })
        }
    }
}
const getters = {
    isLoading: (state) => state.isLoading || false,
    languages: (state) => state.languages || []
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}