import _ from "lodash";
import Api from "@/service/api/_mix_api";

const state = {
    isFetching: false,
    student_authorizations: [],
    student_authorization_details: {}
};
const mutations = {
    setIsFetching(state, payload) {
        state.isFetching = !!payload;
    },
    setStudentAuthorization(state, payload) {
        state.student_authorizations = [...payload];
    },
    setExistingStudentAuthorization(state, payload) {
        state.student_authorizations[payload.index] = payload.data;
    },
    setNewStudentAuthorization(state, data) {
        state.student_authorizations = [...data];
    },
    setStudentAuthorizationDetails(state, data) {
        state.student_authorization_details = data;
    }
};
const actions = {
    studentAuthorization(context, payload) {
        const isForced = !!payload.isForced;
        if (isForced || _.isEmpty(context.getters.student_authorizations)) {
            return Api.student_authorization.index(payload.uuid).then((response) => {
                if (response.status === 200) {
                    context.commit('setStudentAuthorization', [...response.data.data]);
                }
            })
        }
    },
    authorizationDetails(context, data) {
        return Api.student_authorization.show(data.uuid, data.id)
        .then((response) => {
            if (response.status === 200) {
                context.commit('setStudentAuthorizationDetails', {...response.data.data});
            }
        }).finally(() => context.commit('setIsFetching', true))
    },
    authorizationUpdate(context, data) {
        return Api.student_authorization.update(data.uuid, data.id, data.payload).then((response) => {
            if (response.status === 200) {
                context.commit('setStudentAuthorizationDetails', {...response.data.data});
            }
        })
    }
};
const getters = {
    isFetching: state => state.isFetching || false,
    student_authorizations: state => state.student_authorizations || [],
    student_authorization_details: state => state.student_authorization_details || {}
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}