import _ from "lodash";
import {resource_icons} from "@/common_icons";
import {menuPermission} from "@/helper/menu";

const parentRouteName = (name) => {
    if (_.isString(name) && name.indexOf('.') > -1) {
        return name.split('.')[0];
    }
    return name;
};
const matchRouter = (linkRoute, currentRoute) => {
    const linkRouteName = linkRoute?.name || null;
    const linkRouteParentName = parentRouteName(linkRouteName);
    const currentRouteName = currentRoute?.name || null;
    const currentRouteParentName = parentRouteName(currentRouteName);

    // console.log(
    //     `Link route name: ${linkRouteName} | `,
    //     `Link route parent name: ${linkRouteParentName} | `,
    //     `Current route name: ${currentRouteName} | `,
    //     `Current route parent name: ${currentRouteParentName} | `
    // );
    if (linkRouteName && currentRouteName) {
        return linkRouteParentName === currentRouteParentName ||
            linkRouteParentName === currentRouteName ||
            currentRouteParentName === linkRouteName ||
            linkRouteName === currentRouteName;
    }
    return false;
};

const state = {
    visible: true,
    menu: [
        {
            label: 'Dashboard',
            handle: 'dashboard',
            icon: resource_icons.dashboard,
            route: {name: 'dashboard'},
            active: false,
            permissionName: true
        },
        {
            label: 'Manage student',
            handle: 'manage-student',
            route: {name: 'student.index'},
            icon: resource_icons.student,
            active: false,
            permissionName: menuPermission({name: 'student.index'})
        },
        {
            label: 'Manage user',
            handle: 'manage-user',
            route: null,
            icon: resource_icons.user,
            permissionName: null,
            children: [
                {
                    label: 'All staffs',
                    handle: 'manage-user-all-staffs',
                    route: {name: 'staff.index'},
                    active: false,
                    permissionName: menuPermission({name: 'staff.index'})
                },
                // {
                //     label: 'All guardians',
                //     handle: 'manage-user-all-guardians',
                //     route: {name: 'guardian.index'},
                //     active: false,
                //     permissionName: 'guardians-view',
                // }
            ]
        },
        {
            label: 'Manage agency',
            handle: 'manage-agency',
            route: {name: 'agency.index'},
            icon: resource_icons.agency,
            active: false,
            permissionName: menuPermission({name: 'agency.index'})
        },
        {
            label: 'Authorization',
            handle: 'manage-authorization',
            route: {name: 'authorization.index'},
            icon: resource_icons.authorization,
            active: false,
            permissionName: menuPermission({name: 'authorization.index'})
        },
        {
            label: 'Manage referring provider',
            handle: 'referring-provider',
            route: {name: 'referring-provider.index'},
            icon: resource_icons.referring_provider,
            permissionName: menuPermission({name: 'referring-provider.index'})
        },
        // {
        //     label: 'Manage schedule',
        //     handle: 'manage-schedule',
        //     route: null,
        //     icon: 'fa-calendar',
        //     permissionName: null,
        //     children: [
        //         {
        //             label: 'All sessions',
        //             handle: 'manage-schedule-all-sessions',
        //             route: {name: 'appointment-list'},
        //             active: false,
        //             permissionName: 'calendar-add-edit'
        //         },
        //         {
        //             label: 'Session calender',
        //             handle: 'manage-schedule-session-calender',
        //             route: {name: 'global-calendar'},
        //             active: false,
        //             permissionName: 'calendar-add-edit'
        //         }
        //     ]
        // },
        // {
        //     label: 'Manage treatment plan',
        //     route: null,
        //     handle: 'manage-treatment-plan',
        //     icon: 'fa-book',
        //     permissionName: null,
        // },
        // {
        //     label: 'Manage case document',
        //     handle: 'manage-case-document',
        //     route: null,
        //     icon: 'fa-book',
        //     permissionName: null
        // },
        {
            label: 'Reports',
            handle: 'reports',
            route: null,
            icon: 'fa-line-chart',
            permissionName: null,
            children: [
                // {
                //     label: 'Behavior Graph',
                //     handle: 'reports-behavior-graph',
                //     route: {name: 'student-behaviour-graph-view'},
                //     active: false,
                //     permissionName: 'graph-student-skill-behavior'
                // },
                // {
                //     label: 'Note Helper Graph',
                //     handle: 'reports-helper-graph',
                //     route: {name: 'student-note-helper-graph'},
                //     active: false,
                //     permissionName: 'student-skill-graph-view'
                // }
            ]
        },
        {
            label: 'Manage role & permission',
            handle: 'permission',
            route: null,
            icon: resource_icons.role_and_permission,
            permissionName: null,
            children: [
                {
                    label: 'Staff Title/Group',
                    handle: 'settings-staff-title-or-groups',
                    route: {name: 'title.index'},
                    active: false,
                    permissionName: menuPermission({name: 'title.index'})
                },
                {
                    label: 'Role',
                    handle: 'settings-roles',
                    route: {name: 'roles.index'},
                    active: false,
                    permissionName: menuPermission({name: 'roles.index'})
                },
                // {
                //     label: 'Delegated staff permission',
                //     handle: 'settings-delegated-agency-staff-permission',
                //     route: {name: 'delegated-permission.principal'},
                //     active: false,
                //     permissionName: null
                // }
                
            ]
        },
        {
            label: 'Settings',
            handle: 'settings',
            route: null,
            icon: resource_icons.setting,
            permissionName: null,
            children: [
                // {
                //     label: 'Funder',
                //     handle: 'settings-funders',
                //     route: {name: 'funder.index'},
                //     active: false,
                //     permissionName: true, //'funder-view'
                // },
                {
                    label: 'Kareo Funder',
                    handle: 'settings-kareo-funder',
                    route: {name: 'kareo-funder.index'},
                    active: false,
                    permissionName: menuPermission({name: 'kareo-funder.index'}), //'kareo-funder-view'
                },
                {
                    label: 'Certification',
                    handle: 'settings-certifications',
                    route: {name: 'certification.index'},
                    active: false,
                    permissionName: menuPermission({name: 'certification.index'}), //'certifications-view'
                },
                {
                    label: 'Certification Type',
                    handle: 'settings-certification-types',
                    route: {name: 'certification-type.index'},
                    active: false,
                    permissionName: menuPermission({name: 'certification-type.index'}), //'certification-type-view'
                },
                {
                    label: 'Service Line',
                    handle: 'settings-service-line',
                    route: {name: 'service-line.index'},
                    active: false,
                    permissionName: menuPermission({name: 'service-line.index'}), //'service-line-view'
                },
                {
                    label: 'Service',
                    handle: 'settings-services',
                    route: {name: 'service.index'},
                    active: false,
                    permissionName: menuPermission({name: 'service.index'}), //'service-view'
                },
                {
                    label: 'Place of Service',
                    handle: 'settings-place-of-service',
                    route: {name: 'place-of-service.index'},
                    active: false,
                    permissionName: menuPermission({name: 'place-of-service.index'}), //'place-of-service-view'
                },
                {
                    label: 'Pay Code',
                    handle: 'settings-pay-code',
                    route: {name: 'pay-code.index'},
                    active: false,
                    permissionName: menuPermission({name: 'pay-code.index'}), //'pay-code-view'
                }
            ]
        }
    ]
};
const mutations = {
    updateVisibility(state, payload) {
        state.visible = !!payload;
    },
    toggleHasOpen(state, payload) {
        const indexOfPayload = state.menu.findIndex(item => item.handle === payload);
        if (!!state.menu[indexOfPayload]) {
            const menu = _.cloneDeep([...state.menu]);
            const status = menu[indexOfPayload]['has_open'] || false;
            menu[indexOfPayload] = {
                ...menu[indexOfPayload],
                has_open: !status
            };
            state.menu = [...menu];
        }
    },

    updateStatusFromRouteURL(state, payload) {
        if (payload?.name) {
            const restore =  [...state.menu].map(item => {
                item.active = false;
                if (!_.isEmpty(item?.children)) {
                    item.children = [...item.children].map(ch_item => {
                        ch_item.active = false;
                        return ch_item;
                    })
                }
                return item;
            });
            const collections = [...restore].map((item, index) => {
                item['_index'] = index;
                return item;
            }).filter(item => {
                return (item.route && matchRouter(item.route, payload)) ||
                    [...(item.children || [])]
                        .filter(item => item.route && matchRouter(item.route,payload)).length > 0;
            }).map(item => item._index);
            if (!_.isEmpty(collections)) {
                for(let index = 0; index < collections.length; index++) {
                    const menu_index = collections[index];
                    restore[menu_index]['active'] = true;
                    if (restore[menu_index]?.children) {
                        for(let ch_index = 0; ch_index < restore[menu_index]?.children.length; ch_index++) {
                            if (matchRouter(restore[menu_index]?.children[ch_index].route, payload)) {
                                restore[menu_index].children[ch_index].active = true;
                            }
                        }
                    }
                }
                state.menu = [...restore];
            }
        }
    }
};
const actions = {
    toggleVisibility(context) {
        const value = !context.getters.visibility;
        context.commit('updateVisibility', value);
    }
};
const getters = {
    visibility: (state) => state.visible,
    menu: (state, getters, rootState,  rootGetters) => {
        const guards = (permissionName) => {
            if (permissionName === null) {
                return rootGetters.typeAdmin;
            }
            if (_.isBoolean(permissionName)) {
                return permissionName;
            }
            if (_.isFunction(permissionName)) {
                return permissionName();
            }
            if (_.isString(permissionName)) {
                return rootGetters.can(permissionName);
            }
            if (_.isArray(permissionName)) {
                return rootGetters.canAny([...permissionName]);
            }
            return false;
        };
        const checkMenuGuard = (collection) => {
            const hasChildren = _.has(collection,'children') && _.size(collection?.children || []) > 0;
            const parentPermission = collection?.permissionName || null;
            const childrenPermissions = hasChildren ? (collection.children || [])
                .map(item => item?.permissionName)
                .filter(item => !_.isUndefined(item)) : [];
            if (!hasChildren) {
                return guards(parentPermission);
            }
            return childrenPermissions.map(permission => guards(permission)).filter(item => item === true).length > 0;
        };
        return _.cloneDeep(state.menu)
            .filter(item => checkMenuGuard(item))
            .map(item => {
                if (_.has(item,'children') && !_.isEmpty(item.children || [])) {
                    item['children'] = [...item.children].filter(child_item => guards(child_item.permissionName));
                    item.active = [...item.children].filter(child_item => child_item?.active || false).length > 0;
                }
                item['has_open'] = item.has_open || item.active;
                return item;
            })
            .filter(item => {
                return !_.isEmpty(item?.children) || !_.has(item, 'children');
            });
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
