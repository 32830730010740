import HTTP from "@/service/api/_axios_setup";

export default {
    index: (query) => HTTP.get('/student',{params: (query || null)}),
    show: (uuid) => HTTP.get('/student/'+uuid),
    update: (uuid, payload) => HTTP.put('/student/'+uuid, payload),
    create: (payload) => HTTP.post('/student', payload),
    funders: (uuid) => HTTP.get('/student/'+uuid+'/student-payer'),
    destroy(student, hasRestore, hasInBulk) {
        const url = hasInBulk ? `/student/bulk` : `/student/${student}`;
        return HTTP.delete(url, {
            params: {

            }
        })
    }
}
