import _ from "lodash";

export default {
    install(Vue, options) {
        const removeUnused = (params) => {
            const filters = {};
            Object.keys(params).forEach((key) => {
                const isNotUndefined = !_.isUndefined(params[key])
                const isNotNull = !_.isNull(params[key]);
                const isNotEmptyString = (_.isString(params[key]) && _.trim(params[key]) !== '') || !_.isString(params[key]);
                const isNotEmptyObjectOrArray = !_.isEmpty(params[key]) || (!_.isObject(params[key]) && !_.isArray(params[key]));
                if (isNotUndefined && isNotNull && isNotEmptyString && isNotEmptyObjectOrArray) {
                    filters[key] = params[key];
                }
            });
            return filters;
        };
        Vue.prototype.$routeQuery = function (params, extra_params, forcePage, forceSoftDelete, forceSearch) {
            const restoreParams = (this.$route.query || {});
            params = _.isEmpty(params) ? (this.$route.query || {}) : params;
            extra_params = extra_params || {};
            if (!forcePage && restoreParams.hasOwnProperty('page') && !params.hasOwnProperty('page')) {
                params['page'] = restoreParams['page'];
            }
            if (!forceSoftDelete && restoreParams.hasOwnProperty('only_trashed') && !params.hasOwnProperty('only_trashed')) {
                params['only_trashed'] = restoreParams['only_trashed'];
            }
            if (!forceSearch && restoreParams.hasOwnProperty('s') && !params.hasOwnProperty('s')) {
                params['s'] = restoreParams['s'];
            }
            return  _.cloneDeep({
                ...removeUnused(extra_params),
                ...removeUnused(params)
            });
        }
        Vue.prototype.$routeQuerySoftDeleteTab = function(value, params, extra_params) {
            params = params || {};
            extra_params = extra_params || {};
            const restored = {
                ...removeUnused(extra_params),
                ...removeUnused(params)
            };
            switch (value) {
                case 'trash':
                    _.set(restored, 'only_trashed', true);
                    break;
                default:
                    restored.hasOwnProperty('only_trashed') && delete restored['only_trashed'];
                    break;
            }
            return restored;
        };
        Vue.prototype.$routeQueryPagination = function(value, params, extra_params) {
            const restored = this.$routeQuery(params, extra_params);
            if (_.isNumber(value)) {
                _.set(restored, 'page', value);
            } else {
                restored.hasOwnProperty('page') && delete restored['page'];
            }
            return restored;
        };
        Vue.prototype.$routeQuerySearch = function(value, params, extra_params) {
            const restored = this.$routeQuery(params, extra_params);
            if (value) {
                restored['s'] = value;
            } else if (restored.hasOwnProperty('s')) {
                delete restored['s'];
            }
            return restored;
        }
        Vue.prototype.$routeQuerySorting = function(sortBy,sort, params, extra_params) {
            const restored = this.$routeQuery(params, extra_params);
            if (sortBy && sort && ['asc','desc'].includes((sort || '').toLowerCase())) {
                restored['sort_by'] = sortBy;
                restored['sort'] = sort;
            } else if (restored.hasOwnProperty('sort_by') || restored.hasOwnProperty('sort')) {
                if (restored.hasOwnProperty('sort_by')) {
                    delete restored['sort_by'];
                }
                if (restored.hasOwnProperty('sort')) {
                    delete restored['sort'];
                }
            }
            return restored;
        }
    }
}
