import { lazyLoad } from "@/routers/utils/helper";

export const routes = [
    {
        path: '/certification',
        name: 'certification.index',
        meta: {
            middleware: 'auth',
            user_permission: null,
        },
        component: lazyLoad('pages/certification/Index')
    },
    {
        path: "/certification/create",
        name: "certification.create",
        meta: { middleware: "auth", user_permission: null},
        component: lazyLoad("pages/certification/parts/index/Add-certification")
    },
    {
        path: "/certification/:certification_id",
        name: "certification.edit",
        redirect:{ name: 'certification.show' },
        meta: {middleware: "auth", user_permission: null},
        component: lazyLoad("pages/certification/show/Layout"),
        children: [
            {
                path: "",
                name: "certification.show",
                meta: {middleware: "auth", user_permission: null},
                component: lazyLoad('pages/certification/parts/index/Edit-certification')
            },
        ]
    },
    // {
    //     path: "/certification/:certification_id/show",
    //     name: "certification.show",
    //     meta: {
    //         layout: "ProtectedVue", middleware: "login", sidebar: "profile", user_permission: null, breadcrumb: {
    //             label: "Edit Certification",
    //             parent: "certification.index"
    //         }
    //     },
    //     component: lazyLoad("pages/certification/Show")
    // },
    // {
    //     path: "/certifications/",
    //     name: "certifications",
    //     meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"null", breadcrumb: 'Certifications'},
    //     component: lazyLoad("protected/organization/Certification/List-certification")
    // },
    // {
    //     path: "/delete-certifications",
    //     name: "delete-certifications",
    //     meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"certifications-delete", breadcrumb: {
    //             label: "Delete Certification",
    //             parent: "certifications"
    //         }},
    //     component: lazyLoad("protected/organization/Certification/Delete-certification")
    // },
    // {
    //     path: "/import-certifications",
    //     name: "import-certifications",
    //     meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:null, breadcrumb: {
    //             label: "Import Certification",
    //             parent: "certifications"
    //         }},
    //     component: lazyLoad("protected/organization/Certification/Import-certification")
    // },
];
