<script>
import HistoryNavigator from "@/components/layouts/authenticated/parts/Header/HistoryNavigator.vue";
import MiniCreateMenu from "@/components/layouts/authenticated/parts/Header/MiniCreateMenu.vue";

export default {
  name: "MixSecondaryHeader",
  components: {MiniCreateMenu, HistoryNavigator}
}
</script>

<template>
  <div class="page-header auth-secondary-header">
    <div class="col-sm-12 col-md-12">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="d-flex flex-row align-items-center">
            <HistoryNavigator />
            <div class="search-box-global">
              <portal-target name="search-or-back" multiple />
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 d-flex flex-row align-items-center">
          <div class="ml-auto">
            <MiniCreateMenu />
          </div>
<!--          <Breadcrumbs class="d-flex justify-content-md-end flex-wrap" />-->
          <!-- <v-breadcrumbs
              :items="breadCrumbs"
              divider=">"
          >
              <template v-slot:item="props">
              <router-link :to="props.item.href" v-if="!props.item.disabled">
                  <v-breadcrumbs-item
                  :class="[props.item.disabled && 'disabled']"
                  >
                  {{ props.item.text }}
                  </v-breadcrumbs-item>
              </router-link>
              <v-breadcrumbs-item
                  :class="[props.item.disabled && 'disabled']"
                  v-else
              >
                  {{ props.item.text }}
              </v-breadcrumbs-item>
              </template>
          </v-breadcrumbs> -->
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
