import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/folders",
        name: "folders",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"File Management", breadcrumb: 'Folders' },
        component: lazyLoad("protected/folder/folder-list")
    },
    {
        path: "/add-folder",
        name: "add-folder",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"File Management", breadcrumb: {
                label: "Add New Folder",
                parent: "folders"
            } },
        component: lazyLoad("protected/folder/folder-add")
    },
    {
        path: "/edit-folder/:folder_id",
        name: "edit-folder",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"File Management", breadcrumb: {
                label: "Edit Folder",
                parent: "folders"
            }},
        component: lazyLoad("protected/folder/folder-edit")
    },
    {
        path: "/view-folder/:folder_id",
        name: "view-folder",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"File Management", breadcrumb: {
                label: "Files",
                parent: "folders"
            }},
        component: lazyLoad("protected/file/file-list")
    },
    {
        path: "/delete-folder",
        name: "delete-folder",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"File Management", breadcrumb: {
                label: "Deleted Folders",
                parent: "folders"
            } },
        component: lazyLoad("protected/folder/folder-delete")
    },
];
