import Vue from 'vue'
import _ from "lodash";
import Api from "@/service/api/_mix_api";
import {search} from '@/service/localFilters';

const state = {
    loading: false,
    roles: [],
    localFilters: {}
};
const mutations = {
    setRoles(state, payload) {
        state.roles = payload;
    },
    setLoading(state, payload) {
        state.loading = !!payload;
    }
};
const actions = {
    updateRoleDetails(context, payload){
        context.commit('setRoleDetails', {...payload});
    },
    fetchRoles(context, payload) {
        const isForced = !!payload;
        if (isForced || _.isEmpty(context.getters.roles)) {
            context.commit('setLoading', true);
            return Api.role.index().then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    context.commit('setRoles', [...response.data.data])
                }
            }).finally(() => context.commit('setLoading', false))
        }
    },
    fetchRoleDetails(context, uuid) {
        // if (_.isEmpty(context.getters.getRoleDetails)) {
            return Api.role.show(uuid).then( response => {

                //context.commit('setRoleDetails', {...response.data.data})
            })
        // }
    },
    deleteRole(context, payload) {
        const uuid = payload?.uuid;
        const restoreUserRole = !!uuid ? [...context.getters.roles].find(item => item.uuid === uuid) : null;
        if (restoreUserRole) {
            Api.role.destroy(uuid)
                .then(response => {
                    if (response.status >= 200 && response.status < 300) {
                        let newrolelist = [...context.getters.roles].filter(item => !_.isEqual(item.uuid, uuid));
                        context.commit('setRoles', [...newrolelist]);
                        Vue.$toast.open({message: response.data.msg, type: 'success'});
                    }
                })
                .catch((error) => {
                    context.commit('setRoles', _.concat([...context.getters.roles], {...restoreUserRole}));
                    if (error?.response) {
                        Vue.$toast.open({message: error?.response.data.msg, type: 'error'});
                    }
                })
        }
    },
};
const getters = {
    loading: (state) => state.loading,
    roles: state => state.roles || [],
    rolesWithFilters: state => {
        const filter = [
            // Search
            (collection) => search(collection, state.localFilters, ['name']),
        ];
        return filter.reduce((collection, value) => {
            return value(collection);
        }, (state.roles || []));
    },
    roleSelectionOptions: (state) => {
        const options = [...state.roles].map(item => ({
            value: item.id,
            text: item.name
        }));
        return [
            {value: null, text: 'Select role'},
            ...options
        ]
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
