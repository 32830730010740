import HTTP from "@/service/api/_axios_setup";

export default {
    showBasicDetails: () => HTTP('/profile/show-basic'),
    updateBasicDetails: (payload) => HTTP.put('/profile/update-basic', payload),
    updatePassword: (payload) => HTTP.put('/profile/update-password', payload),
    updateSignature: (payload) => HTTP.put('/profile/update-signature', payload),
    addressIndex: () => HTTP.get('/profile/address'),
    addressCreate: (payload) => HTTP.post('/profile/address', payload),
    addressUpdate: (uuid, payload) => HTTP.put(`/profile/address/${uuid}`, payload),
    addressDelete: (uuid, payload) => HTTP.delete(`/profile/address/${uuid}`)
}
