import _ from "lodash";
import Api from "@/service/api/_mix_api";
const state = {
    taxonomies: [],
};
const mutations = {
    setTaxonomies(state, payload) {
        state.taxonomies = payload;
    },
};
const actions = {
    fetch(context, payload) {
        const isForced = !!payload;
        if (isForced || _.isEmpty(context.getters.taxonomies)) {
            return Api.taxonomy.index().then((response) => {
                if (response.status === 200) {
                    context.commit('setTaxonomies', [...response.data.data]);
                }
            })
        }
    }
    
};
const getters = {
    taxonomies: state => state.taxonomies,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
