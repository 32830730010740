import _ from "lodash";
import Api from "@/service/api/_mix_api";
// import * as JsSearch from "js-search";
import { search, sorting } from "@/service/localFilters";

const state = {
    certifications: [],
    localFilters: {}
};
const mutations = {
    setCertifications(state, payload) {
        state.certifications = [...payload];
    },
    setLocalFilters(state, payload) {
        state.localFilters = {...payload};
    }
};
const actions = {
    fetch(context, payload) {
        const isForced = !!payload;
        // if (isForced || _.isEmpty(context.getters.certifications)) {
            return Api.certification.index().then((response) => {
                if (response.status === 200) {
                    let result = [ ...response.data.data ];
                    result.forEach(item => {
                        if (item.certificationType && item.certificationType.name) {
                            item.certification_type_name = item.certificationType.name;
                        }else {
                            item.certification_type_name = '';
                        }
                    });
                    // console.log(result);
                    context.commit('setCertifications', [...result]);
                }
            })
        // }
    }
};
const getters = {
    certifications: state => state.certifications || [],
    certificationsWithFilter: state => {
        const filter = [
            // Search
            (collection) => search(collection, state.localFilters, ['name','certification_type_name']),
            // Sorting
            (collection) => sorting(collection, state.localFilters)
        ];
        return filter.reduce((collection, value) => {
            return value(collection);
        }, (state.certifications || []));
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
