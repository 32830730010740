import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/rethink-sessions-data",
        name: "rethink-sessions-data",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Rethink Session Data", breadcrumb: 'Rethink Session Data'},
        component: lazyLoad("protected/calendar/RethinkSessionData")
    },
];
