import _ from "lodash";
import Api from "@/service/api/_mix_api";

const state = {
    updating_details: false,
    fetching_details: false,
    details: {
        id: null, // payload.id,
        uuid: null, // payload.uuid,
        first_name: null, // payload.first_name,
        middle_name: null, // payload.middle_name,
        last_name: null, // payload.last_name,
        username: null, // payload.username,
        email: null, // payload.email,
        email_verified_at: null, // payload.email_verified_at,
        tel_number: null, // payload.tel_number,
        dob: null, // payload.dob,
        gender_id: null, // payload.gender?.id,
        tel_number_country_code: null, // payload.tel_number_country_code,
        tel_number_verified_at: null, // payload.tel_number_verified_at,
        avatar: null, // payload.avatar,
        writtenSignature: null, // payload.writtenSignature,
        imageSignature: null, // payload.imageSignature,
        npi_number: null, // _.get(payload, 'user_staff_meta.npi_number'),
        ssn_number: null, // _.get(payload, 'user_staff_meta.ssn_number'),
        ein_number: null, // _.get(payload, 'user_staff_meta.ein_number'),
        medicaid_id: null, // _.get(payload, 'user_staff_meta.medicaid_id'),
        practitioner_id: null, // _.get(payload, 'user_staff_meta.practitioner_id'),
        bcb_id: null, // _.get(payload, 'user_staff_meta.bcb_id'),
        date_of_birth: null, // _.get(payload, 'user_staff_meta.date_of_birth'),
        show_rbt: null, // _.get(payload, 'user_staff_meta.show_rbt'),
        show_scheduling: null, // _.get(payload, 'user_staff_meta.show_scheduling'),
        user_degree_type_id: null, // _.get(payload, 'user_staff_meta.user_degree_type_id'),
        company_staff_id: null, // _.get(payload, 'user_staff_meta.company_staff_id'),
        payroll_type_id: null, // _.get(payload, 'user_staff_meta.payroll_type.id'),
        apply_overtime_calculations: null, // _.get(payload, 'user_staff_meta.apply_overtime_calculations'),
        monthly_salary_rate: null, // _.get(payload, 'user_staff_meta.monthly_salary_rate'),
        can_handle_aggression: null, // _.get(payload, 'user_staff_meta.can_handle_aggression'),
        experience_type_id: null, // _.get(payload, 'user_staff_meta.experience_type.id'),
        taxonomy_code: null, // _.get(payload, 'user_staff_meta.taxonomy_code'),
        user_group_id: null, // payload.user_group?.id,
        user_status_id: null, // payload.user_status?.id,
        timezone_id: null, // payload.timezone?.id,
        languages: [], // payload.languages,
        user_staff_service_lines: [], // payload.user_staff_service_lines,
        userStaffAvailabilities: [], // payload.userStaffAvailabilities,
        certifications: [], // payload.certifications,
        user_staff_paycodes: [], // payload.user_staff_paycodes,
        userAddresses: [], // payload.userAddresses,
        created_at: null, // payload.created_at,
        updated_at: null, // payload.updated_at,
        deleted_at: null, // payload.deleted_at
    }
};
const mutations = {
    setUpdatingDetails(state, payload) {
        state.updating_details = !!payload;
    },
    setFetchingDetails(state, payload) {
        state.fetching_details = !!payload;
    },
    setUserCommonAndStaffMeta(state, payload) {
        state.details = {...payload};
    },
    setWrittenSignature(state, payload) {
        state.details.written_signature = payload;
    },
    setUploadedSignature(state, payload) {
        state.details.uploaded_signature = payload;
    }
    // setPayroll(state, payload) {
    //     state.payroll_type = [...payload];
    // },
    // setExperienceType(state, payload) {
    //     state.experience_type = [...payload];
    // },
    // setLanguages(state, payload) {
    //     state.languages = [...payload];
    // }
};
const actions = {
    fetchUserCommonAndStaffMeta(context, payload) {
        const forced = !!(payload?.forced || false);
        if (forced || _.isNull(context.getters.uuid) || context.getters.uuid !== payload.uuid) {
            context.commit('setFetchingDetails', true);
            return Api.staff.show(payload.uuid).then((response) => {
                if (response.status === 200) {
                    context.commit('setUserCommonAndStaffMeta', {...response.data.data});
                }
            }).finally(() => {
                context.commit('setFetchingDetails', false);
            })
        }
    },
    updateUserCommonAndStaffMeta(context, payload) {
        context.commit('setUpdatingDetails', true);
        Api.staff.update(payload.uuid, payload.payload)
        .then((response) => {
            if(response.status >= 200 && response.status < 300) {
                context.commit('setUserCommonAndStaffMeta', {...response.data.data});
                context.commit('staff_list/setStaff', {...response.data.data}, {root: true});
            }
            if (payload.callback && _.isFunction(payload.callback)) {
                payload.callback(response);
            }
        }).finally(() => context.commit('setUpdatingDetails', false));
    }
    // staffAddress(context, payload) {
    //     const isForced = !!(payload?.forced || false);
    //     if (isForced || _.isEmpty(context.getters.staff_details)) {
    //         return Api.staff_address.create(payload.uuid, payload.data).then((response) => {
    //             if (response.status === 200) {
    //                 context.commit('setStaffDetails', {...context.getters.staff_details, ...{userAddresses: response.data.data}});
    //             }
    //         })
    //     }
    // },
    // getAgeGroup(context, payload) {
    //     const isForced = !!(payload?.forced || false);
    //     if (isForced || _.isEmpty(context.getters.age_group)) {
    //         return Api.age_group.index().then((response) => {
    //             if (response.status === 200) {
    //                 context.commit('setAgeGroup', [...response.data.data]);
    //             }
    //         })
    //     }
    // },
    // getPayrollType(context, payload) {
    //     const isForced = !!(payload?.forced || false);
    //     if (isForced || _.isEmpty(context.getters.payroll_type)) {
    //         return Api.payroll_type.index().then((response) => {
    //             if (response.status === 200) {
    //                 context.commit('setPayroll', [...response.data.data]);
    //             }
    //         })
    //     }
    // },
    // getExperienceType(context, payload) {
    //     const isForced = !!(payload?.forced || false);
    //     if (isForced || _.isEmpty(context.getters.experience_type)) {
    //         return Api.experience_type.index().then((response) => {
    //             if (response.status === 200) {
    //                 context.commit('setExperienceType', [...response.data.data]);
    //             }
    //         })
    //     }
    // },
    // getLanguages(context, payload) {
    //     const isForced = !!(payload?.forced || false);
    //     if (isForced || _.isEmpty(context.getters.languages)) {
    //         return Api.languages.index().then((response) => {
    //             if (response.status === 200) {
    //                 context.commit('setLanguages', [...response.data.data]);
    //             }
    //         })
    //     }
    // }

};
const getters = {
    uuid: (state) => state.details.uuid || null,
    details: (state) => state.details,
    form: (state) => ({
        id: state.details?.id,
        uuid: state.details?.uuid,
        name: state.details?.name,
        first_name: state.details?.first_name,
        middle_name: state.details?.middle_name,
        last_name: state.details?.last_name,
        username: state.details?.username,
        email: state.details?.email,
        email_verified_at: state.details?.email_verified_at,
        tel_number: state.details?.tel_number,
        date_of_birth: state.details?.date_of_birth,
        gender_id: _.get(state.details, 'gender.id', null),
        tel_number_country_code: state.details?.tel_number_country_code,
        tel_number_verified_at: state.details?.tel_number_verified_at,
        avatar: state.details?.avatar,
        writtenSignature: state.details?.writtenSignature,
        imageSignature: state.details?.imageSignature,
        npi_number: _.get(state.details, 'user_staff_meta.npi_number', null),
        ssn_number: _.get(state.details, 'user_staff_meta.ssn_number', null),
        ein_number: _.get(state.details, 'user_staff_meta.ein_number', null),
        medicaid_id: _.get(state.details, 'user_staff_meta.medicaid_id', null),
        practitioner_id: _.get(state.details, 'user_staff_meta.practitioner_id', null),
        bcb_id: _.get(state.details, 'user_staff_meta.bcb_id', null),
        show_rbt: _.get(state.details, 'user_staff_meta.show_rbt', false),
        show_scheduling: _.get(state.details, 'user_staff_meta.show_scheduling', false),
        user_degree_type_id: _.get(state.details, 'user_staff_meta.user_degree_type_id', null),
        company_staff_id: _.get(state.details, 'user_staff_meta.company_staff_id', null),
        payroll_type_id: _.get(state.details, 'user_staff_meta.payroll_type.id', null),
        apply_overtime_calculations: _.get(state.details, 'user_staff_meta.apply_overtime_calculations', null),
        monthly_salary_rate: _.get(state.details, 'user_staff_meta.monthly_salary_rate', null),
        can_handle_aggression: _.get(state.details, 'user_staff_meta.can_handle_aggression', null),
        experience_type_id: _.get(state.details, 'user_staff_meta.experience_type.id', null),
        taxonomy_code: _.get(state.details, 'user_staff_meta.taxonomy_code', null),
        user_group_id: _.get(state.details, 'user_group.id', null),
        user_status_id: _.get(state.details, 'user_status.id', null),
        timezone_id: _.get(state.details, 'timezone.id'),
        languages: state.details?.languages || [],
        user_staff_service_lines: state.details?.user_staff_service_lines || [],
        userStaffAvailabilities: state.details?.userStaffAvailabilities || [],
        certifications: state.details?.certifications || [],
        user_staff_paycodes: state.details?.user_staff_paycodes || [],
        userAddresses: state.details?.userAddresses || [],
        created_at: state.details.created_at,
        updated_at: state.details.updated_at,
        deleted_at: state.details.deleted_at,
    }),
    hasLoadingFetch: (state) => state.fetching_details || false,
    hasLoadingUpdate: (state) => state.updating_details || false
    // general_info: state => state.general_info || {},
    // age_group: state => state.age_group || [],
    // payroll_type: state => state.payroll_type || [],
    // experience_type: state => state.experience_type || [],
    // languages: state => state.languages || [],

};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
