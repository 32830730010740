import _ from "lodash";

const state = {
    countFilterApplied: null,
    tabs: [
        {label: 'Publish', handle: 'publish'},
        {label: 'Trash', handle: 'trash'},
    ]
};
const mutations = {
    setCountFilterApplied(state, payload) {
        state.countFilterApplied = !payload ? null : _.toNumber(value)
    }
};
const actions = {
};
const getters = {
    countFilterApplied: (state) => state.countFilterApplied,
    tabs: state => (withoutTrash) => {
        if (withoutTrash) {
            return state.tabs.filter((item) => item.handle !== 'trash');
        }
        return state.tabs;
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}