import {lazyLoad} from "@/routers/utils/helper";

export const routes =  [
    {
        path: "/dynamic-pdf-session-template",
        name: "dynamic-pdf-session-template",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Auto Generate Session", breadcrumb: 'PDF Session Templates' },
        component: lazyLoad("protected/organization/DynamicSessionTemplate/PdfListSessionTemplate")
    },
    {
        path: "/dynamic-pdf-session-template/add",
        name: "add-dynamic-pdf-session-template",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Auto Generate Session", breadcrumb: {
                label: "Add Pdf Session Template",
                parent: "dynamic-pdf-session-template"
            } },
        component: lazyLoad("protected/organization/DynamicSessionTemplate/PdfAddSessionTemplate")
    },
    {
        path: "/dynamic-pdf-session-template/edit/:temp_id",
        name: "edit-dynamic-pdf-session-template",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Auto Generate Session", breadcrumb: {
                label: "Edit Pdf Session Template",
                parent: "dynamic-pdf-session-template"
            } },
        component: lazyLoad("protected/organization/DynamicSessionTemplate/PdfEditSessionTemplate")
    },
];
