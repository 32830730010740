import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/authorization",
        name: "authorization.index",
        meta: {
            middleware: "auth",
            user_permission:true,
        },
        component: lazyLoad("pages/authorization/Index")
    },
    // {
    //     path: "/kareo-funder/create",
    //     name: "kareo-funder.create",
    //     meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"kareo-funder-add-edit", breadcrumb: {
    //             label: "Add Kareo Funder",
    //             parent: "kareo-funder.index"
    //         } },

    //     component: lazyLoad("pages/kareo-funder/parts/index/Add-kareo-funder")
    // },
    // {
    //     path: "/kareo-funder/:kareo_funder_id",
    //     name: "kareo-funder.edit",
    //     meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"kareo-funder-add-edit", breadcrumb: {
    //             label: "Edit Kareo Funder",
    //             parent: "kareo-funder.index"
    //         } },
    //     component: lazyLoad("pages/kareo-funder/parts/index/Edit-kareo-funder")
    // },
];
