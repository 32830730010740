import HTTP from "@/service/api/_axios_setup";

export default {
    index: (uuid) => HTTP.get('/agency/'+uuid+'/payer'),
    store: (uuid, payload) => HTTP.post('/agency/'+uuid+'/payer', payload),
    show: (uuid, id) => HTTP.get('/agency/'+uuid+'/payer/'+id),
    update: (uuid, id, payload) => HTTP.put('/agency/'+uuid+'/payer/'+id, payload),
    getPayerServiceLine: (uuid, id) => HTTP.get('/agency/'+uuid+'/agency-payer/'+id+'/service-line'),
    addPayerServiceLine: (uuid, id, payload) => HTTP.post('/agency/'+uuid+'/agency-payer/'+id+'/service-line', payload),
    getService: (uuid, id) => HTTP.get('/agency/'+uuid+'/agency-payer-service-line/'+id+'/agency-payer-service'),
    addService: (uuid, id, payload) => HTTP.post('/agency/'+uuid+'/agency-payer-service-line/'+id+'/agency-payer-service', payload),
    editService: (uuid, id,serviceId, payload) => HTTP.put('/agency/'+uuid+'/agency-payer-service-line/'+id+'/agency-payer-service/'+serviceId, payload),
    getBillingSubmissionMethod: () => HTTP.get('/billing-submission-method'),
    getBillingRoundingType: () => HTTP.get('/billing-code-rounding-type'),
    getBillingUnitType: () => HTTP.get('/billing-unit-type'),
    getClaimFormTemplate: () => HTTP.get('/claim-form-template'),
}