import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/case-document-list",
        name: "case-document-list",
        meta: { layout: "ProtectedVue", middleware: "auth", sidebar: "profile", user_permission: "Templates", breadcrumb: 'Case Documents' },
        component: lazyLoad("protected/Templates/CaseDocument/CaseDocumentList")
    },
    {
        path: "/deleted-case-documents",
        name: "deleted-case-documents",
        meta: { layout: "ProtectedVue", middleware: "auth", sidebar: "profile", user_permission: "Templates", breadcrumb: {
                label: "Deleted Case Documents",
                parent: "case-document-list"
            } },
        component: lazyLoad("protected/Templates/CaseDocument/CaseDocumentsDeletedList")
    },
    {
        path: "/case-document-add",
        name: "case-document-add",
        meta: {
            layout: "ProtectedVue", middleware: "auth", sidebar: "profile", user_permission: "Templates", breadcrumb: {
                label: "Case Document Add",
                parent: "case-document-list"
            }
        },
        component: lazyLoad("protected/Templates/CaseDocument/CaseDocumentAdd")
    },
    {
        path: "/case-document-edit/:case_id",
        name: "case-document-edit",
        meta: {
            layout: "ProtectedVue", middleware: "auth", sidebar: "profile", user_permission: "Templates", breadcrumb: {
                label: "Case Document Edit",
                parent: "case-document-list"
            }
        },
        component: lazyLoad("protected/Templates/CaseDocument/CaseDocumentEdit")
    },

    {
        path: "/case-document-details/:case_id",
        name: "case-document-details",
        meta: {
            layout: "ProtectedVue", middleware: "auth", sidebar: "profile", user_permission: "Templates", breadcrumb: {
                label: "Case Document Details",
                parent: "case-document-list"
            }
        },
        component: lazyLoad("protected/Templates/CaseDocument/CaseDocumentDetails")
    },

    // {
    //   path: "/case-document/:tab_name/:case_id",
    //   name: "case-document-info",
    //   meta: {
    //     layout: "ProtectedVue", middleware: "login", sidebar: "profile", user_permission: "program-list", breadcrumb: {
    //       label: "Case Document info",
    //       parent: "case-document-list"
    //     }
    //   },
    //   component: lazyLoad("protected/Templates/CaseDocument/CaseDocumentDetails")
    // },


    {
        path: "/case-document-templates/:case_id",
        name: "case-document-templates",
        meta: {
            layout: "ProtectedVue", middleware: "auth", sidebar: "profile", user_permission: "Templates", breadcrumb: {
                label: "Case Document Templates",
                parent: "case-document-list"
            }
        },
        component: lazyLoad("protected/Templates/CaseDocument/CaseDocumentTemplates")
    },
    {
        path: "/case-document-deleted-templates/:case_id",
        name: "case-document-deleted-templates",
        meta: {
            layout: "ProtectedVue", middleware: "auth", sidebar: "profile", user_permission: "Templates", breadcrumb: {
                label: "Case Document Deleted Templates",
                parent: "case-document-list"
            }
        },
        component: lazyLoad("protected/Templates/CaseDocument/CaseDocumentDeletedTemplates")
    },

    {
        path: "/case-document-goal-convert-list/:import_file_code/:case_id",
        name: "case-document-goal-convert-list",
        meta: {
            layout: "ProtectedVue", middleware: "auth", sidebar: "profile", user_permission: "Templates", breadcrumb: {
                label: "Case Document Goal Convert List",
                parent: "case-document-list"
            }
        },
        component: lazyLoad("protected/Templates/CaseDocument/CaseDocumentGoalConvertList")
    },
];
