import _ from "lodash";
import Api from "@/service/api/_mix_api";

const state = {
    statuses: []
};
const mutations = {
    setStatusStatus(state, payload) {
        state.statuses = [...payload];
    }
};
const actions = {
    fetchStatus(context, payload) {
        const isForced = !!payload;
        if (isForced || _.isEmpty(context.getters.statuses)) {
            return Api.student_status.index().then((response) => {
                if (response.status === 200) {
                    context.commit('setStatusStatus', [...response.data.data]);
                }
            })
        }
    }
};
const getters = {
    statuses: state => state.statuses || []
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
