<script>
import PrimaryHeader from "@/components/layouts/authenticated/parts/Header/PrimaryHeader.vue";
import SecondaryHeader from "@/components/layouts/authenticated/parts/Header/SecondaryHeader.vue";

export default {
  name: "AppHeader",
  components: {PrimaryHeader, SecondaryHeader}
}
</script>

<template>
  <header class="o-header app-mix-header">
    <div class="content-header white  box-shadow-0">
      <PrimaryHeader />
      <SecondaryHeader />
    </div>
  </header>
</template>

<style scoped>

</style>
