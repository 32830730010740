import { lazyLoad } from "@/routers/utils/helper";

export const routes = [
    {
        path: "/service",
        name: "service.index",
        meta: { middleware: "auth",  user_permission: null},
        component: lazyLoad("pages/service/Index")
    },
    {
        path: "/service/create",
        name: "service.create",
        meta: {
            middleware: "auth",  user_permission: null
        },
        component: lazyLoad("pages/service/parts/index/Add-service")
    },
    {
        path: "/service/:service_id",
        name: "service.edit",
        redirect: { name: 'service.show' },
        meta: {
            middleware: "auth",  user_permission: null
        },
        component: lazyLoad("pages/service/show/Layout"),
        children: [
            {
                path: "",
                name: "service.show",
                meta: {
                    middleware: "auth",  user_permission: null
                },
                component: lazyLoad('pages/service/parts/index/Edit-service')
            },
        ]
    },
    // {
    //     path: "/delete-service",
    //     name: "delete-service",
    //     meta: { ,middleware: "login",user_permission: null},
    //     component: lazyLoad("pages/service/parts/index/Delete-service")
    // },
    // {
    //     path: "/service/import",
    //     name: "service.import",
    //     meta: { ,middleware: "login",user_permission:null},
    //     component: lazyLoad("pages/service/parts/index/Import-service")
    // },
];
