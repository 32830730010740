import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/checklist-master",
        name: "checklist-master",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"checklist-masters-list" },
        component: lazyLoad("protected/organization/ChecklistMaster/Checklist-master")
    },
    {
        path: "/add-checklist-master",
        name: "add-checklist-master",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"checklist-masters-add" },
        component: lazyLoad("protected/organization/ChecklistMaster/Add-checklist-master")
    },
    {
        path: "/edit-checklist-master/:checklist_master_id",
        name: "edit-checklist-master",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"checklist-masters-edit"},
        component: lazyLoad("protected/organization/ChecklistMaster/Edit-checklist-master")
    },
    {
        path: "/delete-checklist-master",
        name: "delete-checklist-master",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"checklist-masters-list"},
        component: lazyLoad("protected/organization/ChecklistMaster/Delete-checklist-master")
    },
];
