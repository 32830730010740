import Vue from "vue"
import _ from "lodash";

const state = {
    report_filters: {}
};
const mutations = {
    setReportFilters(state, payload) {
        state.report_filters = payload;
    }
};
const actions = {
    updateReportFilters(context, payload){
        context.commit('setReportFilters', {...payload});
    }
};
const getters = {
    getReportFilters: state => {
        return state.report_filters;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}