import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/",
        name: "dashboard",
        meta: {
            middleware: "auth"
        },
        component: lazyLoad('pages/user/Dashboard')
    }
];
