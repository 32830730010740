import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/invoice-list",
        name: "invoice-list",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Invoice List / Import / Export ", breadcrumb: 'Invoice Data Import/Export' },
        component: lazyLoad("protected/organization/Invoice/invoice-list")
    },
    {
        path: "/invoice-setss-request",
        name: "invoice-setss-request",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile", user_permission:"Request SETSS Invoice", breadcrumb: 'Invoice Data Import/Export' },
        component: lazyLoad("protected/organization/Invoice/SetssRequest")
    },
    {
        path: "/invoice-report",
        name: "invoice-report",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile", user_permission:"Invoice Report", breadcrumb: 'Invoice Report' },
        component: lazyLoad("protected/organization/Invoice/InvoiceReport")
    },
];
