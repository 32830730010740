import _ from "lodash";
import Api from "@/service/api/_mix_api";

const state = {
    referring_providers: [],
    referring_provider_details: {}
};
const mutations = {
    setReferringProvider(state, data) {
        state.referring_providers = data;
    },
    setReferringProviderDetails(state, data) {
        state.referring_provider_details = data;
    },
    setExistingStudentReferingProvider(state, payload) {
        state.referring_providers[payload.index] = payload.data;
    },
    setNewStudentReferingProvider(state, data) {
        state.referring_providers = data;
    }
};
const actions = {
    fetch(context, payload) {
        const isForced = !!payload.isForced;
        if (isForced || _.isEmpty(context.getters.referring_providers)) {
            return Api.student_referring_provider.index(payload.uuid)
            .then( response => { 
                context.commit('setReferringProvider', [... response.data.data]) 
            })
        }
    },
    referringProviderDetails(context, payload) {
        const isForced = !!payload.isForced;
        if (isForced || _.isEmpty(context.getters.referring_provider_details)) {
            return Api.student_referring_provider.show(payload.suuid, payload.puuid)
            .then( response => { 
                context.commit('setReferringProviderDetails', {... response.data.data}) 
            })
        }
    }
};
const getters = {
    referring_providers: state => state.referring_providers,
    referring_provider_details: state => state.referring_provider_details,
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}