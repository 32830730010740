import HTTP from "@/service/api/_axios_setup";

export default {
    index: () => HTTP.get('/agency'),
    store: (payload) => HTTP.post('/agency', payload),
    show: (id) => HTTP.get('/agency/'+id),
    update: (id,payload) => HTTP.put('/agency/'+id, payload),
    destroy: (id,data) => HTTP.delete('/agency/'+id, { data }),
    address: (uuid) => HTTP.get('/agency/'+uuid+'/agency-addresses'),
    addAddress: (uuid, payload) => HTTP.post('/agency/'+uuid+'/agency-addresses', payload),
    updateAddress: (uuid, id, payload) => HTTP.put('/agency/'+uuid+'/agency-addresses/'+id, payload),
    supervisor: (id) => HTTP.get('/agency-address/'+id+'/supervisor'),
    addSupervisor: (id, payload) => HTTP.post('/agency-address/'+id+'/supervisor', payload),
    // indexTaxonomies: () => HTTP.get('/taxonomies'),
    // indexAgencyTypes: () =>  HTTP.get('/agency-types')
}