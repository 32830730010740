import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/reinforcement/add/:parent_id?",
        name: "add-reinforcement",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"certification-add", breadcrumb: {
                label: "Reinforcement Category",
                parent: "reinforcement"
            } },
        component: lazyLoad("protected/organization/Reinforcement/Add-Reinforcement")
    },
    {
        path: "/reinforcement/edit/:cid",
        name: "edit-reinforcement",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"certification-edit", breadcrumb: {
                label: "Reinforcement Category",
                parent: "reinforcement"
            } },
        component: lazyLoad("protected/organization/Reinforcement/Edit-Reinforcement")
    },
    {
        path: "/reinforcement/:parent_id?",
        name: "reinforcement",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"certification-list", breadcrumb: 'Reinforcement Category'},
        component: lazyLoad("protected/organization/Reinforcement/List-Reinforcement")
    },
    {
        path: "/reinforcement/delete",
        name: "delete-reinforcement",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"certification-list", breadcrumb: {
                label: "Deleted Reinforcement",
                parent: "reinforcement"
            }},
        component: lazyLoad("protected/organization/Reinforcement/Delete-Reinforcement")
    },
];
