import _ from "lodash";
import Api from "@/service/api/_mix_api";
import {softDelete, search, sorting} from '@/service/localFilters';


const state = {
    placeOfServices: [],
    localFilters: {}
};
const mutations = {
    setPlaceOfServices(state, payload) {
        state.placeOfServices = payload;
    },
    setLocalFilters(state, payload) {
        state.localFilters = {...payload};
    }
};
const actions = {
    fetch(context, payload) {
        const isForced = !!payload;
        // if (isForced || _.isEmpty(context.getters.placeOfServices)) {
            return Api.place_of_service.index().then((response) => {
                if (response.status === 200) {
                    context.commit('setPlaceOfServices', [...response.data.data]);
                }
            })
        // }
    }
};
const getters = {
    placeOfServices: state => state.placeOfServices,
    placeOfServicesWithFilters: state => {
        const filter = [
            // Trashed or published
            (collection) => softDelete(collection, state.localFilters),
            // Search
            (collection) => search(collection, state.localFilters, ['name', 'code']),
            // Sorting
            (collection) => sorting(collection, state.localFilters)
        ];
        return filter.reduce((collection, value) => {
            return value(collection);
        }, (state.placeOfServices || []));
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}