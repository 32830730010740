import { extend, localize } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import en from "vee-validate/dist/locale/en.json";
import moment from "moment";
import _ from "lodash";

// Install rules
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
extend('date_lt', {
  params: ['target', 'date_format'],
  validate(value, {target, date_format}) {
    if (value && target) {
      date_format = date_format || 'YYYY-MM-DD';
      return moment(value, date_format).isSameOrBefore(moment(target, date_format));
    }
    return true;
  },
  message: 'The {_field_} field must be a date before or equal {target}'
})
extend('date_gt', {
  params: ['target', 'date_format'],
  validate(value, {target, date_format}) {
    if (value && target) {
      date_format = date_format || 'YYYY-MM-DD';
      return moment(value, date_format).isSameOrAfter(moment(target, date_format));
    }
    return true;
  },
  message: 'The {_field_} field must be a date after or equal {target}'
})
extend("staff_select", value => {
  if(value == 0){
    return 'The staff field is required';
  }
  return true;
})
extend("total_units", value => {
  if(value == 0){
    return 'Total units must be greater than zero';
  }
  return true;
})
extend("rate", value => {
  if(value == 0){
    return 'Rate must be greater than zero';
  }
  return true;
})
/*
  |------------------------------------------------------------------
  | Time related custom validation rules.
  |------------------------------------------------------------------
  | It's used on user's, student's and profile availability
  | setting.
 */
import {TimeUtils} from "@/helper/utils";
const timeUtils = new TimeUtils;
const timeUtilsHelper = {
  validRange(range) {
    return {
      start_time: _.isObject(range) && _.has(range, 'start_time') ? timeUtils.getValidTime(range.start_time) : null,
      end_time: _.isObject(range) && _.has(range, 'end_time') ? timeUtils.getValidTime(range.end_time) : null
    }
  }
};
extend('duplicate_time',{
  params: ['times'],
  validate(value, {times}) {
    const {start_time, end_time} = timeUtilsHelper.validRange(value);
    console.log(start_time, end_time);
    if (start_time && end_time) {
      const validTimes = _.isArray(times) && times.length > 0 ? [...times].filter(item => {
        const rangeV = timeUtilsHelper.validRange(item);
        return !!rangeV.start_time && !!rangeV.end_time;
      }) : [];
      const duplicateIndex = start_time && end_time && validTimes.length > 0 ? [...validTimes].findIndex(item => {
        return _.isEqual(item.start_time, start_time) && _.isEqual(item.end_time, end_time);
      }) : -1;
      return duplicateIndex === -1;
    }
    return true;
  },
  message: 'Duplicate.'
})
extend('not_overlapped_time', {
  params: ['times'],
  validate(value, {times}) {
    const {start_time, end_time} = timeUtilsHelper.validRange(value);
    if (start_time && end_time) {
      const momentStartTime = moment(start_time, 'HH:mm');
      const momentEndTime = moment(end_time, 'HH:mm');
      const validTimes = _.isArray(times) && times.length > 0 ? [...times].filter(item => {
        const rangeV = timeUtilsHelper.validRange(item);
        return !!rangeV.start_time && !!rangeV.end_time;
      }) : [];
      const overlapped = start_time && end_time && validTimes.length > 0 ? [...validTimes].filter(item => {
        const self_momentStartTime = moment(item.start_time, 'HH:mm');
        const self_momentEndTime = moment(item.end_time, 'HH:mm');
        return momentStartTime.isBetween(self_momentStartTime, self_momentEndTime) ||
            momentEndTime.isBetween(self_momentStartTime, self_momentEndTime);
      }) : [];
      return overlapped.length === 0;
    }
    return true;
  },
  message: 'Overlapped.'
});
extend('max_time', {
  params: ['max'],
  validate(value,{max}) {
    const regex = /^\d{2}:\d{2}$/;
    if (value && regex.test(value) && max && regex.test(max)) {
      const maxTimeInstance = moment(max, 'HH:mm');
      return moment(value, 'HH:mm').isSameOrBefore(maxTimeInstance);
    }
    return true;
  }
})
extend('min_time', {
  params: ['min'],
  validate(value,{min}) {
    const regex = /^\d{2}:\d{2}$/;
    if (value && regex.test(value) && min && regex.test(min)) {
      const minTimeInstance = moment(min, 'HH:mm');
      return moment(value, 'HH:mm').isSameOrAfter(minTimeInstance);
    }
    return true;
  }
})
extend('time_gt_then', {
  params: ['target'],
  validate(value,{target}) {
    const regex = /^\d{2}:\d{2}$/;
    if (value && regex.test(value) && target && regex.test(target)) {
      const minTimeInstance = moment(target, 'HH:mm');
      return moment(value, 'HH:mm').isAfter(minTimeInstance);
    }
    return true;
  }
})
extend('time_lt_then', {
  params: ['target'],
  validate(value,{target}) {
    const regex = /^\d{2}:\d{2}$/;
    if (value && regex.test(value) && target && regex.test(target)) {
      const maxTimeInstance = moment(target, 'HH:mm');
      return moment(value, 'HH:mm').isBefore(maxTimeInstance);
    }
    return true;
  }
})
// Install messages
localize({
  en
});
