import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/program-target-type/add",
        name: "add-program-target-type",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"program-list" },
        component: lazyLoad("protected/organization/ProgramTargetType/Add-Types")
    },
    {
        path: "/program-target-type/edit/:type_id",
        name: "edit-program-target-type",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"program-list" },
        component: lazyLoad("protected/organization/ProgramTargetType/Edit-Types")
    },
    {
        path: "/program-target-types",
        name: "program-target-type-list",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"program-list", breadcrumb: 'Program Target Type'},
        component: lazyLoad("protected/organization/ProgramTargetType/Types")
    },
    {
        path: "/delete-program-target-types",
        name: "delete-program-target-type",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"program-list", breadcrumb: {
                label: "Delete Program Target Types",
                parent: "program-target-type-list"
            }},
        component: lazyLoad("protected/organization/ProgramTargetType/Delete-Types")
    },
];
