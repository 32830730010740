import Vue from "vue";
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {
    faClipboardList,
    faAngleRight,
    faAngleLeft,
    faAngleUp,
    faAngleDown,
    faDashboard,
    faGraduationCap,
    faUser,
    faUserDoctor,
    faUserLock,
    faGear,
    faSackDollar,
    faCertificate,
    faMoneyCheckDollar,
    faLayerGroup,
    faCircleUser,
    faArrowRightFromBracket,
    faFilter,
    faArrowLeft,
    faMagnifyingGlass,
    faBarsStaggered,
    faCircleXmark,
    faPencil,
    faTrash,
    faCircleExclamation,
    faDollarSign,
    faBan,
    faAsterisk,
    faPlus,
    faCirclePlus,
    faCircleMinus
} from "@fortawesome/free-solid-svg-icons";
import {faBuilding, faMehBlank, faEye, faEyeSlash} from "@fortawesome/free-regular-svg-icons"

library.add(
    faClipboardList,
    faUser,
    faDashboard,
    faAngleRight,
    faAngleLeft,
    faAngleUp,
    faAngleDown,
    faGraduationCap,
    faBuilding,
    faUserDoctor,
    faUserLock,
    faGear,
    faSackDollar,
    faCertificate,
    faMoneyCheckDollar,
    faLayerGroup,
    faCircleUser,
    faArrowRightFromBracket,
    faMehBlank,
    faFilter,
    faArrowLeft,
    faMagnifyingGlass,
    faEye,
    faEyeSlash,
    faBarsStaggered,
    faCircleXmark,
    faPencil,
    faTrash,
    faCircleExclamation,
    faDollarSign,
    faBan,
    faAsterisk,
    faPlus,
    faCirclePlus,
    faCircleMinus
)

Vue.component('font-awesome-icon', FontAwesomeIcon);