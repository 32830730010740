import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/guardian",
        name: "guardian.index",
        meta: { middleware: "auth", user_permission:"guardian.view-all" },
        component: lazyLoad("pages/guardian/Index")
    },
    {
        path: "/guardian/:user_id",
        name: "edit-guardian",
        meta: { middleware: "auth", user_permission:"guardian.view-all"},
        component: lazyLoad("pages/guardian/show/GeneralInfo")
    },
];
