import _ from "lodash";
import Api from "@/service/api/_mix_api";

const state = {
    session_group: [],
    session_group_details: {}
};
const mutations = {
    setSessionGroup(state, data) {
        state.session_group = data;
    },
    setSessionGroupDetails(state, data) {
        state.session_group_details = data;
    },
    setExistingSessionGroup(state, payload) {
        state.session_group[payload.index] = payload.data;
    },
    setNewSessionGroup(state, data) {
        state.session_group.push(data);
    }
};
const actions = {
    fetch(context, payload) {
        const isForced = !!payload.isForced;
        if (isForced || _.isEmpty(context.getters.session_group)) {
            return Api.session_group.index(payload.uuid)
            .then( response => { 
                context.commit('setSessionGroup', [... response.data.data]) 
            })
        }
    },
    sessionGroupDetails(context, payload) {
        const isForced = !!payload.isForced;
        if (isForced || _.isEmpty(context.getters.session_group_details)) {
            return Api.session_group.show(payload.uuid, payload.id)
            .then( response => { 
                context.commit('setSessionGroupDetails', {... response.data.data}) 
            })
        }
    }
};
const getters = {
    session_group: state => state.session_group,
    session_group_details: state => state.session_group_details,
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}