<script>
export default {
  name: "HistoryNavigator",
  methods: {
    prev() { this.$router.back(); }
  }
}
</script>

<template>
  <div class="browser-history-action">
    <div class="history-nav-menu d-flex flex-row align-items-center">
      <button type="button" class="history-nav-btn" @click.prevent="prev">
        <font-awesome-icon :icon="['fas', 'arrow-left']" class="mr-2" />
        <span>Back</span>
      </button>
    </div>
  </div>
</template>

<style scoped>

</style>
