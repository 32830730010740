import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/prompt-code/add",
        name: "add-prompt-code",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"certification-add", breadcrumb: {
                label: "Add Prompt Code",
                parent: "prompt-code"
            } },
        component: lazyLoad("protected/organization/PromptCode/add-promptcode")
    },
    {
        path: "/prompt-code/edit/:cid",
        name: "edit-prompt-code",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"certification-edit", breadcrumb: {
                label: "Edit Prompt Code",
                parent: "prompt-code"
            } },
        component: lazyLoad("protected/organization/PromptCode/edit-promptcode")
    },
    {
        path: "/prompt-code/",
        name: "prompt-code",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"certification-list", breadcrumb: 'Prompt Code'},
        component: lazyLoad("protected/organization/PromptCode/promptcode-list")
    },
    {
        path: "/prompt-code/delete",
        name: "delete-prompt-code",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"certification-list"},
        component: lazyLoad("protected/organization/PromptCode/promptcode-delete")
    },
    {
        path: "/invoice",
        name: "invoice",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"certification-list"},
        component: lazyLoad("protected/organization/Invoice/invoice")
    },
];
