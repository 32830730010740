import _ from "lodash";
import Api from "@/service/api/_mix_api";

const state = {
    isLoading: false,
    types: []
};
const mutations = {
    setIsLoading(state, payload) {
        state.isLoading = !!payload;
    },
    setTypes(state, payload) {
        state.types = [...payload];
    }
}
const actions = {
    fetch(context, payload) {
        const forced = !!(payload?.forced || false);
        if (forced || _.isEmpty(context.getters.types) || _.size(context.getters.types) === 0) {
            context.commit('setIsLoading', true);
            return Api.experience_type.index().then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    context.commit('setTypes', [...response.data.data]);
                }
            }).finally(() => context.commit('setIsLoading', false))
        }
    }
}
const getters = {
    isLoading: (state) => state.isLoading || false,
    types: (state) => state.types || []
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}