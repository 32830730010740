import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/stage-status",
        name: "stage-status",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"stage-status-list" },
        component: lazyLoad("protected/organization/StageStatus/Stage-status")
    },
    {
        path: "/add-stage-status",
        name: "add-stage-status",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"stage-status-add" },
        component: lazyLoad("protected/organization/StageStatus/Add-stage-status")
    },
    {
        path: "/edit-stage-status/:stage_status_id",
        name: "edit-stage-status",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"stage-status-edit"},
        component: lazyLoad("protected/organization/StageStatus/Edit-stage-status")
    },
    {
        path: "/delete-stage-status",
        name: "delete-stage-status",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"stage-status-list"},
        component: lazyLoad("protected/organization/StageStatus/Delete-stage-status")
    },

    {
        path: "/student-billing-calendar/:student_id",
        name: "student-billing-calendar",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Student List", breadcrumb: {
                label: "Billing Calendar",
                parent: "student"
            }},
        component: lazyLoad("protected/students/student_billing_calendar/billing-calendar")
    },
];
