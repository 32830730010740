import _ from "lodash";
import Api from "@/service/api/_mix_api";

const state = {
    agency: {},
    agency_address: [],
    agency_supervisor: [],
};
const mutations = {
    setAgency(state, payload) {
        state.agency = {...payload};
    },
    setAgencyAddress(state, payload) {
        state.agency_address = [...payload];
    },
    setAgencySupervisor(state, payload) {
        state.agency_supervisor = [...payload];
    }
};
const actions = {
    fetch(context, payload) {
        const isForced = !!(payload?.forced || false);
        if (isForced || _.isEmpty(context.getters.agency)) {
            return Api.agency.show(payload.id).then((response) => {
                if (response.status === 200) {
                    context.commit('setAgency', {...response.data.data});
                }
            })
        }
    },
    fetchAddress(context, payload) {
        const isForced = !!(payload?.forced || false);
        if (isForced || _.isEmpty(context.getters.agency_address)) {
            return Api.agency.address(payload.uuid).then((response) => {
                if (response.status === 200) {
                    context.commit('setAgencyAddress', [...response.data.data]);
                }
            })
        }
    },
    fetchSupervisor(context, payload) {
        const isForced = !!(payload?.forced || false);
        if (isForced || _.isEmpty(context.getters.agency_supervisor)) {
            return Api.agency.supervisor(payload.id).then((response) => {
                if (response.status === 200) {
                    context.commit('setAgencySupervisor', [...response.data.data]);
                }
            })
        }
    },
};
const getters = {
    agency: (state) => state.agency || {},
    agency_address: (state) => state.agency_address || [],
    agencySupervisor: (state) => state.agency_supervisor || [],
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
