import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/coverage-types",
        name: "coverage-types",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"agency-list", breadcrumb: 'Coverage Types' },
        component: lazyLoad('protected/organization/CoverageTypes/CoverageTypes')
    },
    {
        path: "/add-coverage-types",
        name: "add-coverage-types",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"agency-add", breadcrumb: {
                label: "Add New Coverage Type",
                parent: "coverage-types"
            } },
        component: lazyLoad("protected/organization/CoverageTypes/Add-CoverageTypes")
    },
    {
        path: "/edit-coverage-types/:coverage_type_id",
        name: "edit-coverage-types",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"agency-edit", breadcrumb: {
                label: "Edit Coverage Type",
                parent: "coverage-types"
            }},
        component: lazyLoad("protected/organization/CoverageTypes/Edit-CoverageTypes")
    },
    {
        path: "/delete-coverage-types",
        name: "delete-coverage-types",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"agency-list", breadcrumb: {
                label: "Delete Coverage Type",
                parent: "coverage-types"
            }},
        component: lazyLoad("protected/organization/CoverageTypes/Delete-CoverageTypes")
    },
];
