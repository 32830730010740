import axios from "axios";
import store from "@/store";
const HTTP = axios.create({
    baseURL: `${process.env.VUE_APP_BASE_URL}/api/`,
});
export default function() {
    const payload = {refresh_token: store.getters.refreshToken};
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    }
    return HTTP.post('/refresh-token', payload, config)
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                store.commit('setToken', {...response.data.data});
            }
            return response;
        })
}