import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/package",
        name: "package",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"package-list" },
        component: lazyLoad("protected/package/package-list")
    },
    {
        path: "/add-package",
        name: "add-package",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"package-add" },
        component: lazyLoad("protected/package/package-add")
    },
    {
        path: "/edit-package/:package_id",
        name: "edit-package",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"package-edit"},
        component: lazyLoad("protected/package/package-edit")
    },
];
