import _ from "lodash";
import Api from "@/service/api/_mix_api";

const state = {
    staff_statuses: []
};
const mutations = {
    setStaffStatuses(state, data) {
        state.staff_statuses = data;
    }
};
const actions = {
    fetch(context, query) {
        const isForced = !!query;
        if (isForced || _.isEmpty(context.getters.staff_statuses)) {
            Api.user_group.userStatus(query)
            .then( response => { 
                context.commit('setStaffStatuses', [... response.data.data]) 
            })
        }
    }
};
const getters = {
    staff_statuses: state => state.staff_statuses,
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}