export const resource_icons = {
    dashboard: ['fas', 'fa-dashboard'],
    student: ['fa-solid', 'fa-graduation-cap'],
    user: ['fa-solid', 'fa-user'],
    agency: ['fa-regular', 'fa-building'],
    referring_provider: ['fa-solid', 'fa-user-doctor'],
    role_and_permission: ['fa-solid', 'fa-user-lock'],
    setting: ['fa-solid', 'fa-gear'],
    setting_funder: ['fas', 'sack-dollar'],
    setting_certification: ['fas', 'certificate'],
    setting_paycode: ['fas', 'money-check-dollar'],
    setting_type_or_category: ['fas', 'layer-group'],
    authorization: ['fas', 'clipboard-list'],
}