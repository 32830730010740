import _ from "lodash";
import Api from "@/service/api/_mix_api";
import {search} from "@/service/localFilters";
import Vue from "vue";
const defaultForm = {
    id: null,
    uuid: null,
    name: null,
    role_id: null,
    description: null
};
const state = {
    loading: false,
    localFilter: null,
    groups: [],
    form: {...defaultForm},
    formShow: false,
    formSubmitting: false
};
const mutations = {
    setGroups(state, payload) {
        state.groups = _.isNull(payload) || _.isEmpty(payload) ? [] : [...payload];
    },
    setLoading(state, payload) {
        state.loading = !!payload;
    },
    setLocalFilters(state, payload) {
        state.localFilters = {...payload};
    },
    setForm(state, payload) {
        const payloadRoleId = _.has(payload, 'role_id') ? _.get(payload, 'role_id', null) :  _.get(payload, 'role.id', null)
        state.form = _.isNull(payload) ? {...defaultForm} : {
            id: _.get(payload, 'id', null),
            uuid: _.get(payload, 'uuid', null),
            name: _.get(payload, 'name', null),
            role_id: payloadRoleId || null,
            description: _.get(payload, 'description', null)
        };
    },
    setFormShow(state, payload) {
        state.formShow = !!payload;
    },
    setFormSubmitting(state, payload) {
        state.formSubmitting = !!payload;
    },
    setAddTitle(state, payload) {
        state.isActiveAddTitle = payload;
    }

};
const actions = {
    fetch(context, payload) {
        const callback = _.has(payload, 'callback') && _.isFunction(payload?.callback) ? payload.callback : null;
        const forced = _.get(payload, 'forces', false);
        if (_.isEmpty(context.getters.groups) || forced) {
            context.commit('setLoading', true)
            return Api.user_group.index().then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    context.commit('setGroups', [...response.data.data])
                }
                callback && callback(response.data);
            }).finally(() => context.commit('setLoading', false));
        }
    },
    openForm(context, payload) {
        context.commit('setFormShow', true);
        context.commit('setForm', payload);
    },
    submitForm(context, payload){
        const form = context.getters.form;
        const promise = !!form.uuid ? Api.user_group.update(form.uuid, {...form}) : Api.user_group.store({...form});
        const callback = payload?.callback && _.isFunction(payload?.callback) ? payload?.callback : null;
        context.commit('setFormSubmitting', true);
        promise.then(response => {
            _.isFunction(callback) && callback(response);
            if (response.status >= 200 && response.status < 300) {
                const updatedGroups = _.cloneDeep([...context.getters.groups]).filter(item => !_.isEqual(item.uuid, response.data.data.uuid));
                updatedGroups.push({...response.data.data});
                context.commit('setGroups', updatedGroups);
                context.commit('setForm', null);
                context.commit('setFormShow', false);
            }
        }).finally(() => context.commit('setFormSubmitting', false));
    },
    deleteGroup(context, payload) {
        const uuid = payload?.uuid;
        const restoreUserGroup = !!uuid ? [...context.getters.groups].find(item => item.uuid === uuid) : null;
        if (restoreUserGroup) {
            context.commit('setGroups', [...context.getters.groups].filter(item => !_.isEqual(item.uuid, uuid)));
            Api.user_group.destroy(uuid)
                .then(response => {
                    if (response.status >= 200 && response.status < 300) {
                        Vue.$toast.open({message: response.data.msg, type: 'success'});
                    }
                })
                .catch((error) => {
                    context.commit('setGroups', _.concat([...context.getters.groups], {...restoreUserGroup}));
                    if (error?.response) {
                        Vue.$toast.open({message: error?.response.data.msg, type: 'error'});
                    }
                })
        }
    },
    updateGroups(context, payload) {
        context.commit('setGroups', [...payload])
    }
}

const getters = {
    groups: state => state.groups || [],
    loading: state => state.loading || false,
    isActiveAddTitle: state => state.isActiveAddTitle || false,
    checkTitleWithFilter: state => {
        const filter = [
            // Search
            (collection) => search(collection, state.localFilter, ['name']),
            // Sorting
            // (collection) => sorting(collection, state.localFilters)
        ];
        return filter.reduce((collection, value) => {
            return value(collection);
        }, (state.groups || []));
    },
    form: state => state.form,
    formShow: state => state.formShow,
    formSubmitting: state => state.formSubmitting

};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}