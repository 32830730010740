import _ from "lodash";
import {Positions} from "@/plugin/toast/index"
const toast_config = {
    types: [
        'success',
        'info',
        'warning',
        'error',
        'default'
    ],
    positions: [
        Positions.TOP,
        Positions.BOTTOM,
        Positions.TOP_RIGHT,
        Positions.BOTTOM_RIGHT,
        Positions.TOP_LEFT,
        Positions.BOTTOM_LEFT
    ]
};
const modal_default_config = {
    show: false,
    title: null,
    message: null,
    hasConfirmation: false,
    strict: false
};
const state = {
    toasts: [],
    activeToastID: null,
    modal: {...modal_default_config}
}
const mutations = {
    addToast(state, payload) {
            state.toasts.push(payload);
    },
    removeToast(state, payload) {
        const id = payload?.id || null;
        if (!!id) {
            state.toasts = [...state.toasts].filter(toast => !_.isEqual(toast.id, id)) || [];
        }
    },
    setActiveToastID(state, payload) {
        state.activeToastID = payload;
    },
    clearToasts(state) {
        state.toasts = [];
    },
    openModal(state, payload) {
        state.modal = {
            show: payload?.show || false,
            title: payload?.title || null,
            message: payload?.message || null,
            hasConfirmation: payload?.hasConfirmation || false,
            strict: payload?.strict || false
        }
    },
    closeModal(state) {
        state.modal = {...modal_default_config};
    }
};
const actions = {
    addToast(context, payload) {
        const type = !payload?.type || !_.includes(toast_config.types, payload?.type) ? 'default' : payload?.type;
        const id = payload?.id || _.uniqueId('toast_');
        const position = !payload?.position || !_.includes(toast_config.positions, payload?.position) ? 'top' : payload?.position;
        const existing = [...state.toasts].filter(toast => _.isEqual(toast.id, id));
        if(_.isEmpty(existing)) {
            context.commit('addToast', {
                id: id,
                type: type,
                message: payload?.message,
                position: position,
                created_at: (new Date()).getTime()
            });
            if (!context.getters.activeToastID) {
                context.commit('setActiveToastID', id);
            }
        }
    },
    removeToast(context, payload) {
        const id = payload?.id || null;
        const nextId = context.getters.nextToast?.id || null;
        if (id === context.getters.activeToastID) {
            context.commit('setActiveToastID', nextId);
            context.commit('removeToast', {...payload});
        }
    },
    clearToasts(context) {
        context.commit('clearToasts');
    },
    openModal(context, payload) {
        context.commit('openModal', {...payload});
    },
    closeModal(context) {
        context.commit('closeModal');
    }
};
const getters = {
    activeToastID: (state, getters) => {
        return getters.activeToast ? state.activeToastID : null;
    },
    activeToast: (state) => {
        const toast = state.activeToastID ? [...state.toasts].filter(toast => toast.id === state.activeToastID) : null;
        if (!_.isEmpty(toast)) {
            return toast[0];
        }
        return null;
    },
    nextToast: (state, getters) => {
        const upcomingToast = [...state.toasts].filter(toast => !_.isEqual(toast.id, getters.activeToastID));
        if (!!upcomingToast) {
            return _.head(_.sortBy(upcomingToast, 'created_at'));
        }
        return null;
    },
    toasts: (state) => state.toasts || [],
    modal: state => state.modal
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}