import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/group-template-lession/add/:parent_id?",
        name: "add-group-template-lession",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"certification-add", breadcrumb: {
                label: "Group Template Lession Category",
                parent: "group-template-lession"
            } },
        component: lazyLoad("protected/organization/GroupTemplateLession/Add-Lession")
    },
    {
        path: "/group-template-lession/edit/:cid",
        name: "edit-group-template-lession",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"certification-edit", breadcrumb: {
                label: "Edit Group Template Lesson Category",
                parent: "group-template-lession"
            } },
        component: lazyLoad("protected/organization/GroupTemplateLession/Edit-Lession")
    },
    {
        path: "/group-template-lession/:parent_id?",
        name: "group-template-lession",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"certification-list", breadcrumb: 'Group Template Lesson Category'},
        component: lazyLoad("protected/organization/GroupTemplateLession/List-Lession")
    },
    {
        path: "/group-template-lession/delete",
        name: "delete-group-template-lession",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"certification-list", breadcrumb: {
                label: "Deleted Group Template Lesson",
                parent: "group-template-lession"
            }},
        component: lazyLoad("protected/organization/GroupTemplateLession/Delete-Lession")
    },
];
