<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import {PerfectScrollbar} from "vue2-perfect-scrollbar";
import {resource_icons} from "@/common_icons";
export default {
  name: "MiniCreateMenu",
  components: {
    PerfectScrollbar
  },
  data() {
    return {
      showMenu: false,
      scrollConfig: {
        wheelPropagation: false,
        suppressScrollX: true,
        wheelSpeed: 0.8
      },
      menu: [
        {
          label: 'Student',
          icon: resource_icons.student,
          desc: 'Create student/client',
          to: { name: 'student.create' },
          permissionName: 'student.add-or-edit'
        },
        {
          label: 'Staff',
          icon: resource_icons.user,
          desc: 'Create staff',
          to: '/staff/create',
          permissionName: 'staff.add-or-edit'
        },
        {
          label: 'Referring Provider',
          icon: resource_icons.referring_provider,
          desc: 'Create referring provider',
          to: { name: 'referring-provider.create' },
          permissionName: 'referring-provider.add-or-edit'
        },
        // {
        //   label: 'Add Funder',
        //   icon: 'fa fa-building',
        //   desc: 'Create a new funder.',
        //   to: { name: 'funder.create' },
        //   permissionName: null
        // },
        {
          label: 'Kareo Funder',
          icon: resource_icons.setting_funder,
          desc: 'Create kareo funder.',
          to: { name: 'kareo-funder.create' },
          permissionName: null
        },
        {
          label: 'Agency',
          icon: resource_icons.agency,
          desc: 'Create agency.',
          to: { name: 'agency.create' },
          permissionName: null
        },
        {
          label: 'Certification',
          icon: resource_icons.setting_certification,
          desc: 'Create certification.',
          to: { name: 'certification.create' },
          permissionName: null
        },
        {
          label: 'Certification Type',
          icon: resource_icons.setting_type_or_category,
          desc: 'Create certification type.',
          to: { name: 'certification-type.create' },
          permissionName: null
        },
        {
          label: 'Services',
          icon: resource_icons.setting_type_or_category,
          desc: 'Create service.',
          to: { name: 'service.create' },
          permissionName: null
        },
        {
          label: 'Service Line',
          icon: resource_icons.setting_type_or_category,
          desc: 'Create service line.',
          to: { name: 'service-line.create' },
          permissionName: null
        },
        {
          label: 'Pay Code',
          icon: resource_icons.setting_paycode,
          desc: 'Create pay code.',
          to: { name: 'pay-code.create' },
          permissionName: null
        },
      ]
    };
  },
  computed: {
    menuItems() {
      return [...this.menu].filter(item => {
        if (_.isNull(item.permissionName)) {
          return this.$store.getters.typeAdmin;
        }
        if (_.isArray(item.permissionName)) {
          return this.$store.getters.canAny(item.permissionName)
        }
        return this.$store.getters.can(item.permissionName)
      });
    }
  },
  watch: {
    $route() {
      this.closeOnRouteChange();
    }
  },
  methods: {
    closeOnClickOutside(event) {
      const element = this.$refs.menu;
      if (this.showMenu && element && !element.contains(event.target)) {
        this.showMenu = false;
      }
    },
    closeOnRouteChange() {
      if (this.showMenu) {
        this.showMenu = false;
      }
    },
    canDo(handle) {
      if (!handle || _.isNull(handle)) {
        return this.$store.getters.typeAdmin;
      }
      return this.can(handle);
    }
  },
  mounted() {
    document.addEventListener('mouseup', this.closeOnClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('mouseup', this.closeOnClickOutside);
  }
};
</script>

<template>
  <div ref="menu" class="mini-dropdown-create" v-if="menuItems && menuItems.length">
    <button type="button" class="mini-dropdown-trigger" @click.prevent="showMenu = !showMenu">
      <span class="mini-dropdown-trigger-text">Create</span>
      <span class="mini-dropdown-trigger-icon">
        <font-awesome-icon :icon="['fas', 'angle-down']" />
      </span>
    </button>
    <div class="mini-dropdown-menu" v-if="showMenu">
      <PerfectScrollbar class="mini-dropdown-menu--wrap" :options="scrollConfig">
        <router-link
            v-for="(item, index) in menuItems"
            :key="index"
            class="mini-dropdown-menu--item"
            :to="item.to">
          <div class="mini-dropdown-menu-item-wrap">
            <div class="mini-dropdown-menu--item-icon">
              <font-awesome-icon :icon="item.icon" />
            </div>
            <div class="mini-dropdown-menu--item--details">
              <div class="mini-dropdown-menu--item-label">{{ item.label }}</div>
              <div class="mini-dropdown-menu--item-desc">{{ item.desc }}</div>
            </div>
          </div>
        </router-link>
      </PerfectScrollbar>
    </div>
  </div>
</template>

<style scoped>
/* Add your styles here */
</style>
