import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/inbox",
        name: "inbox",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Message", breadcrumb: 'Inbox'},
        component: lazyLoad("protected/Message/Inbox")
    },
    {
        path: "/send",
        name: "send",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Message", breadcrumb: 'Send'},
        component: lazyLoad("protected/Message/Send")
    },
    {
        path: "/compose",
        name: "compose",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Message", breadcrumb: 'Compose'},
        component: lazyLoad("protected/Message/Compose")
    },
    {
        path: "/trash",
        name: "trash",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Message", breadcrumb: 'Trash'},
        component: lazyLoad("protected/Message/Trash")
    },
    {
        path: "/message/:id",
        name: "message-details",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Message", breadcrumb: 'Message'},
        component: lazyLoad("protected/Message/MessageDetails")
    },
];
