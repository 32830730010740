import Api from "@/service/api/_mix_api";
import _ from "lodash";

const state = {
    isFetching: false,
    isDeleting: false,
    query: {
        page: 1,
        only_trashed: false
    },
    staffs: [],
    pagination: {},
    queueSelection: []
};
const mutations = {
    setIsFetching(state, payload) {
        state.isFetching = !!payload;
    },
    setIsDeleting(state, payload) {
        state.isDeleting = !!payload;
    },
    setStaffs(state, payload) {
        state.staffs = [...payload];
    },
    setStaff(state,{uuid, payload}) {
        const restoredStaff = [...state.staffs];
        const findIndex = restoredStaff.findIndex(item => item.uuid === uuid);
        if (findIndex > -1) {
            Object.keys(restoredStaff[findIndex]).forEach((key) => {
                restoredStaff[findIndex][key] = _.get(payload, key);
            });

        }
    },
    setPagination(state, payload) {
        state.pagination = {...payload};
    },
    setQuery(state, payload) {
        state.query = { ...payload};
    },
    setQueueSelection(state, payload) {
        state.queueSelection = [...payload];
    }
};
const actions = {
    fetch(context, payload) {
        const restoredQuery = _.cloneDeep(context.getters.query);
        const newQuery = _.cloneDeep(payload || {});
        context.commit('setQueueSelection', []);
        if (!_.isEqual(restoredQuery, newQuery) || _.isEmpty(context.getters.students)) {
            context.commit('setQuery', newQuery);
                return Api.staff.index(newQuery)
                    .then((response) => {
                        if(response.status === 200) {
                            context.commit('setStaffs', [...response.data.data]);
                            context.commit('setPagination', {...response.data.meta});
                        }
                    }).finally(() => context.commit('setIsFetching', false));
        }else {
            return null;
        }
    },
    // destroyQueueSelection(context, payload) {
    //     const isRestored = !!payload;
    //     context.commit('setIsDeleting', true);
    //     return Api.staff.destroy()
    // }
}
const getters = {
    staffs: (state) => state.staffs || [],
    pagination: (state) => state.pagination || null,
    query: (state) => ({
        page: 1,
        only_trashed: false,
        ...state.query
    }),
    queueSelection: (state) => state.queueSelection || [],
    showTrashedAction: (state) => !!_.get(state.query, 'only_trashed', false)
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
