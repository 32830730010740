import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/checklist-form-template",
        name: "checklist-form-template",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"checklist-form-template-list" },
        component: lazyLoad("protected/organization/ChecklistFormTemplates/Checklist-form-templates")
    },
    {
        path: "/add-checklist-form-template",
        name: "add-checklist-form-template",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"checklist-form-template-add" },
        component: lazyLoad("protected/organization/ChecklistFormTemplates/Add-checklist-form-templates")
    },
    {
        path: "/edit-checklist-form-template/:checklist_form_template_id",
        name: "edit-checklist-form-template",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"checklist-form-template-edit"},
        component: lazyLoad("protected/organization/ChecklistFormTemplates/Edit-checklist-form-templates")
    },
    {
        path: "/delete-checklist-form-template",
        name: "delete-checklist-form-template",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"checklist-form-template-list"},
        component: lazyLoad("protected/organization/ChecklistFormTemplates/Delete-checklist-form-templates")
    },
];
