import _ from "lodash";
import Api from "@/service/api/_mix_api";
import httpErrorHandler from "@/httpErrorHandler";

const state = {
    isFetching: false,
    releaseInfoConfirmationType: [],
};
const mutations = {
    setIsFetching(state, payload) {
        state.isFetching = !!payload;
    },
    setReleaseInfoConfirmationType(state, data) {
        state.releaseInfoConfirmationType = data;
    },
};
const actions = {
    getInfoConfirmationType(context, query) {
        const isForced = !!query;
        if (isForced || _.isEmpty(context.getters.releaseInfoConfirmationType)) {
            context.commit('setIsFetching', true);
            Api.release_info_confirmation_type.index(query)
            .then( response => {
                if (response.status >= 200 && response.status < 300) {
                    context.commit('setReleaseInfoConfirmationType', [... response.data.data])
                }
            }).finally(() => context.commit('setIsFetching', false))
        }
    },
};
const getters = {
    isFetching: state => state.isFetching || false,
    releaseInfoConfirmationType: state => state.releaseInfoConfirmationType || [],
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}