import _ from "lodash";
import * as JsSearch from "js-search";

export const softDelete = (collection, query) => {
    return collection.filter((item) => {
        if (_.get(query, 'only_trashed') === true) {
            return !!item.deleted_at;
        }
        return !item.deleted_at;
    })
};

function objectForSearch(obj, parentKey = '', res = {}) {
    for (let key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            objectForSearch(obj[key], parentKey ? `${parentKey}.${key}` : key, res);
        } else {
            res[parentKey ? `${parentKey}.${key}` : key] = obj[key];
        }
    }
    return res;
}



export const search = (collection, query, searchableIndex) => {

    const searchText = _.get(query,'s');
    if (searchText) {
        const search = (new JsSearch.Search('uuid'));
        const searchIndexParams = [...searchableIndex];
        for(let index = 0; index < searchIndexParams.length; index++) {
            search.addIndex(searchIndexParams[index]);
        }
        search.addDocuments(collection);
        return search.search(searchText);
    }
    return collection;


    // if (searchText) {
    //     const search = (new JsSearch.Search('uuid'));
    //     const searchIndexParams = [...searchableIndex];

    //     const flattenedCollection = collection.map(item => objectForSearch(item));

    //     for(let index = 0; index < searchIndexParams.length; index++) {
    //         search.addIndex(searchIndexParams[index]);
    //     }
    //     search.addDocuments(flattenedCollection);
    //     let result = search.search(searchText);
    //     console.log(result);
    //     return result;
    // }
    // return collection;
}
export const sorting = (collection, query) => {
    const sortKey = _.get(query, 'sort_by');
    const sortOrder = _.get(query, 'sort');
    if (sortKey && sortOrder && _.isString(sortOrder) && ['asc', 'desc'].includes(sortOrder.toString().toLowerCase())) {
        const sorted = collection.sort((item1, item2) => {
            const value1 = item1[sortKey].toString() || '';
            const value2 = item2[sortKey].toString() || '';
            const order = sortOrder.toString().toLowerCase();
            if (order === 'desc') {
                return value2.localeCompare(value1);
            }
            return  value1.localeCompare(value2);
        });
        // console.log(sorted);
        return sorted;
    }
    return collection;
};
