import authentication from "@/service/api/authentication";
import agency from "@/service/api/agency";
import agency_type from "@/service/api/agency_type";
import taxonomy from "@/service/api/taxonomy";
import state from "@/service/api/state";
import timezones from "@/service/api/timezones";
import certification from "@/service/api/certification";
import service from "@/service/api/service";
import service_line from "@/service/api/service_line";
import profile from "@/service/api/profile";
import place_of_service from "@/service/api/place_of_service";
import student from "@/service/api/student";
import role from "@/service/api/role";
import user_group from "@/service/api/user_group";
import student_status from "@/service/api/student_status";
import gender from "@/service/api/gender";
import student_authorization from "@/service/api/student_authorization";
import student_payers from "@/service/api/student_payers";
import funder from "@/service/api/funder";
import coverage_type from "@/service/api/coverage_type";
import payer_type from "@/service/api/payer_type";
import staff from "@/service/api/staff";
import staff_profile from "@/service/api/staff_profile";
import staff_availability from "@/service/api/staff_availability";
import student_availability from "@/service/api/student_availability";
import pay_code from "@/service/api/pay_code";
import kareo_funder from "@/service/api/kareo_funder";
import certification_type from "@/service/api/certification_type";
import degree_type from "@/service/api/degree_type";
import student_assign_staff from "@/service/api/student_assign_staff";
import student_staff_assign_type from "@/service/api/student_staff_assign_type";
import staff_assign_student from "@/service/api/staff_assign_student";
import agency_payer from "@/service/api/agency_payer";
import staff_address from "@/service/api/staff_address";
import modules from "@/service/api/modules";
import age_group from "@/service/api/age_group";
import experience_type from "@/service/api/experience_type";
import payroll_type from "@/service/api/payroll_type";
import languages from "@/service/api/languages";
import week_days from "@/service/api/week_days";
import npi_checking from "@/service/api/npi_checking";
import staff_paycode from "@/service/api/staff_paycode";
import staff_payroll from "@/service/api/staff_payroll";
import referring_provider from "@/service/api/referring_provider";
import student_referring_provider from "@/service/api/student_referring_provider";
import student_agency from "@/service/api/student_agency";
import funder_plan from "@/service/api/funder_plan";
import staff_signature from "@/service/api/staff_signature";
import marital_status from "@/service/api/marital_status";
import relationship_to_insured from "@/service/api/relationship_to_insured";
import release_info_confirmation_type from "@/service/api/release_info_confirmation_type";
import co_payement_type from "@/service/api/co_payement_type";
import insurance_type from "@/service/api/insurance_type";
import delegated_permission from "@/service/api/delegated_permission";
import billing from "@/service/api/billing";
import authorization_submission_type from "@/service/api/authorization_submission_type";
import authorization_distribution_type from "@/service/api/authorization_distribution_type";
import authorization_status from "@/service/api/authorization_status";
import diagnosis_code from "@/service/api/diagnosis_code";
import interval from "@/service/api/interval";
import rendering_provider_type from "@/service/api/rendering_provider_type";
import student_services from "@/service/api/student_services";
import authorization from "@/service/api/authorization";
import session_group from "@/service/api/session_group";

export default {
    authentication,
    agency,
    agency_type,
    taxonomy,
    state,
    timezones,
    certification,
    profile,
    place_of_service,
    student,
    student_status,
    role,
    user_group,
    service,
    service_line,
    gender,
    student_authorization,
    student_payers,
    funder,
    coverage_type,
    payer_type,
    staff,
    staff_profile,
    staff_availability,
    student_availability,
    pay_code,
    kareo_funder,
    certification_type,
    degree_type,
    student_assign_staff,
    student_staff_assign_type,
    staff_assign_student,
    staff_signature,
    agency_payer,
    staff_address,
    modules,
    age_group,
    experience_type,
    payroll_type,
    languages,
    npi_checking,
    week_days,
    staff_payroll,
    staff_paycode,
    referring_provider,
    student_referring_provider,
    student_agency,
    funder_plan,
    marital_status,
    relationship_to_insured,
    release_info_confirmation_type,
    co_payement_type,
    insurance_type,
    billing,
    authorization_submission_type,
    authorization_distribution_type,
    authorization_status,
    diagnosis_code,
    interval,
    delegated_permission,
    rendering_provider_type,
    student_services,
    authorization,
    session_group
}
