import Vue from "vue";
import _ from "lodash";
import store from "@/store";
const errorsDefinition = {
    401: 'Your session is expired, please login again.',
    402: 'Payment is required to access the requested resource.',
    403: 'You don\'t have permission to access.',
    404: 'The requested resource may deleted or not found.',
    405: 'The request method was invalid. Please try again.',
    406: 'The request is not acceptable. Please check and try again.',
    407: 'Proxy authentication is required to access the resource.',
    408: 'The request timeout happened. The server response is delayed or may be unavailable.',
    409: 'Conflicts are happened. Please try again after sometime',
    410: 'The requested resource is not available or deleted.',
    411: 'Length is required to process the request.',
    412: 'Pre-condition failed to process the request.',
    413: 'The request content is too large to process. Please check and try again.',
    414: 'The URL is too long to process. Please check and try again.',
    415: 'Unsupported media try requested. Please check and try again.',
    416: 'The request range is not satisfiable.',
    417: 'The expected value of the request is failed. Please check and try again.',
    421: 'Misdirected request received.',
    422: 'There have some validation issues on form submission. Please check and try again.',
    423: 'The resource is locked or not accessible.',
    424: 'Dependencies are failed.',
    425: 'The request is received too early.',
    426: 'The request is required to upgrade.',
    428: 'Pre-conditions are required for the request.',
    429: 'Too many requests. Please wait and try again.',
    431: 'The request header is too large.',
    451: 'The resource is unavailable for legal reason.',
    500: 'Internal server error. Please check the server and try again.',
    501: 'The server is not able to handle the request.',
    502: 'Bad gateway.',
    503: 'Service is not available currently. The service is under maintenance, please wait and try again.',
    504: 'Gateway timeout.'
};
const skipErrorMessage = (error) => {
    const statusCode = _.get(error, 'response.status');
    const errorConfig = error.config;
    if (statusCode === 401 && _.has(errorConfig, '_forced_logout')) {
        return true;
    }
    return false;
}
export const proxyValidStatus = (code) => {
    return errorsDefinition.hasOwnProperty(code) || errorsDefinition[code];
}
export default function(error) {
    const statusCode = _.get(error, 'response.status');
    const defaultMessage =  _.get(error, 'response.message');
    if (statusCode) {
        if (statusCode >= 400 && statusCode < 600) {
            !skipErrorMessage(error) && Vue.$toast.open({message: errorsDefinition[statusCode] || defaultMessage, type: 'error'});
            statusCode === 401 && store.dispatch('logout');
            return Promise.resolve(error.response);
        }
    }
    return Promise.reject(error);
}
