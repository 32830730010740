import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/workflows",
        name: "work-flows",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"work-flows-only-view", breadcrumb: 'Workflows' },
        component: lazyLoad("protected/Workflows/WorkFlowsDataList")
    },
    {
        path: "/add-workflows/:id",
        name: "add-work-flows",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"work-flows-add-edit", breadcrumb: {
                label: "Edit Workflow",
                parent: "work-flows"
            }},
        component: lazyLoad("protected/Workflows/AddWorkFlowData")
    },
];
