import _ from "lodash";
import Api from "@/service/api/_mix_api";

const state = {
    funder: [],
    funderDetails: {},
    serviceLines: [],
    services: [],
    billingSubmissionMethod: [],
    claimFormTemplate: [],
    billingUnitType: [],
    billingRoundingType: [],
};
const mutations = {
    setFunder(state, payload) {
        state.funder = [...payload];
    },
    setFunderDetails(state, payload) {
        state.funderDetails = {...payload};
    },
    setServiceLines(state, payload) {
        state.serviceLines = [...payload];
    },
    setService(state, payload) {
        state.services = [...payload];
    },
    setBillingSubmissionMethod(state, payload) {
        state.billingSubmissionMethod = [...payload];
    },
    setClaimFormTemplate(state, payload) {
        state.claimFormTemplate = [...payload];
    },
    setBillingUnitType(state, payload) {
        state.billingUnitType = [...payload];
    },
    setBillingRoundingType(state, payload) {
        state.billingRoundingType = [...payload];
    },
};
const actions = {
    fetch(context, payload) {
        return Api.agency_payer.index(payload).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                context.commit('setFunder', [...response.data.data]);
            }
        })
    },
    show(context, payload) {
        const isForced = !!(payload?.forced || false);    
        if (isForced || _.isEmpty(context.getters.funderDetails)) {
            return Api.agency_payer.show(payload.uuid, payload.id).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    context.commit('setFunderDetails', {...response.data.data});
                }
            })
        }
    },
    fetchServiceLine(context, payload) {
        return Api.agency_payer.getPayerServiceLine(payload.uuid, payload.id).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                context.commit('setServiceLines', [...response.data.data]);
            }
        })
    },
    fetchService(context, payload) {
        return Api.agency_payer.getService(payload.uuid, payload.id).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                context.commit('setService', [...response.data.data]);
            }
        })
    },
    fetchBillingSubmissionMethod(context, payload) {
        const isForced = !!(payload?.forced || false);    
        if (isForced || _.isEmpty(context.getters.billingSubmissionMethod)) {
            return Api.agency_payer.getBillingSubmissionMethod().then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    context.commit('setBillingSubmissionMethod', [...response.data.data]);
                }
            })
        }
    },
    fetchClaimFormTemplate(context, payload) {
        const isForced = !!(payload?.forced || false);    
        if (isForced || _.isEmpty(context.getters.claimFormTemplate)) {
            return Api.agency_payer.getClaimFormTemplate().then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    context.commit('setClaimFormTemplate', [...response.data.data]);
                }
            })
        }
    },
    fetchBillingUnitType(context, payload) {
        const isForced = !!(payload?.forced || false);    
        if (isForced || _.isEmpty(context.getters.billingUnitType)) {
            return Api.agency_payer.getBillingUnitType().then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    context.commit('setBillingUnitType', [...response.data.data]);
                }
            })
        }
    },
    fetchBillingRoundingType(context, payload) {
        const isForced = !!(payload?.forced || false);    
        if (isForced || _.isEmpty(context.getters.billingRoundingType)) {
            return Api.agency_payer.getBillingRoundingType().then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    context.commit('setBillingRoundingType', [...response.data.data]);
                }
            })
        }
    },
};
const getters = {
    funder: (state) => state.funder || [],
    funderDetails: (state) => state.funderDetails || {},
    funderServiceLines: () => state.serviceLines || [],
    serviceLinesServices: () => state.services || [],
    billingSubmissionMethod: () => state.billingSubmissionMethod || [],
    claimFormTemplate: () => state.claimFormTemplate || [],
    billingUnitType: () => state.billingUnitType || [],
    billingRoundingType: () => state.billingRoundingType || [],
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
