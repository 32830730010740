import _ from "lodash";
import Api from "@/service/api/_mix_api";

const state = {
    student_payers: [],
    student_payer_details: {},
    student_payer_serviceline: []
};
const mutations = {
    setStudentPayer(state, payload) {
        state.student_payers = [...payload];
    },
    setExistingStudentFunder(state, payload) {
        state.student_payers[payload.index] = payload.data;
    },
    setNewStudentFunder(state, data) {
        state.student_payers.push(data);
    },
    setStudentPayerDetails(state, data) {
        state.student_payer_details = data;
    },
    setStudentPayerServiceline(state, data) {
        state.student_payer_serviceline = [...data];
    },
};
const actions = {
    studentPayers(context, uuid) {
        const isForced = !!uuid;
        if (isForced || _.isEmpty(context.getters.student_payers)) {
            return Api.student_payers.index(uuid).then((response) => {
                if (response.status === 200) {
                    context.commit('setStudentPayer', [...response.data.data]);
                }
            })
        }
    },
    payerDetails(context, payload) {
        context.commit('setStudentPayerDetails', {...payload});
        // const isForced = !!uuid;
        // if (isForced || _.isEmpty(context.getters.student_payers)) {
        //     return Api.student_payers.index(uuid).then((response) => {
        //         if (response.status === 200) {
        //             context.commit('setStudentPayer', [...response.data.data]);
        //         }
        //     })
        // }
    },
    studentPayerServiceline(context, id) {
        return Api.student_payers.serviceline(id).then((response) => {
            if (response.status === 200) {
                if(response.data.data.length > 0) {
                    context.commit('setStudentPayerServiceline', [...response.data.data]);
                }
            }
        })
        
    }
};
const getters = {
    student_payers: state => state.student_payers || [],
    student_payer_details: state => state.student_payer_details || {},
    student_payer_serviceline: state => state.student_payer_serviceline || [],
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}