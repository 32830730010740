import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/groups",
        name: "groups",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"organization-list", breadcrumb: 'Groups' },
        component: lazyLoad("protected/organization/Group/GroupList")
    },
    {
        path: "/groups/add",
        name: "group-add",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"organization-list", breadcrumb: {
                label: "Add Group",
                parent: "groups"
            } },
        component: lazyLoad("protected/organization/Group/GroupAdd")
    },
    {
        path: "/groups/edit/:group_id",
        name: "group-edit",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"organization-list", breadcrumb: {
                label: "Edit Group",
                parent: "groups"
            } },
        component: lazyLoad("protected/organization/Group/GroupEdit")
    },
];
