import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/skillareas",
        name: "skillareas",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"hcodes-list", breadcrumb: 'Skill Area' },
        component: lazyLoad("protected/organization/SkillArea/skill-area-list")
    },
    {
        path: "/add-skillarea",
        name: "add-skillarea",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"hcodes-add", breadcrumb: {
                label: "Add New Skill Area",
                parent: "skillareas"
            } },
        component: lazyLoad("protected/organization/SkillArea/skill-area-add")
    },
    {
        path: "/edit-skillarea/:skill_id",
        name: "edit-skillarea",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"hcodes-edit", breadcrumb: {
                label: "Edit Skill Area",
                parent: "skillareas"
            } },
        component: lazyLoad("protected/organization/SkillArea/skill-area-edit")
    },
    {
        path: "/delete-skillarea",
        name: "delete-skillarea",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"hcodes-list", breadcrumb: {
                label: "Deleted Skill Area",
                parent: "skillareas"
            } },
        component: lazyLoad("protected/organization/SkillArea/skill-area-delete")
    },
    {
        path: "/import-skillarea",
        name: "import-skillarea",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"hcodes-list", breadcrumb: {
                label: "Import Skill Area",
                parent: "skillareas"
            }},
        component: lazyLoad("protected/organization/SkillArea/skill-area-import")
    },
];
