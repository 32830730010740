import _ from "lodash";
import Api from "@/service/api/_mix_api";
import httpErrorHandler from "@/httpErrorHandler";

const state = {
    isFetching: false,
    insuranceType: [],
};
const mutations = {
    setIsFetching(state, payload) {
        state.isFetching = !!payload;
    },
    setInsuranceType(state, data) {
        state.insuranceType = data;
    },
};
const actions = {
    getInsuranceType(context, query) {
        const isForced = !!query;
        if (isForced || _.isEmpty(context.getters.insuranceType)) {
            context.commit('setIsFetching', true);
            Api.insurance_type.index(query)
            .then( response => {
                if (response.status >= 200 && response.status < 300) {
                    context.commit('setInsuranceType', [... response.data.data])
                }
            }).finally(() => context.commit('setIsFetching', false))
        }
    },
};
const getters = {
    isFetching: state => state.isFetching || false,
    insuranceType: state => state.insuranceType || [],
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}