import Api from './utils/api';
import Positions from './utils/positions';

const Plugin = (Vue, options = {}) => {
    let methods = Api(Vue, options);
    Vue.$toast = methods;
    Vue.prototype.$toast = methods;
};
export default {
    install: Plugin
}
export {Positions}