import Vue from "vue";
import store from './store'
import router from './routers/router'
import './bootstrap'
import LaravelEcho from './laravel_echo'
import App from './App.vue'

import "./vee-validate";
import "./filter";
// import "./froala-editor";
import "@/assets/scss/app.scss"
import "@/directives"
const app = new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    LaravelEcho.mounted();
  }
}).$mount('#app')
