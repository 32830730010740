import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/pdf-template-editor",
        name: "pdf-template-editor",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"organization-list", breadcrumb: 'Pdf Document Editor' },
        component: lazyLoad("protected/PdfDocumentEditor")
    },
];
