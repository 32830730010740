import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/billingcodes",
        name: "billingcodes",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"hcodes-list" },
        component: lazyLoad("protected/organization/BillingCode/Billingcode")
    },
    {
        path: "/add-billingcode",
        name: "add-billingcode",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"hcodes-add" },
        component: lazyLoad("protected/organization/BillingCode/Billingcode-add")
    },
    {
        path: "/edit-billingcode/:billingcode_id",
        name: "edit-billingcode",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"hcodes-edit" },
        component: lazyLoad("protected/organization/BillingCode/Billingcode-edit")
    },
    {
        path: "/delete-billingcode",
        name: "delete-billingcode",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"hcodes-list" },
        component: lazyLoad("protected/organization/BillingCode/Delete-Billingcode")
    },
    {
        path: "/import-billingcode",
        name: "import-billingcode",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"hcodes-list"},
        component: lazyLoad("protected/organization/BillingCode/Import-Billingcode")
    },
];
