<script>
export default {
  name: "AppFooter"
}
</script>

<template>
  <div class="content-footer white app-footer">
    <div class="d-flex p-3">
      <span class="text-sm text-muted flex">&copy; Copyright. Flatfull</span>
      <div class="text-sm text-muted">Version 1.2.0</div>
    </div>
  </div>
</template>

<style scoped>

</style>
