import {lazyLoad} from "@/routers/utils/helper";

export const routes= [
    {
        path: "/payer-service-line/:payer_id",
        name: "add-payer-service-line",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"payer-serviceline-add",group:"payer-service-line" },
        component: lazyLoad("protected/organization/PayerServiceLine/Add-payer-serviceline")
    },
    {
        path: "/payers/service-line/:payer_id",
        name: "payer-service-line",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"payer-serviceline-list",group:"payer-service-line" },
        component: lazyLoad("protected/organization/PayerServiceLine/Payer-serviceline")
    },
    {
        path: "/payers-service-line/:service_line_id",
        name: "edit-payer-service-line",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"payer-serviceline-edit",group:"payer-service-line"},
        component: lazyLoad("protected/organization/PayerServiceLine/Edit-payer-serviceline")
    },
];
