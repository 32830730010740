import { lazyLoad } from "@/routers/utils/helper";
const menu = (routeUuid) => {
    return;
};
export const routes = [
    {
        path: "/staff",
        name: "staff.index",
        meta: {
            middleware: "auth",
            user_permission: [
                'staff.view-all',
                'staff.view-only-matched-agency-address',
                'staff.view-only-matched-agency'
            ],
        },
        component: lazyLoad('pages/staff/Index')
    },
    // {
    //     path: "/add-staff",
    //     name: "staff.create",
    //     meta: { ,middleware: "auth",user_permission:"staff-add-edit", breadcrumb: {
    //             label: "Add Staff",
    //             parent: "staff.index"
    //         } },
    //     component: lazyLoad("pages/staff/show/AddStaff")
    // },
    // {
    //     path: "/add-staff",
    //     name: "add-user",
    //     meta: { ,middleware: "auth", user_permission:"staff-add-edit", breadcrumb: {
    //             label: "Add New Staff",
    //             parent: "users"
    //         } },
    //     component: lazyLoad("protected/User/Add-user")
    // },
    // {
    //     path: "/user-document-settings",
    //     name: "document-settings",
    //     meta: { ,middleware: "auth", user_permission:"staff-add-edit", breadcrumb: {
    //             label: "Document Settings",
    //             parent: "staff.index"
    //         } },
    //     component: lazyLoad("protected/User/UserUploadFileAdmin")
    // },
    // {
    //     path: "/add-user-document-settings",
    //     name: "add-document-settings",
    //     meta: { ,middleware: "auth", breadcrumb: {
    //             label: "Add Edit Document Label Settings",
    //             parent: "users"
    //         } },
    //     component: lazyLoad("protected/User/AddEditUserUploadFileAdmin")
    // },
    {
        path: '/staff/create',
        name: 'staff.create',
        meta: {
            middleware: 'auth',
            user_permission: "staff.add-or-edit"
        },
        component: lazyLoad("pages/staff/Create"),
    },
    {
        path: "/staff/:user_id",
        meta: {
            middleware: "auth",
            user_permission: [
                'staff.view-all',
                'staff.view-only-matched-agency-address',
                'staff.view-only-matched-agency'
            ],
        },
        component: lazyLoad("pages/staff/show/Show"),
        children: [
            {
                path: "",
                name: 'staff.show',
                component: lazyLoad('pages/staff/show/GeneralInfo'),
                meta: {
                    middleware: "auth",
                    user_permission: [
                        'staff.view-all',
                        'staff.view-only-matched-agency-address',
                        'staff.view-only-matched-agency'
                    ],
                },
            },
            {
                path: "availability",
                name: 'staff.availability',
                component: lazyLoad('pages/staff/show/Availability'),
                meta: {
                    middleware: "auth",
                    user_permission: [
                        'staff.view-all',
                        'staff.view-only-matched-agency-address',
                        'staff.view-only-matched-agency'
                    ],
                },
            },
            {
                path: "address",
                name: 'staff.address',
                component: lazyLoad('pages/staff/show/Address'),
                meta: {
                    middleware: "auth",
                    user_permission: [
                        'staff.view-all',
                        'staff.view-only-matched-agency-address',
                        'staff.view-only-matched-agency'
                    ],
                },
            },
            {
                path: "students",
                name: 'staff.students',
                component: lazyLoad('pages/staff/show/StudentAssign'),
                meta: {
                    middleware: "auth",
                    user_permission: [
                        'staff.view-all',
                        'staff.view-only-matched-agency-address',
                        'staff.view-only-matched-agency'
                    ],
                },
            },
            {
                path: 'signature',
                name: 'staff.signature',
                component: lazyLoad('pages/staff/show/Signature'),
                meta: {
                    middleware: "auth",
                    user_permission: [
                        'staff.view-all',
                        'staff.view-only-matched-agency-address',
                        'staff.view-only-matched-agency'
                    ],
                },
            },
            {
                path: "paycode",
                name: 'staff.paycode',
                component: lazyLoad('pages/staff/show/Paycode'),
                meta: {
                    middleware: "auth",
                    user_permission: [
                        'staff.view-all',
                        'staff.view-only-matched-agency-address',
                        'staff.view-only-matched-agency'
                    ],
                },
            },
            {
                path: "group",
                name: 'staff.group',
                component: lazyLoad('pages/staff/show/SessionGroup'),
            },
        ]
    },
    // {
    //     path: "/staff/:user_id/paycode",
    //     name: "staff.paycode",
    //     meta: { ,middleware: "login", user_permission:"staff-add-edit", breadcrumb: {
    //             label: "Staff Paycode",
    //             parent: "staff.index"
    //         }},
    //     component: lazyLoad("pages/staff/show/Paycode")
    // },
    // {
    //     path: "/staff/:user_id/address",
    //     name: "staff.address",
    //     meta: { ,middleware: "login", user_permission:"staff-add-edit", breadcrumb: {
    //             label: "Staff Address",
    //             parent: "staff.index"
    //         }},
    //     component: lazyLoad("pages/staff/show/Address")
    // },
    // {
    //     path: "/delete-staff",
    //     name: "delete-user",
    //     meta: { ,middleware: "login", breadcrumb: {
    //             label: "Delete Staff",
    //             parent: "users"
    //         }},
    //     component: lazyLoad("protected/User/user-delete")
    // },
    // {
    //     path: "/daily-salary",
    //     name: "daily-salary",
    //     meta: { ,middleware: "login", breadcrumb: {
    //             label: "Daily Salary",
    //             parent: "users"
    //         }},
    //     component: lazyLoad("protected/User/salary/daily-salary")
    // },
    // {
    //     path: "/staff-daily-salary/:user_id",
    //     name: "staff-daily-salary",
    //     meta: { ,middleware: "login", breadcrumb: {
    //             label: "Staff Daily Salary",
    //             parent: "daily-salary"
    //         }},
    //     component: lazyLoad("protected/User/salary/staff-daily-salary")
    // },
    // {
    //     path: "/due-payout",
    //     name: "due-payout",
    //     meta: { ,middleware: "login", breadcrumb: {
    //             label: "Due Payout",
    //             parent: "users"
    //         }},
    //     component: lazyLoad("protected/User/salary/due-payout")
    // },
    // {
    //     path: "/staff-due-payout/:user_id",
    //     name: "staff-due-payout",
    //     meta: { ,middleware: "login", breadcrumb: {
    //             label: "Staff Due Payout",
    //             parent: "due-payout"
    //         }},
    //     component: lazyLoad("protected/User/salary/staff-due-payout")
    // },
    // {
    //     path: "/staff-salary",
    //     name: "staff-salary",
    //     meta: { ,middleware: "login", breadcrumb: {
    //             label: "Staff Salary",
    //             parent: "users"
    //         }},
    //     component: lazyLoad("protected/User/salary/staff-salary")
    // },
    // {
    //     path: "/staff/:user_id/documents",
    //     name: "staff.documents",
    //     meta: { ,middleware: "login", user_permission:"staff-document-view", breadcrumb: {
    //             label: "Staff Documents",
    //             parent: "staff.index"
    //         }},
    //     component: lazyLoad("pages/staff/show/Document")
    // },
    // {
    //     path: "/staff/:user_id/students",
    //     name: "staff.students",
    //     meta: { ,middleware: "login", user_permission:"staff-add-edit", breadcrumb: {
    //             label: "Staff Students",
    //             parent: "staff.index"
    //         }},
    //     component: lazyLoad("pages/staff/show/StudentAssign")
    // },
    // {
    //     path: "/staff/:user_id/signature",
    //     name: "staff.signature",
    //     meta: { ,middleware: "login", user_permission:"staff-signature-view", breadcrumb: {
    //             label: "Staff Signature",
    //             parent: "staff.index"
    //         }},
    //     component: lazyLoad("pages/staff/show/Signature")
    // },
    // {
    //     path: "/staff/:user_id/license",
    //     name: "staff.license",
    //     meta: { ,middleware: "login", user_permission:"staff-add-edit", breadcrumb: {
    //             label: "Staff Licenses/Certifications",
    //             parent: "staff.index"
    //         }},
    //     component: lazyLoad("pages/staff/show/License")
    // },
    // {
    //     path: "/staff/:user_id/availability",
    //     name: "staff.availability",
    //     meta: { ,middleware: "login", user_permission:"staff-add-edit", breadcrumb: {
    //             label: "Staff Availability",
    //             parent: "staff.index"
    //         }},
    //     component: lazyLoad("pages/staff/show/Availability")
    // },
];
