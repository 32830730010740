import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/add-goal",
        name: "add-goal",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"goals-add", breadcrumb: {
                label: "Add Target Playlists",
                parent: "goal"
            } },
        component: lazyLoad("protected/organization/Goal/Add-goal")
    },
    {
        path: "/goal",
        name: "goal",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"goals-list", breadcrumb: 'Target Playlists' },
        component: lazyLoad("protected/organization/Goal/List-goal")
    },
    {
        path: "/edit-goal/:goal_id",
        name: "edit-goal",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"goals-edit", breadcrumb: {
                label: "Edit Target Playlists",
                parent: "goal"
            }},
        component: lazyLoad("protected/organization/Goal/Edit-goal")
    },
    {
        path: "/delete-goal",
        name: "delete-goal",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"goals-list", breadcrumb: {
                label: "Delete Program Categories",
                parent: "goal"
            } },
        component: lazyLoad("protected/organization/Goal/Delete-goal")
    },
];
