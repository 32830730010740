<template>
    <GuestAuthenticationLayout header="Login" subtitle="Login to you account to continue">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form name="form" @submit.prevent="handleSubmit(login)">
          <ValidationProvider name="username" rules="required" v-slot="{ errors }">
            <div class="form-group">
              <label class="font-weight-bold">Username/Email/Phone</label>
              <InputText v-model="form.username"
                         name="username"
                         autocomplete="username"
                         type="text"
                         :error="errors[0]"
                         placeholder="Enter username/Email/Phone number" />
            </div>
          </ValidationProvider>
          <ValidationProvider name="password" rules="required" v-slot="{ errors }">
            <div class="form-group">
              <label class="font-weight-bold">Password</label>
              <InputPassword v-model="form.password"
                             name="password"
                             autocomplete="current-password"
                             placeholder="Enter password"
                             :error="errors[0]" />
            </div>
          </ValidationProvider>
          <div class="form-group text-center">
            <b-overlay :show="buttonLoading" rounded="lg" opacity="0.6">
              <button type="submit" class="btn btn-green btn-block text-white mx-0">Sign in</button>
            </b-overlay>
          </div>
        </form>
        <div class="mt-3 text-center">
          <router-link :to="{ name: 'forgot-password' }" class="btn btn-link text-primary">Forgot password?</router-link>
        </div>
      </ValidationObserver>
    </GuestAuthenticationLayout>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {mapGetters} from 'vuex';
import HelpDeskWidget from "@/components/commons/HelpDesk/HelpDeskWidget.vue";
import InputText from "@/components/commons/InputText.vue";
import InputPassword from "@/components/commons/InputPassword.vue";
import GuestAuthenticationLayout from "@/components/layouts/guest/GuestAuthenticationLayout.vue";

export default {
    components: {
      GuestAuthenticationLayout,
      InputPassword,
      InputText,
      HelpDeskWidget,
      ValidationProvider,
      ValidationObserver
    },
    data () {
        return {
            form: {
                username: '',
                password: '',
            }
        }
    },
    computed: {
      ...mapGetters({
        progress: 'progress',
        authenticated: 'authenticated'
      }),
      buttonLoading() {
        return this.progress.login
      }
    },
    methods: {
       login(){
         if (!this.authenticated) {
           this.$store.dispatch('login', {
             data: {username: this.form.username, password: this.form.password},
             callback: (response) => {
               if (response.status >= 200 && response.status < 300) {
                 this.$router.push({name: 'dashboard'})
               }
               if (response.status === 422) {
                 this.$refs.form.setErrors({...response.data.errors});
               }
             }
           });
         } else {
           // This redirection is required for multiple tab logic. When the used logged in from other tab.
           this.$router.push({name: 'dashboard'});
         }
        }
    }
}
</script>

