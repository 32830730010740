import HTTP from "@/service/api/_axios_setup";

export default {
    index: (query) => HTTP.get('/user-staff',{params: (query || null)}),
    show: (uuid) => HTTP.get('/user-staff/'+uuid),
    create: (payload) => HTTP.post('/user-staff', payload),
    update: (uuid, payload) => HTTP.put('/user-staff/'+uuid, payload),
    // destroy(student, hasRestore, hasInBulk) {
    //     const url = hasInBulk ? `/student/bulk` : `/student/${student}`;
    //     return HTTP.delete(url, {
    //         params: {

    //         }
    //     })
    // }
}
