<template>
  <GuestAuthenticationLayout header="Reset your password"
              subtitle="Reset your account password to recover your access in your account.">
    <ValidationObserver ref="form" v-slot="{ handleSubmit }" v-if="!hasCompleted">
      <form name="form" @submit.prevent="handleSubmit(changePassword)">
        <div class="form-group">
          <ValidationProvider name="password" rules="required|confirmed:confirmation" v-slot="{ errors }">
            <label class="font-weight-bold">New password</label>
            <InputPassword placeholder="New Password"
                           name="new_password"
                           autocomplete="new password"
                           :error="errors[0]"
                           v-model="form.password"/>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <ValidationProvider name="confirm new password" rules="required" vid="confirmation" v-slot="{ errors }">
            <label class="font-weight-bold">Confirm new password</label>
            <InputPassword placeholder="Re-enter your new password"
                           name="confirm_new_password"
                           autocomplete="new password"
                           :error="errors[0]"
                           v-model="form.confirm_password"/>
          </ValidationProvider>
        </div>
        <b-overlay :show="buttonLoading" rounded="lg" opacity="0.6" v-if="!hasCompleted">
          <button type="submit" class="btn btn-green mx-0 btn-block">Submit</button>
        </b-overlay>
      </form>
    </ValidationObserver>
    <b-alert show v-if="hasCompleted && statusMessage" variant="info">
      <p class="m-0">{{statusMessage}}</p>
    </b-alert>
    <div class="mt-4">
      Return to
      <router-link :to="{ name: 'home' }" class="text-primary font-weight-bold">Sign in</router-link>
    </div>
    <HelpDeskWidget/>
  </GuestAuthenticationLayout>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import HelpDeskWidget from "@/components/commons/HelpDesk/HelpDeskWidget.vue";
import Api from '@/service/api/_mix_api';
import InputText from "@/components/commons/InputText.vue";
import InputPassword from "@/components/commons/InputPassword.vue";
import Vue from "vue";
import _ from "lodash";
import GuestAuthenticationLayout from "@/components/layouts/guest/GuestAuthenticationLayout.vue";
import {mapGetters} from "vuex";

export default {
  name: 'ResetPassword',
  components: {
    GuestAuthenticationLayout,
    InputPassword,
    InputText,
    ValidationProvider,
    ValidationObserver,
    HelpDeskWidget
  },
  data() {
    return {
      form: {
        encrypted_uuid: '',
        encrypted_email: '',
        token: '',
        password: '',
        confirm_password: '',
      },
      buttonLoading: false,
      hasCompleted: false,
      statusMessage: null
    }
  },
  mounted() {
    this.hasCompleted = false;
    this.form.token = this.$router.currentRoute.params.token;
    this.form.encrypted_uuid = this.$router.currentRoute.params.encrypted_uuid;
    this.form.encrypted_email = this.$router.currentRoute.params.encrypted_email;
  },
  computed: {
    ...mapGetters({
      authenticated: 'authenticated'
    })
  },
  methods: {
    changePassword() {
      if (!this.authenticated) {
        this.statusMessage = null;
        this.buttonLoading = true;
        return Api.authentication
            .resetPassword(this.form.encrypted_email, this.form.encrypted_uuid, this.form.token, this.form.password)
            .then(response => {
              if (response.status >= 200 && response.status < 300) {
                this.hasCompleted = true;
                this.statusMessage = response.data.msg;
                this.$refs.form.reset();
              }
              if (response.status === 422) {
                if (_.has(response.data.errors, 'error')) {
                  this.$toast.open({message: _.get(response.data.errors, 'error.0'), type: 'error'});
                }
                this.$refs.form.setErrors({...response.data.errors});
              }
            })
            .catch(error => {
              error.message && Vue.$toast.open({message: error.message, type: 'error'});
            }).finally(() => this.buttonLoading = false);
      } else {
        // This redirection is required for multiple tab logic. When the used logged in from other tab.
        this.$router.push({name: 'dashboard'});
      }
    }
  }
}
</script>

