import Api from "@/service/api/_mix_api";
import _ from "lodash";

const state = {
    week_days: [],
    isLoading: false
}
const mutations = {
    setIsLoading(state, status) {
        state.isLoading = !!status;
    },
    setWeekDays(state, weekDays) {
        state.week_days = [...weekDays];
    }
};
const actions = {
    fetch({ commit, getters }) {
        if (_.isEmpty(getters.week_days)) {
            commit('setIsLoading', true);
            Api.week_days.index()
                .then( response => {
                    if (response.status === 200) {
                        commit('setWeekDays', [... response.data.data])
                    }
                }).finally(() => commit('setIsLoading', false));
        }
    }
};
const getters = {
    is_loading: (state) => state.isLoading,
    week_days: (state) => state.week_days || [],
    find_week_day: (state, getters) => (week_day_id) => {
        return [...getters.week_days].find(item => _.toNumber(item.id) === _.toNumber(week_day_id));
    },
    extended_week_days: (state, getters) => (selected_ids) => {
        selected_ids = (_.isEmpty(selected_ids) ? [] : [...selected_ids]).map(item => _.toNumber(item));
        return [...getters.week_days].map(item => {
            return {
                ...item,
                short_name: _.size(item.name) > 0 ? item.name.toString().substring(0,3).toUpperCase() : '-',
                selected: _.includes(selected_ids, _.toNumber(item.id))
            }
        })
    }

};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}