import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: '/certification-type',
        name: 'certification-type.index',
        meta: {
            middleware: 'auth',
            user_permission:null,
        },
        component: lazyLoad('pages/certification-type/Index')
    },
    {
        path: '/certification-type/create',
        name: 'certification-type.create',
        meta: {
            middleware: 'auth',
            user_permission:null,
        },
        component: lazyLoad('pages/certification-type/parts/index/Add-certification-type')
    },
    {
        path: '/certification-type/:certification_type_id',
        name: 'certification-type.edit',
        redirect:{ name: 'certification-type.show' },
        meta: {
            middleware: 'auth',
            user_permission:null,
        },
        component: lazyLoad("pages/certification-type/show/Layout"),
        children: [
            {
                path: "",
                name: "certification-type.show",
                meta: {
                    middleware: 'auth',
                    user_permission:null,
                },
                component: lazyLoad('pages/certification-type/parts/index/Edit-certification-type')
            },
        ]
    }
];
