<script>
import Password from 'vue-password-strength-meter'
export default {
  name: "InputPassword",
  components: {
    Password
  },
  props: {
    withPasswordMeter: Boolean,
    value: {
      type: String
    },
    error: {
      type: String,
      default() {
        return null;
      }
    }
  },
  computed: {
    modelValue: {
      get() { return this.value; },
      set(value) { this.$emit('input', value); }
    },
    type() {
      return this.passwordIsVisible ? 'text' : 'password';
    },
    scoreText() {
      if (this.modelValue && this.modelValue.length && !this.error) {
        switch (this.score) {
          case 0:
            return 'Very week';
          case 1:
            return 'Week';
          case 2:
            return 'Good';
          case 3:
            return 'Strong';
          case 4:
            return 'Very strong';
          default:
            return null;
        }
      }
      return null;
    },
    scoreClass() {
      if (this.modelValue && this.modelValue.length && !this.error) {
        return `with-score score-${this.score}`;
      }
      return null;
    }
  },
  data() {
    return {
      score: null,
      passwordIsVisible: false
    };
  },
  methods: {
    resetPasswordVisibility() {
      this.passwordIsVisible = false;
    },
    forceInvisible() {
      if (this.passwordIsVisible) {
        this.passwordIsVisible = false;
      }
    },
    updateScore(score) {
      this.score = score;
    }
  },
  mounted() {
    document.addEventListener('mouseup', this.forceInvisible);
  },
  beforeDestroy() {
    document.removeEventListener('mouseup', this.forceInvisible);
  }
}
</script>

<template>
  <div class="input-password" :class="scoreClass">
    <div class="input-group">
      <input v-bind="$attrs" :type="type" v-model="modelValue" class="form-control" :class="{'is-invalid': !!error}">
      <div class="input-group-append">
        <button type="button" class="btn btn-outline-secondary" @mousedown="passwordIsVisible = true">
          <font-awesome-icon :icon="['far', !passwordIsVisible ? 'eye' : 'eye-slash']" />
        </button>
      </div>
      <div class="invalid-feedback" v-if="!!error">{{error}}</div>
    </div>
    <div class="input-password-meter" v-if="withPasswordMeter && modelValue && modelValue.length && !error">
      <div class="input-password-meter-score">{{scoreText}}</div>
      <Password class="input-password-meter-score-bar" v-model="modelValue" :strength-meter-only="true" @score="updateScore" />
    </div>
  </div>
</template>

<style scoped>

</style>
