import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/roles",
        name: "roles.index",
        meta: { 
            middleware: "auth",
            user_permission:""
        },
        component: lazyLoad("pages/role/Role")
    },
    {
        path: "/roles/add",
        name: "roles.add",
        meta: { 
            middleware: "auth",
            user_permission:""
        },
        component: lazyLoad("pages/role/AddRole")
    },
    {
        path: "/roles/:role_id",
        name: "roles.show",
        meta: { 
            middleware: "auth",
            user_permission:""
        },
        component: lazyLoad("pages/role/EditRole")
    },
    {
        path: "/delegated-permission",
        meta: {
            middleware: "auth",
            user_permission:""
        },
        component: lazyLoad("pages/role/delegated-user/Layout"),
        children: [
            {
                path: "principal",
                name: 'delegated-permission.principal',
                component: lazyLoad('pages/role/delegated-user/Principal'),
            },
            {
                path: "supervisor",
                name: 'delegated-permission.supervisor',
                component: lazyLoad('pages/role/delegated-user/Supervisor'),
            }
        ]
    }
];
