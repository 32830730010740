import _ from "lodash";
import Api from "@/service/api/_mix_api";

const state = {
    student_assign_staffs: [],
    student_staff_assign_type: []
};
const mutations = {
    setStudentAssignStaff(state, payload) {
        state.student_assign_staffs = [...payload];
    },
    setStudentAssignType(state, payload) {
        state.student_staff_assign_type = [...payload];
    }
};
const actions = {
    studentAssignStaff(context, uuid) {
        const isForced = !!uuid;
        if (isForced || _.isEmpty(context.getters.student_assign_staffs)) {
            return Api.student_assign_staff.index(uuid).then((response) => {
                if (response.status === 200) {
                    context.commit('setStudentAssignStaff', [...response.data.data]);
                }
            })
        }
    },
    fetchAssignType(context, uuid) {
        const isForced = !!uuid;
        if (isForced || _.isEmpty(context.getters.student_staff_assign_type)) {
            return Api.student_staff_assign_type.index().then((response) => {
                if (response.status === 200) {
                    context.commit('setStudentAssignType', [...response.data.data]);
                }
            })
        }
    },
};
const getters = {
    student_assign_staffs: state => state.student_assign_staffs || [],
    student_staff_assign_type: state => state.student_staff_assign_type || [],
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}