import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/behavior-category",
        name: "behavior-category",
        meta: { layout:"ProtectedVue",middleware: "auth",sidebar: "profile", user_permission:"behavior-list", breadcrumb: 'Behavior Category'},
        component: lazyLoad("protected/organization/BehaviorCategory/BehaviorCategory")
    },
    {
        path: "/edit-behavior-category/:edit_id",
        name: "edit-behavior-category",
        meta: { layout:"ProtectedVue",middleware: "auth",sidebar: "profile", user_permission:"behavior-edit", breadcrumb: {
                label: "Edit Behavior Category",
                parent: "behavior-category"
            }},
        component: lazyLoad("protected/organization/BehaviorCategory/BehaviorCategory-edit")
    },
    {
        path: "/add-behavior-category/",
        name: "add-behavior-category",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"behavior-add", breadcrumb: {
                label: "Add New Behavior Category",
                parent: "behavior-category"
            } },
        component: lazyLoad("protected/organization/BehaviorCategory/BehaviorCategory-add")
    },
    {
        path: "/delete-behavior-category",
        name: "delete-behavior-category",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"behavior-delete", breadcrumb: {
                label: "Delete Behavior Category",
                parent: "behavior-category"
            }},
        component: lazyLoad("protected/organization/BehaviorCategory/BehaviorCategory-delete")
    },
];
