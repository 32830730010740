import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/goal-generator/excl-doc",
        name: "excel-document",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Goal Generator", breadcrumb: 'Excl To Doc'},
        component: lazyLoad("protected/Goal/ExclDoc")
    },
    {
        path: "/goal-generator/doc-excl",
        name: "document-excel",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Goal Generator", breadcrumb: 'Doc To Excl'},
        component: lazyLoad("protected/Goal/DocExcl")
    },
    {
        path: "/goal-generator/doc-excl-v2",
        name: "document-excel-v2",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Goal Generator", breadcrumb: 'Doc To Excl V2'},
        component: lazyLoad("protected/Goal/DocExclV2")
    },
    {
        path: "/goal-generator/doc-excl/template-list",
        name: "document-conversion-template-list",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Goal Generator", breadcrumb: {
                label: "Template List",
                parent: "document-excel"
            }},
        component: lazyLoad("protected/Goal/Conversion/ConversionTemplateList")
    },
    {
        path: "/goal-generator/doc-excl/template",
        name: "document-conversion-template",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Goal Generator", breadcrumb: {
                label: "Template Add",
                parent: "document-conversion-template-list"
            }},
        component: lazyLoad("protected/Goal/Conversion/ConversionTemplateAdd")
    },
    {
        path: "/goal-generator/doc-excl/template/:u_id",
        name: "document-conversion-template-edit",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Goal Generator", breadcrumb: {
                label: "Template Edit",
                parent: "document-conversion-template-list"
            }},
        component: lazyLoad("protected/Goal/Conversion/ConversionTemplateEdit")
    },
    {
        path: "/goal-generator/edit-file-data/:file_id",
        name: "edit-file-data",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Goal Generator", breadcrumb: {
                label: "Edit File Data",
                parent: "document-excel"
            }},
        component: lazyLoad("protected/Goal/EditClientFile")
    },
];
