import { lazyLoad } from "@/routers/utils/helper";

export const routes = [
    {
        path: "/agency",
        name: "agency.index",
        meta: {
            middleware: "auth",
            user_permission: null
        },
        component: lazyLoad('pages/agency/Index')
    },
    {
        path: '/agency/create',
        name: 'agency.create',
        meta: {
            middleware: "auth",
            user_permission: null
        },
        component: lazyLoad('pages/agency/show/AddAgency')
    },
    {
        path: '/agency/:uuid',
        name: 'agency.edit',
        redirect: { name: 'agency.show' },
        meta: {
            middleware: "auth",
            user_permission: null
        },
        component: lazyLoad("pages/agency/show/Layout"),
        children: [
            {
                path: "",
                name: 'agency.show',
                meta: {
                    middleware: "auth",
                    user_permission: null
                },
                component: lazyLoad('pages/agency/show/EditAgency')
            },
            {
                path: 'funders-services',
                name: 'agency.funders-services',
                meta: {
                    middleware: "auth",
                    user_permission: null
                },
                component: lazyLoad('pages/agency/show/FundersServices')
            },
            {
                path: 'address',
                name: 'agency.address',
                meta: {
                    middleware: "auth",
                    user_permission: null
                },
                component: lazyLoad('pages/agency/show/AgencyAddress')
            },
        ]
    },
    {
        path: "/agency/:uuid/funder/:funder_id",
        name: "agency.funder.edit",
        redirect: { name: 'agency.funder.show' },
        meta: {
            middleware: "auth",
            user_permission: null
        },
        component: lazyLoad("pages/agency/funder/show/Layout"),
        children: [
            {
                path: "",
                name: 'agency.funder.show',
                meta: {
                    middleware: "auth",
                    user_permission: null
                },
                component: lazyLoad('pages/agency/funder/show/EditFunder')
            },
            {
                path: "plans",
                name: 'agency.funder.plan',
                meta: {
                    middleware: "auth",
                    user_permission: null
                },
                component: lazyLoad('pages/agency/funder/show/Plan')
            },
        ]
    },
    // {
    //     path: '/agency/:uuid',
    //     name: 'agency.show',
    //     meta: {
    //         layout: "ProtectedVue",
    //         middleware: "auth",
    //         sidebar: "profile",
    //         user_permission: "agency-view",
    //         breadcrumb: "Agency"
    //     },
    //     component: lazyLoad('pages/agency/Show')
    // },
    // {
    //     path: '/agency/:uuid/funders-services',
    //     name: 'agency.funders-services',
    //     meta: {
    //         layout: "ProtectedVue",
    //         middleware: "auth",
    //         sidebar: "profile",
    //         user_permission: "agency-view",
    //         breadcrumb: "Agency"
    //     },
    //     component: lazyLoad('pages/agency/parts/show/FundersServices')
    // },
    // {
    //     path: '/agency/:uuid/address',
    //     name: 'agency.address',
    //     meta: {
    //         layout: "ProtectedVue",
    //         middleware: "auth",
    //         sidebar: "profile",
    //         user_permission: "agency-view",
    //         breadcrumb: "Agency"
    //     },
    //     component: lazyLoad('pages/agency/show/AgencyAddress')
    // },
    // {
    //     path: '/agency/:uuid/contact-info',
    //     name: 'agency.contact-info',
    //     meta: {
    //         middleware: "auth",
    //         user_permission: [
    //             'agency.view-all',
    //             'agency.view-only-assigned',
    //             'agency.view-only-matched-agency'
    //         ]
    //     },
    //     component: lazyLoad('pages/agency/parts/show/ContactInfo')
    // },
    // {
    //     path: '/agency/:uuid/other-info',
    //     name: 'agency.other-info',
    //     meta: {
    //         middleware: "auth",
    //         user_permission: [
    //             'agency.view-all',
    //             'agency.view-only-assigned',
    //             'agency.view-only-matched-agency'
    //         ]
    //     },
    //     component: lazyLoad('pages/agency/parts/show/OtherInfo')
    // }
    // {
    //     path: "/add-agency",
    //     name: "add-agency",
    //     meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"agency-add-edit", breadcrumb: {
    //             label: "Add New Agency",
    //             parent: "agency"
    //         } },
    //     component: lazyLoad("protected/organization/Agency/Add-agency")
    // },
    // {
    //     path: "/edit-agency/:agency_id",
    //     name: "edit-agency",
    //     meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"agency-add-edit", breadcrumb: {
    //             label: "Edit Agency",
    //             parent: "agency"
    //         }},
    //     component: lazyLoad("protected/organization/Agency/Edit-agency")
    // },
    // {
    //     path: "/delete-agency",
    //     name: "delete-agency",
    //     meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"agency-delete", breadcrumb: {
    //             label: "Delete Agency",
    //             parent: "agency"
    //         }},
    //     component: lazyLoad("protected/organization/Agency/Delete-agency")
    // },
];
