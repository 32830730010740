import _ from "lodash";
import Api from "@/service/api/_mix_api";
import {softDelete, search, sorting} from '@/service/localFilters';

const state = {
    kareoFunders: [],
    localFilters: {}

};
const mutations = {
    setKareoFunders(state, data) {
        state.kareoFunders = data;
    },
    setLocalFilters(state, payload) {
        state.localFilters = {...payload};
    }
};
const actions = {
    fetch(context, payload) {
        const isForced = !!payload;
        if (isForced || _.isEmpty(context.getters.kareoFunders)) {
            return Api.kareo_funder.index()
            .then( response => { 
                context.commit('setKareoFunders', [... response.data.data]) 
            })
        }
    }
};
const getters = {
    kareoFunders: state => state.kareoFunders,
    kareoFundersWithFilters: state => {
        const filter = [
            // Trashed or published
            (collection) => softDelete(collection, state.localFilters),
            // Search
            (collection) => search(collection, state.localFilters, ['name']),
            // Sorting
            (collection) => sorting(collection, state.localFilters)
        ];
        return filter.reduce((collection, value) => {
            return value(collection);
        }, (state.kareoFunders || []));
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}