import _ from "lodash";
export const getMiddleware = (route) => {
    const middleware = _.get(route?.meta, 'middleware', null)
    return (middleware || '').toString().toLowerCase();
}

export class Redirect {
    path = null;
    from = null;
    to = null

    constructor(path, from, to) {
        this.path = path;
        this.from = from || null;
        this.to = to || null;
    }

    redirectTo() {
        return this.path;
    }
}

export class ResponseType {
    hasPermission = true;
    from = null;
    to = null

    constructor(permission, from, to) {
        this.hasPermission = !!permission;
        this.from = from || null;
        this.to = to || null;
    }

    permitted() {
        return this.hasPermission;
    }

    back() {
        return this.from;
    }
}

export class ResponseError extends Error {
    code = null
    constructor(message, code, options) {
        super(message, options);
        this.code = code
    }
}