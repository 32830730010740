import _ from "lodash";
import Api from "@/service/api/_mix_api";

const state = {
    staff_certification: []
};
const mutations = {
    setStaffCertification(state, payload) {
        state.staff_certification = {...payload};
    },
};
const actions = {
    staffCertification(context, payload) {
        const isForced = !!(payload?.forced || false);
        if (isForced || _.isEmpty(context.getters.staff_certification)) {
            return Api.staff.show(payload.uuid).then((response) => {
                if (response.status === 200) {
                    context.commit('setStaffCertification', [...response.data.data]);
                }
            })
        }
    }

};
const getters = {
    staff_certification: state => state.staff_certification || {}
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
