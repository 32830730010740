import _ from "lodash";
import Api from "@/service/api/_mix_api";

const state = {
    funder_plan: [],
    funder_plan_details: {}
};
const mutations = {
    setFunderPlan(state, data) {
        state.funder_plan = [...data];
    },
    setfunderPlanDetails(state, data) {
        state.funder_plan_details = data;
    },
    setExistingFunderPlan(state, payload) {
        state.funder_plan[payload.index] = payload.data;
    },
    setNewFunderPlan(state, data) {
        state.funder_plan = data;
    }
};
const actions = {
    fetch(context, payload) {
        const isForced = !!payload;
        if (isForced || _.isEmpty(context.getters.funder_plan)) {
            return Api.funder_plan.index(payload)
            .then( response => { 
                context.commit('setFunderPlan', [... response.data.data]) 
            })
        }
    },
    funderPlanDetails(context, payload) {
        const isForced = !!payload.isForced;
        if (isForced || _.isEmpty(context.getters.funder_plan_details)) {
            return Api.funder_plan.show(payload.fuuid, payload.puuid)
            .then( response => { 
                context.commit('setfunderPlanDetails', {... response.data.data}) 
            })
        }
    }
};
const getters = {
    funder_plan: state => state.funder_plan,
    funder_plan_details: state => state.funder_plan_details,
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}