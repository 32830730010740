<script>
import Sidenav from "@/components/layouts/authenticated/parts/Sidenav/Sidenav.vue";
import AppFooter from "@/components/layouts/authenticated/parts/Footer/AppFooter.vue";
import AppHeader from "@/components/layouts/authenticated/parts/Header/AppHeader.vue";
import {mapGetters} from "vuex";

export default {
  name: "AuthenticatedLayout",
  components: {Sidenav, AppFooter, AppHeader},
  computed: {
    ...mapGetters({
      isActiveSidenav: 'sidenav/visibility'
    })
  }
}
</script>

<template>
  <div class="app app-auth-layout" :class="{'with-sidenav': isActiveSidenav}">
    <Sidenav />
    <div class="app-content box-shadow-0" role="main">
      <div class="app-content-main-area">
        <AppHeader />
        <transition name="route-page">
         <router-view :key="$route.fullPath"></router-view>
        </transition>
      </div>
      <AppFooter />
    </div>
  </div>
</template>

<style scoped>

</style>
