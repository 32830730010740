import _ from "lodash";
import Api from "@/service/api/_mix_api";
import httpErrorHandler from "@/httpErrorHandler";

const state = {
    modules: [],
};
const mutations = {
    setModules(state, data) {
        state.modules = data;
    },
};
const actions = {
    fetchModules(context, query) {
        const isForced = !!query;
        if (isForced || _.isEmpty(context.getters.modules)) {
            Api.modules.index(query)
            .then( response => { context.commit('setModules', [... response.data.data]) })
            .catch(e => { context.commit('setMasterServicesErrorMsg', httpErrorHandler(e)); })
        }
    },
};
const getters = {
    modules: state => state.modules,
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}