import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/session-form",
        name: "session-form",
        meta: { layout:"ProtectedVue",middleware: "auth",sidebar: "profile", user_permission:"session-form-template-list"},
        component: lazyLoad("protected/SessionTemplates/Form/Form")
    },
    {
        path: "/edit-session-form/:session_form_id",
        name: "edit-session-form",
        meta: { layout:"ProtectedVue",middleware: "auth",sidebar: "profile", user_permission:"session-form-template-edit"},
        component: lazyLoad("protected/SessionTemplates/Form/Edit-form")
    },
    {
        path: "/add-session-form/",
        name: "add-session-form",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"session-form-template-add" },
        component: lazyLoad("protected/SessionTemplates/Form/Add-form")
    },
    {
        path: "/delete-session-form",
        name: "delete-session-form",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"session-form-template-delete"},
        component: lazyLoad("protected/SessionTemplates/Form/Delete-form")
    },
];
