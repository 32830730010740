import _ from "lodash";
import Api from "@/service/api/_mix_api";
// import * as JsSearch from "js-search";
// import { search, sorting } from "@/service/localFilters";

const state = {
    degreeTypes: [],
};
const mutations = {
    setDegreeTypes(state, payload) {
        state.degreeTypes = [...payload];
    }
};
const actions = {
    fetch(context, payload) {
        const isForced = !!payload;
        if (isForced || _.isEmpty(context.getters.degreeTypes)) {
            return Api.degree_type.index().then((response) => {
                if (response.status === 200) {
                    context.commit('setDegreeTypes', [...response.data.data]);
                }
            })
        }
    }
};
const getters = {
    degreeTypes: state => state.degreeTypes || []
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
