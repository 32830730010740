import _ from "lodash";
import Api from "@/service/api/_mix_api";
import httpErrorHandler from "@/httpErrorHandler";

const state = {
    isFetching: false,
    authorization_distribution_type: [],
};
const mutations = {
    setIsFetching(state, payload) {
        state.isFetching = !!payload;
    },
    setAuthorizationDistributionType(state, data) {
        state.authorization_distribution_type = data;
    },
};
const actions = {
    getDistributionType(context, query) {
        const isForced = !!query;
        if (isForced || _.isEmpty(context.getters.authorization_distribution_type)) {
            context.commit('setIsFetching', true);
            Api.authorization_distribution_type.index(query)
            .then( response => {
                if (response.status >= 200 && response.status < 300) {
                    context.commit('setAuthorizationDistributionType', [... response.data.data])
                }
            }).finally(() => context.commit('setIsFetching', false))
        }
    },
};
const getters = {
    isFetching: state => state.isFetching || false,
    authorization_distribution_type: state => state.authorization_distribution_type || [],
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}