<template>
  <div class="box-body app-helpdesk-form">
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <form @submit.prevent="handleSubmit(submit)">
      <ValidationProvider rules="required|name" name="name" v-slot="{ errors }">
        <div class="row form-group">
          <div class="col-12">
            <label class="col-form-label" for="helpDeskFullName">Full name<span class="text-danger">*</span></label>
            <div>
              <input type="text"
                     id="helpDeskFullName"
                     placeholder="Enter your full name"
                     :value="formName"
                     @input="updateFormName"
                     :class="{'is-invalid': errors[0]}"
                     class="form-control" />
              <div class="invalid-feedback" v-if="errors[0]">{{ errors[0] }}</div>
            </div>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider rules="required|email" name="email" v-slot="{ errors }">
        <div class="row form-group">
          <div class="col-12">
            <label class="col-form-label" for="helpDeskEmailAddress">Email address<span class="text-danger">*</span></label>
            <div>
              <input type="text"
                     id="helpDeskEmailAddress"
                     placeholder="Enter email address"
                     :class="{'is-invalid': errors[0]}"
                     :value="formEmail"
                     @input="updateFormEmail"
                     class="form-control" />
              <div class="invalid-feedback" v-if="errors[0]">{{errors[0]}}</div>
            </div>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider rules="required" name="feedback" v-slot="{ errors }">
        <div class="row form-group">
          <div class="col-12">
            <label class="col-form-label" for="helpDeskFeedback">Describe your feedback<span class="text-danger">*</span></label>
            <div>
              <textarea class="form-control feedback-area-input"
                        id="helpDeskFeedback"
                        :value="formFeedback"
                        @input="updateFormFeedback"
                        :class="{'is-invalid': errors[0]}"
                        placeholder="Tell us what prompted this feedback…"></textarea>
              <div class="invalid-feedback" v-if="errors[0]">{{errors[0]}}</div>
            </div>
          </div>
        </div>
      </ValidationProvider>
      <div class="row form-group">
        <div class="col-12">
          <label class="col-form-label">A screenshot will help us better understand your feedback. (optional)</label>
          <HelpDeskCaptureOrUploadScreenshot />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-12">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" :checked="formConsent" @change="updateFormConsent" id="helpDeskConceptOfEmailComm">
            <label class="form-check-label" for="helpDeskConceptOfEmailComm">We may email you for more information or updates</label>
          </div>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-12">
          <div class="alert alert-info mb-0">
            Some <a href="#">system information</a> may be sent to Hoopla. We will use it to fix problems and improve our services.
          </div>
        </div>
      </div>
      <div class="row form-group mb-0">
        <div class="col-12">
          <div class="d-flex flex-row align-items-center text-center justify-content-center" v-if="loading">
            <i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
            <span class="sr-only">Loading...</span>
          </div>
          <button type="submit" class="btn btn-green btn-block mx-0" v-if="!loading">Submit</button>
        </div>
      </div>
    </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import {mapGetters, mapState} from "vuex";
import HelpDeskCaptureOrUploadScreenshot from "./HelpDeskCaptureOrUploadScreenshot.vue";
import {userAgentDetails, dataURLtoFile} from "@/helpDesk";
import Api from "@/service/api";

extend('name', {
  validate: value => {
    return /^[a-zA-Z]+((\s)[a-zA-Z]+(\s[a-zA-Z]+)?)?(\s+)?$/.test(value)
  },
  message: 'The {_field_} field must be a valid name'
})

export default {
  name: "HelpDeskForm",
  components: {
    HelpDeskCaptureOrUploadScreenshot,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      form: {
        name: null,
        email: null,
        feedback: null,
        userConsentToCommunicateOnEmail: true
      },
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      imageDataURL : 'outputScreenShot'
    }),
    ...mapState({
      formName: (state) => state.help_desk.helpDeskFormName,
      formEmail: (state) => state.help_desk.helpDeskFormEmail,
      formFeedback: (state) => state.help_desk.helpDeskFormFeedback,
      formConsent: (state) => state.help_desk.helpDeskFormConsent
    }),
    loggedInUserEmail() {
      return this.user?.email || null
    },
    loggedInUserName() {
      return this.user?.name || null;
    }
  },
  methods: {
    updateFormName(event){ this.$store.commit('setHelpDeskFormName', event.target.value); },
    updateFormEmail(event){ this.$store.commit('setHelpDeskFormEmail', event.target.value); },
    updateFormFeedback(event){ this.$store.commit('setHelpDeskFormFeedback', event.target.value); },
    updateFormConsent(event){ this.$store.commit('setHelpDeskFormConsent', event.target.checked); },
    async submit() {
      const agentDetails = userAgentDetails();
      const imageFile = this.imageDataURL ? dataURLtoFile(this.imageDataURL, "screenshot.png") : null;
      const form = new FormData();
      form.append("name", this.formName);
      form.append("email", this.formEmail);
      form.append("feedback", this.formFeedback);
      form.append("user_content_email_communication", this.formConsent ? 1 : 0);
      if (imageFile) {
        form.append("screenshot", imageFile, "screenshot.png");
      }
      agentDetails.forEach((element, element_index) => {
        form.append(`user_agent[${element_index}][label]`, element.label);
        form.append(`user_agent[${element_index}][value]`, element.value);
      });
      this.loading = true;
      let response = {};
      try {
        response = await Api.sendFeedback(form);
        if(response.status === 200){
          this.$refs.form.reset();
          this.$store.dispatch('resetHelpDeskForm').then(() => {
            this.$store.commit('setHelpDeskFormNotification','Thanks for your feedback, we will review and improve our service.');
          });
        }
      } catch (error) {
        if(error.response.data.status != '302') {
          this.$store.commit('setHelpDeskFormNotification',  "Some error occured\n"+error.message);
        }
      }
      this.loading = false;
    },
  },
  mounted() {
    // **As per the client's feedback, prefill of name and email address feature disabled.
    // if (!this.formName && this.loggedInUserName) {
    //   this.$store.commit('setHelpDeskFormName', this.loggedInUserName);
    // }
    // if (!this.formEmail && this.loggedInUserEmail){
    //   this.$store.commit('setHelpDeskFormEmail', this.loggedInUserEmail);
    // }
  }
}
</script>

<style scoped>

</style>
