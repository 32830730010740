import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/service-line",
        name: "service-line.index",
        meta: {middleware: "auth",user_permission:null},
        component: lazyLoad("pages/service-line/Index")
    },
    {
        path: "/service-line/create",
        name: "service-line.create",
        meta: {middleware: "auth",user_permission:null},
        component: lazyLoad("pages/service-line/parts/index/Add-service-line")
    },
    {
        path: "/service-line/:service_line_id",
        name: "service-line.edit",
        redirect:{ name: 'service-line.show' },
        meta: {middleware: "auth",user_permission:null},
        component: lazyLoad("pages/service-line/show/Layout"),
        children: [
            {
                path: "",
                name: "service-line.show",
                meta: {middleware: "auth",user_permission:null},
                component: lazyLoad('pages/service-line/parts/index/Edit-service-line')
            },
        ]
    },
    // {
    //     path: "/delete-service-line",
    //     name: "delete-service-line",
    //     meta: {middleware: "login",user_permission:null
    //     component: lazyLoad("pages/service-line/parts/index/Delete-service-line")
    // },
    // {
    //     path: "/service-line/import",
    //     name: "service-line.import",
    //     meta: {middleware: "login",user_permission:null},
    //     component: lazyLoad("pages/service-line/parts/index/Import-service-line")
    // },
];
