import Vue from "vue"
import _ from "lodash";
import Api from "@/service/api/_mix_api";


const state = {
    timezones: []
};
const mutations = {
    setTimezones(state, payload) {
        state.timezones = payload;
    },
};
const actions = {
    // updateTimezones(context, payload) {
    //     context.commit('setTimezones', payload);
    // }
    fetch(context, payload) {
        const isForced = !!payload;
        if (isForced || _.isEmpty(context.getters.timezones)) {
            return Api.timezones.index().then((response) => {
                if (response.status === 200) {
                    context.commit('setTimezones', [...response.data.data]);
                }
            })
        }
    }
};
const getters = {
    timezones: state => state.timezones
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
