import Api from "@/service/api/_mix_api";
import _ from "lodash";
import {softDelete, search, sorting} from '@/service/localFilters';


const state = {
    serviceLines: [],
    localFilters: {}
};
const mutations = {
   
    setServiceLines(state, payload) {
        state.serviceLines = [...payload];
    },
    setLocalFilters(state, payload) {
        state.localFilters = {...payload};
    }
};
const actions = {
    fetch(context, payload) {
        const isForced = !!payload;
        // if (isForced || _.isEmpty(context.getters.serviceLines)) {
            return Api.service_line.index().then((response) => {
                if (response.status === 200) {
                    context.commit('setServiceLines', [...response.data.data]);
                }
            })
        // }
    }
};
const getters = {
    serviceLines: (state) => state.serviceLines || [],
    serviceLinesWithFilters: state => {
        const filter = [
            // Trashed or published
            (collection) => softDelete(collection, state.localFilters),
            // Search
            (collection) => search(collection, state.localFilters, ['name']),
            // Sorting
            (collection) => sorting(collection, state.localFilters)
        ];
        return filter.reduce((collection, value) => {
            return value(collection);
        }, (state.serviceLines || []));
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}