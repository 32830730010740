import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/goal-generator/goal-activity-list",
        name: "goal-activity-list",
        meta: { layout: "ProtectedVue", middleware: "auth", sidebar: "profile", user_permission: "Goal Generator", breadcrumb: 'Goal Activity List' },
        component: lazyLoad("protected/Goal/GoalAttributes/GoalAttributeList")
    },

    {
        path: "/goal-generator/add-goal-activity",
        name: "add-goal-activity",
        meta: {
            layout: "ProtectedVue", middleware: "auth", sidebar: "profile", user_permission: "Goal Generator",
            breadcrumb: {
                label: "Add Goal Activity",
                parent: "goal-activity-list"
            }
        },
        component: lazyLoad("protected/Goal/GoalAttributes/GoalAttributeAdd")
    },

    {
        path: "/goal-generator/edit-goal-activity/:actId",
        name: "edit-goal-activity",
        meta: { layout: "ProtectedVue", middleware: "auth", sidebar: "profile", user_permission: "Goal Generator",
            breadcrumb: {
                label: "Edit Goal Activity",
                parent: "goal-activity-list"
            }
        },
        component: lazyLoad("protected/Goal/GoalAttributes/GoalAttributeEdit")
    },

    {
        path: "/goal-generator/goal-activity-list-deleted",
        name: "goal-activity-list-deleted",
        meta: { layout: "ProtectedVue", middleware: "auth", sidebar: "profile", user_permission: "Goal Generator",
            breadcrumb: {
                label: "Goal Activity List Deleted",
                parent: "goal-activity-list"
            } }
        ,
        component: lazyLoad("protected/Goal/GoalAttributes/GoalAttributeListDeleted")
    },
];
