<template>
  <div class="tags-input-container">
    <div class="tags">
      <span v-for="(tag, index) in tags" :key="tag.id" class="tag">
        {{ tag.name }} <span class="tag-remove" @click="removeTag(index)">&times;</span>
      </span>
      <input
        type="text"
        v-model="inputValue"
        @keydown.enter.prevent="addTag"
        @input="onInput"
        placeholder="Add a tag"
      />
    </div>
    <div class="suggestions" v-if="filteredSuggestions.length">
      <div
        v-for="(suggestion, index) in filteredSuggestions"
        :key="suggestion.id"
        class="suggestion"
        @click="selectSuggestion(suggestion)"
      >
        {{ suggestion.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    initialTags: {
      type: Array,
      required: true
    },
    suggestions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      tags: this.initialTags,
      inputValue: '',
      filteredSuggestions: []
    }
  },
  methods: {
    addTag() {
      const tagName = this.inputValue.trim()
      if (tagName && !this.tags.some(tag => tag.name === tagName)) {
        const newTag = { name: tagName, id: Date.now() } // Assign a temporary id for new tags
        this.tags.push(newTag)
        this.$emit('update:tags', this.tags)
        this.$emit('new-tag-added', newTag)
      }
      this.inputValue = ''
      this.filteredSuggestions = []
    },
    removeTag(index) {
      this.tags.splice(index, 1)
      this.$emit('update:tags', this.tags)
    },
    onInput() {
      const value = this.inputValue.toLowerCase()
      this.filteredSuggestions = this.suggestions.filter(suggestion =>
        suggestion.name.toLowerCase().includes(value) && !this.tags.some(tag => tag.name === suggestion.name)
      )
    },
    selectSuggestion(suggestion) {
      this.tags.push(suggestion)
      this.$emit('update:tags', this.tags)
      this.$emit('new-tag-added', suggestion)
      this.inputValue = ''
      this.filteredSuggestions = []
    }
  }
}
</script>

<style scoped>
.tags-input-container {
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}
.tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.tag {
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
  margin: 2px;
  display: flex;
  align-items: center;
}
.tag-remove {
  margin-left: 5px;
  cursor: pointer;
}
input[type="text"] {
  border: none;
  outline: none;
  flex: 1;
  padding: 5px;
}
.suggestions {
  border-top: 1px solid #ccc;
  margin-top: 5px;
}
.suggestion {
  padding: 5px;
  cursor: pointer;
}
.suggestion:hover {
  background-color: #f0f0f0;
}
</style>
