import _ from "lodash";
import Api from "@/service/api/_mix_api";

const state = {
    student_details: {},
    student_contact_details: {},
};
const mutations = {
    setStudentDetails(state, payload) {
        state.student_details = {...payload};
    },
    setStudentContactDetails(state, payload) {
        // console.log(payload);
        state.student_contact_details = {...payload};
    },
};
const actions = {
    studentDetails(context, payload) {
        const isForced = !!(payload?.forced || false);
        if (isForced || _.isEmpty(context.getters.student_details)) {
            return Api.student.show(payload.uuid).then((response) => {
                if (response.status === 200) {
                    context.commit('setStudentDetails', {...response.data.data});
                }
            })
        }
    },
    update({ commit, state, rootState }, data) {
        return Api.student.update(data.uuid, data.payload)
        .then((response) => {
            if(response.status === 200) {
                commit('setStudentDetails', {...response.data.data});
                const students = rootState.student.students;
                let updatedStudents = students.map(student => 
                    student.uuid === response.data.data.uuid ? response.data.data : student
                );
                commit('student/setStudents', updatedStudents, { root: true });

                // if (data.callback && typeof data.callback === 'function') {
                //     return data.callback(null, response.data.data);
                // }
                if (data.callback && _.isFunction(data.callback)) {
                    data.callback(response);
                }
            }
        })
    },
    create({ commit, state, rootState }, data) {
        return Api.student.create(data.payload)
        .then((response) => {
            if(response.status === 201) {
                commit('setStudentDetails', {...response.data.data});
                const students = rootState.student.students;
                students.unshift(response.data.data);
                commit('student/setStudents', students, { root: true });

                if (data.callback && typeof data.callback === 'function') {
                    return data.callback(null, response.data.data);
                }
            }
        })
    },
};
const getters = {
    student_details: state => state.student_details || {},
    student_contact_details: state => state.student_contact_details || {},
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
