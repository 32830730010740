import _ from "lodash";
import Api from "@/service/api/_mix_api";
import {softDelete, search, sorting} from '@/service/localFilters';

const state = {
    authorization: [],
    // localFilters: {},
    isFetching: false,
    isDeleting: false,
    query: {
        page: 1,
        only_trashed: false
    },
    pagination: {},
    queueSelection: []

};
const mutations = {
    setAuthorization(state, data) {
        state.authorization = data;
    },
    // setLocalFilters(state, payload) {
    //     state.localFilters = {...payload};
    // }
    setIsFetching(state, payload) {
        state.isFetching = !!payload;
    },
    setIsDeleting(state, payload) {
        state.isDeleting = !!payload;
    },
    setPagination(state, payload) {
        state.pagination = {...payload};
    },
    setQuery(state, payload) {
        state.query = { ...payload};
    },
    setQueueSelection(state, payload) {
        state.queueSelection = [...payload];
    }
};
const actions = {
    fetch(context, payload) {
        const restoredQuery = _.cloneDeep(context.getters.query);
        const newQuery = _.cloneDeep(payload || {});
        context.commit('setQueueSelection', []);
        if (!_.isEqual(restoredQuery, newQuery) || _.isEmpty(context.getters.authorization)) {
            context.commit('setQuery', newQuery);
                return Api.authorization.index(newQuery)
                    .then((response) => {
                        if(response.status === 200) {
                            context.commit('setAuthorization', [...response.data.data]);
                            context.commit('setPagination', {...response.data.meta});
                        }
                    }).finally(() => context.commit('setIsFetching', false));
        }else {
            return null;
        }
    }
};

const getters = {
    authorization: state => state.authorization,
    authorizationFilters: state => {
        const filter = [
            // Trashed or published
            (collection) => softDelete(collection, state.localFilters),
            // Search
            (collection) => search(collection, state.localFilters, ['name']),
            // Sorting
            (collection) => sorting(collection, state.localFilters)
        ];
        return filter.reduce((collection, value) => {
            return value(collection);
        }, (state.authorization || []));
    },
    pagination: (state) => state.pagination || null,
    query: (state) => ({
        page: 1,
        only_trashed: false,
        ...state.query
    }),
    queueSelection: (state) => state.queueSelection || [],
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}