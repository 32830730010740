import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/bcbatasks",
        name: "bcbatasks",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"hcodes-list", breadcrumb: 'BCBA Task' },
        component: lazyLoad("protected/organization/BCBATask/bcba-task-list")
    },
    {
        path: "/add-bcbatask",
        name: "add-bcbatask",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"hcodes-add", breadcrumb: {
                label: "Add New BCBA Task",
                parent: "bcbatasks"
            } },
        component: lazyLoad("protected/organization/BCBATask/bcba-task-add")
    },
    {
        path: "/edit-bcbatask/:task_id",
        name: "edit-bcbatask",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"hcodes-edit", breadcrumb: {
                label: "Edit BCBA Task",
                parent: "bcbatasks"
            } },
        component: lazyLoad("protected/organization/BCBATask/bcba-task-edit")
    },
    {
        path: "/delete-bcbatask",
        name: "delete-bcbatask",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"hcodes-list", breadcrumb: {
                label: "Deleted BCBA Task",
                parent: "bcbatasks"
            } },
        component: lazyLoad("protected/organization/BCBATask/bcba-task-delete")
    },
    {
        path: "/import-bcbatask",
        name: "import-bcbatask",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"hcodes-list", breadcrumb: {
                label: "Import BCBA Task",
                parent: "bcbatasks"
            }},
        component: lazyLoad("protected/organization/BCBATask/bcba-task-import")
    },
];
