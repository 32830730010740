import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/session-template",
        name: "session-template",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"hcodes-list" },
        component: lazyLoad("protected/organization/SessionTemplate/session-template-list")
    },
    {
        path: "/add-session-template",
        name: "add-session-template",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"hcodes-add" },
        component: lazyLoad("protected/organization/SessionTemplate/session-template-add")
    },
    {
        path: "/edit-session-template/:st_id",
        name: "edit-session-template",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"hcodes-edit" },
        component: lazyLoad("protected/organization/SessionTemplate/session-template-edit")
    },
    {
        path: "/delete-session-template",
        name: "delete-session-template",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"hcodes-list" },
        component: lazyLoad("protected/organization/SessionTemplate/session-template-delete")
    },
];
