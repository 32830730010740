import _ from "lodash";
import Api from "@/service/api/_mix_api";
import httpErrorHandler from "@/httpErrorHandler";

const state = {
    coverage_types: [],
};
const mutations = {
    setCoverageTypes(state, data) {
        state.coverage_types = data;
    },
};
const actions = {
    getAllCoverageTypes(context, query) {
        const isForced = !!query;
        if (isForced || _.isEmpty(context.getters.coverage_types)) {
            Api.coverage_type.index(query)
            .then( response => { context.commit('setCoverageTypes', [... response.data.data]) })
            .catch(e => { context.commit('setMasterServicesErrorMsg', httpErrorHandler(e)); })
        }
    },
};
const getters = {
    coverage_types: state => state.coverage_types,
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}