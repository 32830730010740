import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/session-type",
        name: "session-type",
        meta: { layout:"ProtectedVue",middleware: "auth",sidebar: "profile", user_permission:"session-template-type-list"},
        component: lazyLoad("protected/SessionTemplates/Types/Type")
    },
    {
        path: "/edit-session-type/:session_type_id",
        name: "edit-session-type",
        meta: { layout:"ProtectedVue",middleware: "auth",sidebar: "profile", user_permission:"session-template-type-edit"},
        component: lazyLoad("protected/SessionTemplates/Types/Edit-type")
    },
    {
        path: "/add-session-type/",
        name: "add-session-type",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"session-template-type-add" },
        component: lazyLoad("protected/SessionTemplates/Types/Add-type")
    },
    {
        path: "/delete-session-type",
        name: "delete-session-type",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"session-template-type-delete"},
        component: lazyLoad("protected/SessionTemplates/Types/Delete-type")
    },
];
