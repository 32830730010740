import Vue from "vue";
import _ from "lodash";
import Api from "@/service/api/_mix_api";
import role from "./role";

const state = {
    permissions: [],
    principal_role_details: {},
    supervisor_role_details: {}
};

const mutations = {
    setPrincipalDetails(state, payload) {
        state.principal_role_details = payload;
    },
    setPrincipalDetails(state, payload) {
        state.supervisor_role_details = payload;
    },
    setPermissions(state, payload) {
        state.permissions = payload;
    },
};

const actions = {
    updatePrincipalDetails(context, payload) {
        context.commit('setPrincipalDetails', { ...payload });
    },
    fetchPrincipalDetails(context, payload) {
        context.commit('setPermissions', [...payload])
        return Api.delegated_permission.principal()
            .then(response => {
                context.commit('setPrincipalDetails', { ...response.data.data });
            });
    },
    updateSupervisorDetails(context, payload) {
        context.commit('setSupervisorDetails', { ...payload });
    },
    fetchSupervisorDetails(context, payload) {
        context.commit('setPermissions', [...payload])
        return Api.delegated_permission.supervisor()
            .then(response => {
                context.commit('setSupervisorDetails', { ...response.data.data });
            });
    },
};

const getters = {
    // roleMainPermissions: () => role.getters.getPermissions, // Ensure role.getters.getPermissions is a valid getter
    getPermissions: state => {
        return state.permissions;
    },
    getPrincipalDetails: (state, getters) => {
        const restored = _.cloneDeep({ ...state.principal_role_details });
        const originalPermissions = _.cloneDeep(getters.getPermissions);
        const rolePermission = _.cloneDeep(restored.permissions || []);

        if (rolePermission.length > 0) {
            const mixablePermissions = {};
            for (let index = 0; index < rolePermission.length; index++) {
                mixablePermissions[rolePermission[index].handle] = rolePermission[index];
            }

            restored['permissions'] = [...originalPermissions].map(item => {
                const updatedPermissions = [...item.permissions].map(permissionItem => {
                    const mixablePermission = mixablePermissions[permissionItem.handle];
                    if (mixablePermission) {
                        permissionItem.can_do = mixablePermission.can_do;
                    }
                    return permissionItem;
                });

                item.permissions = updatedPermissions;
                return item;
            });
        } else {
            restored['permissions'] = [...originalPermissions];
        }

        return restored;
    },
    getSupervisorDetails: (state, getters) => {
        const restored = _.cloneDeep({ ...state.principal_role_details });
        const originalPermissions = _.cloneDeep(getters.getPermissions);
        const rolePermission = _.cloneDeep(restored.permissions || []);

        if (rolePermission.length > 0) {
            const mixablePermissions = {};
            for (let index = 0; index < rolePermission.length; index++) {
                mixablePermissions[rolePermission[index].handle] = rolePermission[index];
            }

            restored['permissions'] = [...originalPermissions].map(item => {
                const updatedPermissions = [...item.permissions].map(permissionItem => {
                    const mixablePermission = mixablePermissions[permissionItem.handle];
                    if (mixablePermission) {
                        permissionItem.can_do = mixablePermission.can_do;
                    }
                    return permissionItem;
                });

                item.permissions = updatedPermissions;
                return item;
            });
        } else {
            restored['permissions'] = [...originalPermissions];
        }

        return restored;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
