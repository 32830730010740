<script>
import {mapGetters} from "vuex";
import {ResponseError} from "@/helper/route";

export default {
  name: "GlobalPermissionModal",
  data() {
    return {
      show: false,
      message: null,
      code: null
    };
  },
  computed: {
    ...mapGetters('router', {
      currentRouteError: 'error'
    })
  },
  watch: {
    currentRouteError() {
      this.updateStatus();
    }
  },
  methods: {
    updateStatus() {
      if (!this.show && this.currentRouteError instanceof ResponseError) {
        this.show = true;
        this.message = this.currentRouteError.message;
        this.code = this.currentRouteError.code;
        this.$store.commit('router/setCurrentRouteError', null);
      }
    }
  }
}
</script>

<template>
  <b-modal v-model="show" title="Access denied" hide-footer hide-header centered no-close-on-backdrop no-close-on-esc>
    <div class="permission-denied">
      <div class="pd-status-code">{{code}}</div>
      <div class="pd-message-title">We are sorry...</div>
      <p class="pd-message-body">{{message}}</p>
      <button type="button" class="btn btn-green mx-0 my-0" @click.prevent="show=false">Go back</button>
    </div>
  </b-modal>
</template>

<style scoped>

</style>