import Vue from 'vue'
import Router from './extend_vue_router';

import {routes as authRoutes} from "@/routers/routes/authenticated/_mix_all_authenticated";
import {routes as errorRoutes} from "@/routers/routes/errorRoutes";
import {routes as guestRoutes} from "@/routers/routes/guestRoutes";

Vue.use(Router);

// console.log(authRoutes, errorRoutes, guestRoutes)
export const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        ...guestRoutes,
        ...authRoutes,
        ...errorRoutes,
    ]
});


