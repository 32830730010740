import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/reports/web-pages-applications",
        name: "web-pages-applications",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Monitoring", breadcrumb: 'Web Monitoring List' },
        component: lazyLoad("protected/Monitoring/Web-page-logs")
    },
    {
        path: "/reports/student-activity-history",
        name: "activity-history",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Activity Log", breadcrumb: 'Activity History' },
        component: lazyLoad("protected/Monitoring/Student-activity-history")
    },
    {
        path: "/reports/import-file-log-history",
        name: "import-file-log",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"certification-add", breadcrumb: 'Import file log list' },
        component: lazyLoad("protected/Monitoring/ImportLogHistory")
    },
    {
        path: "/reports/import-file-details-log-history/:source_id",
        name: "import-file-details-log",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"certification-add", breadcrumb: {
                label: "Details Import File Data List",
                parent: "import-file-log"
            } },
        component: lazyLoad("protected/Monitoring/ImportLogDetailsHistory")
    },
];
