import _ from "lodash";
import Api from "@/service/api/_mix_api";

const state = {
    referring_provider: [],
    referring_provider_details: {},
    query: {
        only_trashed: false
    },
};
const mutations = {
    setReferringProvider(state, data) {
        state.referring_provider = [...data];
    },
    setReferringProviderDetails(state, data) {
        state.referring_provider_details = data;
    },
    setExistingReferingProvider(state, payload) {
        state.referring_provider[payload.index] = payload.data;
    },
    setNewReferingProvider(state, data) {
        state.referring_provider = data;
    },
    setQuery(state, payload) {
        state.query = { ...payload};
    },
};
const actions = {
    fetch(context, payload) {
        const newQuery = _.cloneDeep(payload || {});
        const isForced = !!payload;
        if (isForced || _.isEmpty(context.getters.referring_provider)) {
            context.commit('setQuery', newQuery);
            return Api.referring_provider.index(newQuery)

            .then( response => { 
                context.commit('setReferringProvider', [... response.data.data]) 
            })
        }
    },
    referringProviderDetails(context, payload) {
        const isForced = !!payload.isForced;
        if (isForced || _.isEmpty(context.getters.referring_provider_details)) {
            return Api.referring_provider.show(payload.uuid)
            .then( response => { 
                context.commit('setReferringProviderDetails', {... response.data.data}) 
            })
        }
    }
};
const getters = {
    referring_provider: state => state.referring_provider,
    referring_provider_details: state => state.referring_provider_details,
    query: (state) => ({
        only_trashed: false,
        ...state.query
    }),
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}