<template>
  <div id="app">
    <AuthenticatedLayout v-if="authenticated">
      <router-view />
    </AuthenticatedLayout>
    <GuestLayout v-else>
      <router-view />
    </GuestLayout>
    <HelpDeskWidget />
    <GlobalToast />
    <GlobalPermissionModal />
  </div>
</template>

<script>
import HelpDeskWidget from "@/components/commons/HelpDesk/HelpDeskWidget.vue";
import {mapGetters} from "vuex";
import AuthenticatedLayout from "@/components/layouts/authenticated/AuthenticatedLayout.vue";
import GuestLayout from "@/components/layouts/guest/GuestLayout.vue";
import GlobalToast from "@/components/commons/Layout/Notifications/GlobalToast.vue";
import GlobalPermissionModal from "@/components/commons/Layout/Notifications/GlobalPermissionModal.vue";
export default {
  name: "App",
  metaInfo() {
    return {
      lang: 'en-US',
      meta: [
        {charset: 'utf-8'},
        {equiv: 'Content-Type', content: 'text/html'}
      ]
    }
  },
  components: {
    GlobalPermissionModal,
    GlobalToast,
    GuestLayout,
    AuthenticatedLayout,
    HelpDeskWidget
  },
  computed: {
    ...mapGetters({
      authenticated: 'authenticated',
      middleware: 'router/middleware'
    })
  },
  watch: {
    authenticated(newValue, oldValue) {
      newValue !== oldValue && this.coreRouteRedirect()
    }
  },
  methods: {
    // This logic is here to sync the redirection for multiple browser tabs and windows.
    // Please don't remove this logic.
    coreRouteRedirect() {
      if (this.middleware === 'login' && !this.authenticated) {
        this.$router.push({path: '/'});
      } else if (this.middleware === 'guest' && this.authenticated) {
        this.$router.push({path: '/dashboard'});
      }
    }
  }
};
</script>
