import store from "@/store";
import {router} from "@/routers/utils/setup";
import {Redirect, ResponseType, ResponseError, getMiddleware} from "@/helper/route";
import authMiddleware from "@/routers/middleware/auth"
import guestMiddleware from "@/routers/middleware/guest"
router.beforeResolve((to, from, next) => {
    const toMiddlewareSlug = getMiddleware(to);
    let middlewareResponse = new ResponseType(true, from, to);
    store.commit('router/setFullPathTo', to.fullPath);
    store.commit('router/setFullPathFrom', from.fullPath);
    store.commit('router/setCurrentRouteMiddleware', toMiddlewareSlug);
    if (toMiddlewareSlug === 'auth') {
        middlewareResponse = authMiddleware(to, from);
    }
    if (toMiddlewareSlug === 'guest') {
        middlewareResponse = guestMiddleware(to, from);
    }
    if (middlewareResponse instanceof ResponseType) {
        if (middlewareResponse.permitted()) {
            next();
        } else {
            const error = new ResponseError('The page you\'re trying to access has restricted access. Please refer to your system administrator.', 403);
            next(error)

        }
    }
    else if (middlewareResponse instanceof Redirect) {
        store.commit('router/setCurrentRoutePermissionDenied', false);
        next({
            path: middlewareResponse.redirectTo()
        })
    } else {
        next();
    }
});
router.onError((e) => {
    if (e instanceof ResponseError) {
        store.commit('router/setCurrentRouteError', e);
        return;
    }
    console.warn(e);
})
export default router;
