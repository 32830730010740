import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/classes",
        name: "classes",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Classes", breadcrumb: 'Classes' },
        component: lazyLoad("protected/organization/Classes/ClassList")
    },
    {
        path: "/classes/add",
        name: "class-add",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Classes", breadcrumb: {
                label: "Add Class",
                parent: "classes"
            } },
        component: lazyLoad("protected/organization/Classes/ClassAdd")
    },
    {
        path: "/classes/edit/:class_id",
        name: "class-edit",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Classes", breadcrumb: {
                label: "Edit Class",
                parent: "classes"
            } },
        component: lazyLoad("protected/organization/Classes/ClassEdit")
    },

    {
        path: "/staff-document-preview/:file_id",
        name: "staff-document-preview",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"organization-list", breadcrumb: {
                label: "Document Preview",
                parent: "profile"
            } },
        component: lazyLoad("protected/StaffDocumentsPreview")
    },

    {
        path: "/profile-document-preview",
        name: "profile-document-preview",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile", breadcrumb: {
                label: "Document Preview",
                parent: "profile"
            } },
        component: lazyLoad("protected/ProfileDocumentPreview")
    },
];
