import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/profile",
        meta: {middleware: "auth"},
        children: [
            {
                path: "",
                name: 'profile',
                meta: {middleware: "auth"},
                component: lazyLoad('pages/user/Profile/ProfileBasicDetails')
            },
            {
                path: "update-password",
                name: 'profile.update-password',
                meta: {middleware: "auth"},
                component: lazyLoad('pages/user/Profile/ProfileUpdatePassword')
            },
            {
                path: "update-availability",
                name: "profile.update-availabilities",
                meta: {middleware: "auth", user_permission:"only-staff"},
                component: lazyLoad('pages/user/Profile/ProfileUpdateAvailability')
            },
            {
                path: "update-address",
                name: "profile.update-address",
                meta: {middleware: "auth"},
                component: lazyLoad('pages/user/Profile/ProfileUpdateAddress')
            },
            {
                path: 'update-signature',
                name: "profile.update-signature",
                meta: {middleware: "auth"},
                component: lazyLoad('pages/user/Profile/ProfileUpdateSignature')
            }
        ],
        component: lazyLoad('pages/user/Profile/Profile')
    },
    {
        path: "/profile-signature/:user_id",
        name: "profile-signature",
        meta: { middleware: "login"},
        component: lazyLoad('protected/ProfileSignature')
    },
    {
        path: "/profile-availability/:user_id",
        name: "profile-availability",
        meta: { middleware: "login"},
        component: lazyLoad('protected/ProfileAvailability')
    },
    {
        path: "/profile-documents/:user_id",
        name: "profile-documents",
        meta: { middleware: "login"},
        component: lazyLoad('protected/ProfileDocuments')
    },
];
