import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: '/npi_details',
        name: 'npi_details.index',
        meta: { middleware: "auth",user_permission:"" },
        component: lazyLoad('pages/staff/parts/show/generalInfo/NpiDetailsShow')
    },
];
