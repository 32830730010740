import Vue from 'vue'
import _ from 'lodash';
import axios from 'axios';
// window.Vue = Vue;

import Tags from './components/commons/InputTagsElement.vue'
// Register the Tags component globally
Vue.component('Tags', Tags)

// Jquery bind with vue
Vue.use({
    install: function(Vue, options){
        Vue.prototype.$jQuery = require('jquery');
    }
})

// Bootstrap theme (Vue bootstrap)
import { BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'


Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)



// Google map
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_MAP_KEY,
        libraries: 'places',
    }
});




// Vue toaster
import VueToast from '@/plugin/toast/index';
Vue.use(VueToast, {
    position: 'top'
});

// Portal
import PortalVue from 'portal-vue'
Vue.use(PortalVue)


// Sweet alert
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2)

// Public View Component
// import PublicVue from './views/public.vue';
// Vue.component("PublicVue", PublicVue);

// Single View Component
// import SingleVue from './views/singl.vue';
// Vue.component("SingleVue", SingleVue);

// Protected View Component
// import ProtectedVue from './views/protected.vue';
import AuthenticatedLayout from "@/components/layouts/authenticated/AuthenticatedLayout.vue";
Vue.component("ProtectedVue", AuthenticatedLayout);

// Input tag component
import InputTag from 'vue-input-tag';
Vue.component('input-tag', InputTag)

// Multi select
import Multiselect from 'vue-multiselect';
Vue.component('multiselect', Multiselect)

// Date picker
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import enUS from 'vue2-datepicker/locale/en';
DatePicker.locale('en-US', enUS);
Vue.use(DatePicker);

// Time picker
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
Vue.component("VueTimepicker",VueTimepicker)

// Model
import VModal from 'vue-js-modal'
Vue.use(VModal)

// Drag and drop
import VueDragDrop from 'vue-drag-drop';
Vue.use(VueDragDrop)

// Vue tooltip
import VTooltip from 'v-tooltip';
Vue.use(VTooltip)

// Production tips false
Vue.config.productionTip = false

// Custom Filtering with pagination and soft delete plugin
import FilteringWithPaginationRoute from "@/service/FilteringWithPaginationRoute";
Vue.use(FilteringWithPaginationRoute);

// HTML Meta tags and information
// Ref: https://vue-meta.nuxtjs.org/guide/#package-manager
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

// Fontawesome v6
import "@/font-awesome";


// ------------------- Deprecated
// import jQuery from 'jquery'
// import popperJS from 'vue-popperjs'
// import 'bootstrap/dist/js/bootstrap.min.js'
// import paceProgress from 'pace-progressbar'
// import 'pace-progressbar/themes/blue/pace-theme-barber-shop.css'
//import NProgress from 'vue-nprogress'

//import $axios from './axiosInstance'












// import VueCompositionAPI from '@vue/composition-api'









// export const bus = new Vue();
// window.Vue = Vue;




//Vue.use(DataTable)
// Vue.use(popperJS)
// Vue.use(paceProgress)
// Vue.use(_)
// Vue.use(VueCompositionAPI)
//Vue.use(jQuery)
// axios.interceptors.request.use(
//   paceProgress.start()
// )
