import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/program",
        name: "programs",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"program-list" },
        component: lazyLoad("protected/organization/Program/program-list")
    },
    {
        path: "/add-program",
        name: "add-program",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"program-add" },
        component: lazyLoad("protected/organization/Program/program-add")
    },
    {
        path: "/edit-program/:program_id",
        name: "edit-program",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"program-edit"},
        component: lazyLoad("protected/organization/Program/program-edit")
    },
    {
        path: "/delete-program",
        name: "delete-program",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"program-list" },
        component: lazyLoad("protected/organization/Program/program-delete")
    },
    {
        path: "/import-program",
        name: "import-program",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"program-list"},
        component: lazyLoad("protected/organization/Program/Import-Program")
    },
];
