import HTTP from "@/service/api/_axios_setup";

export default {
    index: (uuid) => HTTP.get('/student/'+uuid+'/student-agency'),
    create: (uuid, payload) => HTTP.post('/student/'+uuid+'/student-agency', payload),
    show: (uuid, id) => HTTP.get('/student/'+uuid+'/student-agency/'+id),
    update: (uuid, id, payload) => HTTP.post('/student/'+uuid+'/student-agency/'+id, payload),
    delete: (uuid, id, payload) => HTTP.delete('/student/'+uuid+'/student-agency/'+id, payload),
    address: (id) => HTTP.get('/student-agency/'+id+'/student-agency-address'),
    addaddress: (id, payload) => HTTP.post('/student-agency/'+id+'/student-agency-address', payload),
    payers: (id) => HTTP.get('/student-agency/'+id+'/student-payer'),
}