<template>
  <GuestAuthenticationLayout header="Forgot your password?"
              subtitle="Enter your email below and we will send you instructions on how to change your password.">
    <ValidationObserver ref="form" v-slot="{ handleSubmit }" v-if="!hasCompleted">
      <form name="form" @submit.prevent="handleSubmit(forgotPassword)">
        <ValidationProvider name="username" rules="required" v-slot="{ errors }">
          <div class="form-group">
            <label class="font-weight-bold">Email</label>
            <InputText v-model="form.username"
                       name="email"
                       type="email"
                       :error="errors[0]"
                       placeholder="Enter email address"/>
          </div>
        </ValidationProvider>
        <b-overlay :show="buttonLoading" rounded="lg" opacity="0.6">
          <button type="submit" class="btn btn-green btn-block text-white mx-0">Send</button>
        </b-overlay>
      </form>
    </ValidationObserver>
    <b-alert v-if="hasCompleted && statusMessage" show variant="info">
      <p class="m-0">{{statusMessage}}</p>
    </b-alert>
    <div class="mt-4">
      Return to
      <router-link :to="{ name: 'home' }" class="text-primary font-weight-bold">Sign in</router-link>
    </div>
    <HelpDeskWidget/>
  </GuestAuthenticationLayout>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import Api from '@/service/api/_mix_api';
import HelpDeskWidget from "@/components/commons/HelpDesk/HelpDeskWidget.vue";
import GuestAuthenticationLayout from "@/components/layouts/guest/GuestAuthenticationLayout.vue";
import InputText from "@/components/commons/InputText.vue";
import Vue from "vue";
import {mapGetters} from "vuex";

export default {
  components: {
    InputText,
    GuestAuthenticationLayout,
    HelpDeskWidget,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      form: {
        username: ''
      },
      buttonLoading: false,
      hasCompleted: false,
      statusMessage: null
    }
  },
  computed: {
    ...mapGetters({
        authenticated: 'authenticated'
    })
  },
  mounted() {
    this.hasCompleted = false;
  },
  methods: {
    forgotPassword() {
      if (!this.authenticated) {
        this.statusMessage = null;
        this.buttonLoading = true;
        Api.authentication.forgotPassword(this.form.username)
            .then(response => {
              if (response.status >= 200 && response.status < 300) {
                this.hasCompleted = true;
                this.statusMessage = response.data.msg;
              }
              if (response.status === 422) {
                this.$refs.form.setErrors({...response.data.errors});
              }
            })
            .catch(error => {
              error.message && Vue.$toast.open({message: error.message, type: 'error'});
            }).finally(() => this.buttonLoading = false);
      } else {
        // This redirection is required for multiple tab logic. When the used logged in from other tab.
        this.$router.push({name: 'dashboard'});
      }
    }
  }
}
</script>

