import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/parent-service-line/:user_id",
        name: "parent-service-list",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile"},
        component: lazyLoad("protected/ParentsServiceLine/Parents-Serviceline")
    },
    {
        path: "/parent/add-service-line/:user_id",
        name: "parent-service-add",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile"},
        component: lazyLoad("protected/ParentsServiceLine/Add-parents-Serviceline")
    },
    {
        path: "/parent/edit-service-line/:service_line_id",
        name: "parent-service-edit",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile"},
        component: lazyLoad("protected/ParentsServiceLine/Edit-parents-Serviceline")
    },
];
