import {routes as userProfileRoutes} from "@/routers/routes/authenticated/user_profile";
import {routes as agencyRoutes} from "@/routers/routes/authenticated/agency";
import {routes as funderRoutes} from "@/routers/routes/authenticated/funder";
import {routes as dashboardRouters} from "@/routers/routes/authenticated/dashboard";
// import {routes as payerCasesRoutes} from "@/routers/routes/authenticated/payer_cases";
import {routes as packageRoutes} from "@/routers/routes/authenticated/package";
import {routes as serviceRoutes} from "@/routers/routes/authenticated/service";
import {routes as serviceListRoutes} from "@/routers/routes/authenticated/service_line";
import {routes as GoalRoutes} from "@/routers/routes/authenticated/goal";
import {routes as StudentRoutes} from "@/routers/routes/authenticated/student";
import {routes as StaffUserRoutes} from "@/routers/routes/authenticated/staff_user";
import {routes as TestRoutes} from "@/routers/routes/authenticated/test"
// Depricated
// import {routes as StaffServiceRoutes} from "@/routers/routes/authenticated/staff_service";
import {routes as BillingCodeRoutes} from "@/routers/routes/authenticated/billing_code";
import {routes as DiagnosisRoutes} from "@/routers/routes/authenticated/diagnosis";
import {routes as ProgramRoutes} from "@/routers/routes/authenticated/program";
import {routes as AssessmentCategoryRoutes} from "@/routers/routes/authenticated/assessment_category";
import {routes as AssessmentRoutes} from "@/routers/routes/authenticated/assessment";
import {routes as PayerServiceLineRoutes} from "@/routers/routes/authenticated/payer_service_line";
import {routes as PayerBillingCodeRoutes} from "@/routers/routes/authenticated/payer_biiling_code";
import {routes as CertificateRoutes} from "@/routers/routes/authenticated/certificate";
// Depricated
// import {routes as GuardianRoutes} from "@/routers/routes/authenticated/guardian";
import {routes as GuardianServiceLineRoutes} from "@/routers/routes/authenticated/guardian_sevice_line";
import {routes as StageStatusMasterRoutes} from "@/routers/routes/authenticated/stage_status_master";
import {routes as CheckListMasterRoutes} from "@/routers/routes/authenticated/check_list_master";
import {routes as DynamicFieldRoutes} from "@/routers/routes/authenticated/dynamic_field";
import {routes as RoleRoutes} from "@/routers/routes/authenticated/role";
import {routes as CalendarRoutes} from "@/routers/routes/authenticated/calendar";
import {routes as RethinkImportStatusRoutes} from "@/routers/routes/authenticated/rethink_import_status";
import {routes as GroupScheduleRoutes} from "@/routers/routes/authenticated/group_schedule";
import {routes as GoalGeneratorRoutes} from "@/routers/routes/authenticated/goal_generator";
import {routes as GoalAttributesRoutes} from "@/routers/routes/authenticated/goal_attributes";
import {routes as MessageRoutes} from "@/routers/routes/authenticated/message";
import {routes as LocationRoutes} from "@/routers/routes/authenticated/location";
import {routes as SessionTypeRoutes} from "@/routers/routes/authenticated/session_type";
import {routes as SessionMasterRoutes} from "@/routers/routes/authenticated/session_master";
import {routes as SessionFormRoutes} from "@/routers/routes/authenticated/session_form";
import {routes as ReminderRoutes} from "@/routers/routes/authenticated/reminder";
import {routes as BehaviourRoutes} from "@/routers/routes/authenticated/behavior_category";
import {routes as ProgramTargetTypeRoutes} from "@/routers/routes/authenticated/progam_target_type";
import {routes as TemplateFormRoutes} from "@/routers/routes/authenticated/template_form";
import {routes as CaseDocumentRoutes} from "@/routers/routes/authenticated/case_document";
import {routes as SessionFormCategoryRoutes} from "@/routers/routes/authenticated/session_form_category";
import {routes as SkillAreaRoutes} from "@/routers/routes/authenticated/skill_area";
import {routes as PayCodeRoutes} from "@/routers/routes/authenticated/pay_code";
import {routes as KareoFunderRoutes} from "@/routers/routes/authenticated/kareo_funder";
import {routes as CertificationTypeRoutes} from "@/routers/routes/authenticated/certification_type";
import {routes as BcbaTaskRoutes} from "@/routers/routes/authenticated/bcba_task";
import {routes as SessionTemplateRoutes} from "@/routers/routes/authenticated/session_template";
import {routes as TargetAccuracyRoutes} from "@/routers/routes/authenticated/target_accuracy";
import {routes as TargetPromptRoutes} from "@/routers/routes/authenticated/target_promt_code";
import {routes as CoverageTypeRoutes} from "@/routers/routes/authenticated/coverage_type";
import {routes as ReinforcementRoutes} from "@/routers/routes/authenticated/reinforcement";
import {routes as GroupTemplateRoutes} from "@/routers/routes/authenticated/group_template_lession";
import {routes as PromptCodeRoutes} from "@/routers/routes/authenticated/prompt_code";
import {routes as AutoGenerateSessionRoutes} from "@/routers/routes/authenticated/auto_geneate_session";
import {routes as DynamicSessionTemplateRoutes} from "@/routers/routes/authenticated/dynamic_session_template";
import {routes as DynamicPDFSessionTemplateRoutes} from "@/routers/routes/authenticated/dynamic_pdf_session_template";
import {routes as InvoiceDataRoutes} from "@/routers/routes/authenticated/invoice_data";
import {routes as MassGoalImportRoutes} from "@/routers/routes/authenticated/mass_goal_import";
import {routes as UserLogListDetailsRoutes} from "@/routers/routes/authenticated/user_log_list_details";
import {routes as GraphModuleRoutes} from "@/routers/routes/authenticated/graph_module";
import {routes as WorkFlowRoutes} from "@/routers/routes/authenticated/work_flow";
import {routes as PdfDocumentEditorRoutes} from "@/routers/routes/authenticated/pdf_document_editor";
import {routes as GroupRoutes} from "@/routers/routes/authenticated/groups";
import {routes as ClassRoutes} from "@/routers/routes/authenticated/class";
import {routes as ErrorListRoutes} from "@/routers/routes/authenticated/error_list";
import {routes as FileManagementRoutes} from "@/routers/routes/authenticated/file_management";
import {routes as FolderRoutes} from "@/routers/routes/authenticated/folder";
import {routes as FileRoutes} from "@/routers/routes/authenticated/file";
import {routes as UserGroupRoutes} from "@/routers/routes/authenticated/user_group";
import {routes as CheckListFormTemplateRoutes} from "@/routers/routes/authenticated/check_list_form_template";
import {routes as StageStatusRoutes} from "@/routers/routes/authenticated/stage_status";
//Depricated
// import {routes as StudentContactRoutes} from "@/routers/routes/authenticated/student_contact";
// import {routes as StudentAssessmentRoutes} from "@/routers/routes/authenticated/student_assesment";
// import {routes as StudentDiagnosisRoutes} from "@/routers/routes/authenticated/student_diagnosis";
// import {routes as StudentServiceOrFunderRoutes} from "@/routers/routes/authenticated/student_services_or_funders";
// import {routes as StudentAuthorizationRoutes} from "@/routers/routes/authenticated/student_authorization";
// import {routes as StudentAvailabilityRoutes} from "@/routers/routes/authenticated/student_availability";
// import {routes as StudentFileRoutes} from "@/routers/routes/authenticated/student_file";
// import {routes as StudentBehaviorRoutes} from "@/routers/routes/authenticated/student_behavior";
// import {routes as StudentProgramRoutes} from "@/routers/routes/authenticated/student_program";
// import {routes as StudentBehaviourDataEntryRoutes} from "@/routers/routes/authenticated/student_behavior_data_entry";
// import {routes as StudentIntakeFormRoutes} from "@/routers/routes/authenticated/student_intake_form_entry";
// import {routes as StudentPromptRoutes} from "@/routers/routes/authenticated/student_prompt_schedule";
// import {routes as StudentActivityHistoryRoutes} from "@/routers/routes/authenticated/student_activity_history";
// import {routes as StudentSessionTemplateRoutes} from "@/routers/routes/authenticated/student_session_template";
// import {routes as StudentSettingRoutes} from "@/routers/routes/authenticated/student_settings";
// import {routes as StudentGoalRoutes} from "@/routers/routes/authenticated/student_goal";
// import {routes as StudentPromptCodeRoutes} from "@/routers/routes/authenticated/student_prompt_code";
// import {routes as StudentStaffRoutes} from "@/routers/routes/authenticated/student_staff";
import {routes as placeOfServiceRoutes} from "@/routers/routes/authenticated/place_of_service";
import {routes as GuardiansRoutes} from "@/routers/routes/authenticated/guardian_user";
import {routes as Authorization} from "@/routers/routes/authenticated/authorization";
import {routes as NpiChecking} from "@/routers/routes/authenticated/npi_checking";
import {routes as ReferringProvider} from "@/routers/routes/authenticated/referring_provider";

export const routes = [
    ...userProfileRoutes,
    // ...organizationRoutes,
    ...agencyRoutes,
    ...funderRoutes,
    // ...payerCasesRoutes,
    ...packageRoutes,
    ...serviceRoutes,
    ...serviceListRoutes,
    ...GoalRoutes,
    ...StudentRoutes,
    ...StaffUserRoutes,
    // Depricated
    // ...StaffServiceRoutes,
    ...BillingCodeRoutes,
    ...DiagnosisRoutes,
    ...ProgramRoutes,
    ...AssessmentCategoryRoutes,
    ...AssessmentRoutes,
    ...PayerServiceLineRoutes,
    ...PayerBillingCodeRoutes,
    ...CertificateRoutes,
    // Depricated
    // ...GuardianRoutes,
    ...GuardiansRoutes,
    ...GuardianServiceLineRoutes,
    ...StageStatusMasterRoutes,
    ...CheckListMasterRoutes,
    ...DynamicFieldRoutes,
    ...RoleRoutes,
    ...CalendarRoutes,
    ...RethinkImportStatusRoutes,
    ...GroupScheduleRoutes,
    ...GoalGeneratorRoutes,
    ...GoalAttributesRoutes,
    ...MessageRoutes,
    ...LocationRoutes,
    ...SessionTypeRoutes,
    ...SessionMasterRoutes,
    ...SessionFormRoutes,
    ...ReminderRoutes,
    ...BehaviourRoutes,
    ...ProgramTargetTypeRoutes,
    ...TemplateFormRoutes,
    ...CaseDocumentRoutes,
    ...SessionFormCategoryRoutes,
    ...SkillAreaRoutes,
    ...PayCodeRoutes,
    ...BcbaTaskRoutes,
    ...SessionTemplateRoutes,
    ...TargetAccuracyRoutes,
    ...TargetPromptRoutes,
    ...CoverageTypeRoutes,
    ...ReinforcementRoutes,
    ...GroupTemplateRoutes,
    ...PromptCodeRoutes,
    ...AutoGenerateSessionRoutes,
    ...DynamicSessionTemplateRoutes,
    ...DynamicPDFSessionTemplateRoutes,
    ...InvoiceDataRoutes,
    ...MassGoalImportRoutes,
    ...UserLogListDetailsRoutes,
    ...GraphModuleRoutes,
    ...WorkFlowRoutes,
    ...PdfDocumentEditorRoutes,
    ...GroupRoutes,
    ...ClassRoutes,
    ...FileManagementRoutes,
    ...FolderRoutes,
    ...FileRoutes,
    ...UserGroupRoutes,
    ...CheckListFormTemplateRoutes,
    ...StageStatusRoutes,
    ...KareoFunderRoutes,
    ...CertificationTypeRoutes,
    //Depricated
    // ...StudentContactRoutes,
    // ...StudentAssessmentRoutes,
    // ...StudentDiagnosisRoutes,
    // ...StudentServiceOrFunderRoutes,
    // ...StudentAuthorizationRoutes,
    // ...StudentAvailabilityRoutes,
    // ...StudentFileRoutes,
    // ...StudentProgramRoutes,
    // ...StudentBehaviourDataEntryRoutes,
    // ...StudentIntakeFormRoutes,
    // ...StudentPromptRoutes,
    // ...StudentActivityHistoryRoutes,
    // ...StudentSessionTemplateRoutes,
    // ...StudentStaffRoutes,
    // ...StudentSettingRoutes,
    // ...StudentGoalRoutes,
    // ...StudentPromptCodeRoutes,
    // ...StudentBehaviorRoutes,
    ...ErrorListRoutes,
    ...placeOfServiceRoutes,
    ...dashboardRouters,
    ...Authorization,
    ...NpiChecking,
    ...TestRoutes,
    ...ReferringProvider,
];
