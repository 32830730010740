import _ from "lodash";
import Api from "@/service/api/_mix_api";

const state = {
    certification: {},
};
const mutations = {
    setCertification(state, payload) {
        state.certification = {...payload};
    }
};
const actions = {
    fetch(context, payload) {    
        const isForced = !!(payload?.forced || false);    
        if (isForced || _.isEmpty(context.getters.certification)) {
            return Api.certification.show(payload.id).then((response) => {
                if (response.status === 200) {
                    context.commit('setCertification', {...response.data.data});
                }
            })
        }
    }
};
const getters = {
    certification: (state) => state.certification || {},
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
