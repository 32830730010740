<script>
export default {
  name: "InputText",
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number, null],
    },
    error: {
      type: String,
      default() {
        return null;
      },
    },
    success: {
      type: String,
      default() {
        return null;
      },
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>


<template>
  <div>
    <input
        :type="$attrs?.type || 'text'"
        v-bind="$attrs"
        :disabled="isDisabled"
        v-model="modelValue"
        @keyup="$emit('keyup', $event)"
        class="form-control"
        :class="{'is-invalid': !!error, 'is-valid': !!success && !error && value}"
    />
    <div class="invalid-feedback" v-if="!!error">{{ error }}</div>
  </div>
</template>

<style scoped>

</style>
