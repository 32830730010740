import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/add-assessment/",
        name: "add-assessment",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"assessments-category-add" },
        component: lazyLoad("protected/organization/Assessment/add-assessment")
    },
    {
        path: "/assessment/",
        name: "assessment",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"assessments-category-list" },
        component: lazyLoad("protected/organization/Assessment/assessment")
    },
    {
        path: "/edit-assessment/:assessment_id",
        name: "edit-assessment",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"assessments-category-edit"},
        component: lazyLoad("protected/organization/Assessment/edit-assessment")
    },
    {
        path: "/delete-assessment",
        name: "delete-assessment",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"assessments-category-list" },
        component: lazyLoad("protected/organization/Assessment/delete-assessment")
    },
];
