import _ from "lodash";
import Api from "@/service/api/_mix_api";
const state = {
    agencyTypes: []
};
const mutations = {
    setAgencyTypes(state, payload) {
        state.agencyTypes = [ ...payload ];
    }
};
const actions = {
    fetch(context, payload) {
        const isForced = !!payload;
        if (isForced || _.isEmpty(context.getters.agencyTypes)) {
            return Api.agency_type.index().then((response) => {
                if (response.status === 200) {
                    context.commit('setAgencyTypes', [...response.data.data]);
                }
            })
        }
    }
};
const getters = {
    agencyTypes: state => state.agencyTypes || []
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
