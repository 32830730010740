import _ from "lodash";
import Api from "@/service/api/_mix_api";
import httpErrorHandler from "@/httpErrorHandler";

const state = {
    isFetching: false,
    rendering_provider_type: [],
};
const mutations = {
    setIsFetching(state, payload) {
        state.isFetching = !!payload;
    },
    setRenderingProviderType(state, data) {
        state.rendering_provider_type = data;
    },
};
const actions = {
    getRenderingProviderType(context, query) {
        const isForced = !!query;
        if (isForced || _.isEmpty(context.getters.rendering_provider_type)) {
            context.commit('setIsFetching', true);
            Api.rendering_provider_type.index(query)
            .then( response => {
                if (response.status >= 200 && response.status < 300) {
                    context.commit('setRenderingProviderType', [... response.data.data])
                }
            }).finally(() => context.commit('setIsFetching', false))
        }
    },
};
const getters = {
    isFetching: state => state.isFetching || false,
    rendering_provider_type: state => state.rendering_provider_type || [],
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}