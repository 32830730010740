import HTTP from "@/service/api/_axios_setup";

export default {
    index: (query) => HTTP.get('/role', {params: (query || null)}),
    permission(params) {
        return  HTTP.get('/permission', params)
    },
    store(payload) {
        return HTTP.post('/role', payload)
    },
    update(uuid, payload) {
        return HTTP.put('/role/'+uuid, payload)
    },
    destroy(uuid) {
        return HTTP.delete('/role/'+uuid)
    },
    show: (uuid) => HTTP.get('/role/'+uuid)
}
