import _ from "lodash";
import Api from "@/service/api/_mix_api";
import httpErrorHandler from "@/httpErrorHandler";

const state = {
    isFetching: false,
    student_services: [],
};
const mutations = {
    setIsFetching(state, payload) {
        state.isFetching = !!payload;
    },
    setStudentServices(state, data) {
        state.student_services = [...data];
    },
};
const actions = {
    getAllStudentServices(context, payload) {
        context.commit('setIsFetching', true);
        Api.student_services.index(payload.agency_uuid , payload.agency_payer_service_line_id)
        .then( response => {
            if (response.status >= 200 && response.status < 300) {
                context.commit('setStudentServices', [...response.data.data])
            }
        }).finally(() => context.commit('setIsFetching', false))
    },
};
const getters = {
    isFetching: state => state.isFetching || false,
    student_services: state => state.student_services || [],
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}