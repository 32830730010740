<script>
import SidenavMenuItemIcon from "@/components/layouts/authenticated/parts/Sidenav/SidenavMenuItemIcon.vue";
import _ from "lodash";

export default {
  name: "SidenavMenuItem",
  components: {SidenavMenuItemIcon},
  props: {
    item: {
      type: Object,
      required: true
    },
    handle: {
      type: String,
      required: true
    }
  },
  computed: {
    hasRoute() {
      return !this.item.route;
    },
    hasChildren() {
      return !_.isEmpty(this.item?.children);
    },
    hasOpen() {
      return !!this.item?.has_open;
    },
    menuClass() {
      return {
        'with-children': !_.isEmpty(this.item?.children),
        'is-active': this.item?.active,
        'open': this.item?.has_open || null
      };
    }
  },
  methods: {
    toggleOpen() {
      if (this.item?.children && this.item?.children.length > 0) {
        this.$store.commit('sidenav/toggleHasOpen', this.handle);
      }
    }
  }
}
</script>

<template>
  <div class="menu-link-item" :class="menuClass">
    <div class="menu-link-item-action">
      <router-link v-if="!hasRoute" :to="item.route" class="menu-link" :class="{'active': item?.active}">
        <SidenavMenuItemIcon :icon="item.icon" />
        <span class="menu-item-label"> <span class="menu-label">{{item.label}}</span> </span>
      </router-link>
      <div class="menu-link" role="link" :class="{'active': item?.active}" @click.prevent="toggleOpen" v-else>
        <SidenavMenuItemIcon :icon="item.icon" />
        <span class="menu-item-label">
        <span class="menu-label">{{item.label}}</span>
      </span>
      </div>
    </div>
    <div class="menu-link-item-suffix" v-if="hasChildren">
      <font-awesome-icon :icon="['fas', hasOpen ? 'angle-up' : 'angle-down']" size="1x" />
    </div>
  </div>
</template>

<style scoped>

</style>
