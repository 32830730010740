import { lazyLoad } from "@/routers/utils/helper";

export const routes = [
    {
        path: "/place-of-service",
        name: "place-of-service.index",
        meta: { middleware: "auth", user_permission: null},
        component: lazyLoad("pages/place-of-service/Index")
    },
    {
        path: "/place-of-service/create",
        name: "place-of-service.create",
        meta: {
            middleware: "auth", user_permission: null
        },
        component: lazyLoad("pages/place-of-service/parts/index/AddPlaceOfService")
    },
    {
        path: "/place-of-service/:place_of_service_id",
        redirect: { name: 'place-of-service.show' },
        name: "place-of-service.edit",
        meta: {
            middleware: "auth", user_permission: null
        },
        component: lazyLoad("pages/place-of-service/show/Layout"),
        children: [
            {
                path: "",
                name: "place-of-service.show",
                meta: {
                    middleware: "auth", user_permission: null
                },
                component: lazyLoad('pages/place-of-service/parts/index/EditPlaceOfService')
            },
        ]
    }
];
