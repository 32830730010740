import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/dynamic-field",
        name: "dynamic-field",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"dynamic-fields-list" },
        component: lazyLoad("protected/organization/DynamicField/Dynamic-field")
    },
    {
        path: "/add-dynamic-field",
        name: "add-dynamic-field",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"dynamic-fields-add" },
        component: lazyLoad("protected/organization/DynamicField/Add-dynamic-field")
    },
    {
        path: "/edit-dynamic-field/:dynamic_field_id",
        name: "edit-dynamic-field",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"dynamic-fields-edit"},
        component: lazyLoad("protected/organization/DynamicField/Edit-dynamic-field")
    },
    {
        path: "/delete-dynamic-field",
        name: "delete-dynamic-field",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"dynamic-fields-list"},
        component: lazyLoad("protected/organization/DynamicField/Delete-dynamic-field")
    },
];
