import _ from "lodash";

const state = {
    fullPathTo: null,
    fullPathFrom: null,
    currentRouteMiddleware: null,
    currentRouteError: null
};
const mutations= {
    setFullPathTo(state, payload) {
        state.fullPathTo = payload;
    },
    setFullPathFrom(state, payload) {
        state.fullPathFrom = payload;
    },
    setCurrentRouteMiddleware(state, payload) {
        state.currentRouteMiddleware = _.isString(payload) && payload.trim() !== "" ? payload : null;
    },
    setCurrentRouteError(state, payload) {
        state.currentRouteError = payload;
    }
}
const actions = {

};
const getters = {
    middleware: state => state.currentRouteMiddleware,
    error: state => state.currentRouteError
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
