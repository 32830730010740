import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/add-reminder/",
        name: "add-reminder",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"certification-add", breadcrumb: {
                label: "Add Reminder",
                parent: "reminders"
            } },
        component: lazyLoad("protected/organization/Reminder/Add-reminder")
    },
    {
        path: "/edit-reminder/:reminder_id",
        name: "edit-reminder",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"certification-edit", breadcrumb: {
                label: "Edit Reminder",
                parent: "reminders"
            } },
        component: lazyLoad("protected/organization/Reminder/Edit-reminder")
    },
    {
        path: "/reminders/",
        name: "reminders",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"certification-list", breadcrumb: 'Reminders'},
        component: lazyLoad("protected/organization/Reminder/List-reminder")
    },
];
