import _ from "lodash";
import Api from "@/service/api/_mix_api";
import httpErrorHandler from "@/httpErrorHandler";

const state = {
    isFetching: false,
    authorization_submission_type: [],
};
const mutations = {
    setIsFetching(state, payload) {
        state.isFetching = !!payload;
    },
    setAuthorizationSubmissionType(state, data) {
        state.authorization_submission_type = data;
    },
};
const actions = {
    getSubmissionType(context, query) {
        const isForced = !!query;
        if (isForced || _.isEmpty(context.getters.authorization_submission_type)) {
            context.commit('setIsFetching', true);
            Api.authorization_submission_type.index(query)
            .then( response => {
                if (response.status >= 200 && response.status < 300) {
                    context.commit('setAuthorizationSubmissionType', [... response.data.data])
                }
            }).finally(() => context.commit('setIsFetching', false))
        }
    },
};
const getters = {
    isFetching: state => state.isFetching || false,
    authorization_submission_type: state => state.authorization_submission_type || [],
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}