import axios from "axios";
import response_error_with_status_code, {proxyValidStatus} from "@/service/api/error_handler/response_error_with_status_code";
import store from "@/store";
import _ from "lodash";
import Vue from "vue";
import _axios_refresh_token from "@/service/api/_axios_refresh_token";
const HTTP = axios.create({
    baseURL: `${process.env.VUE_APP_BASE_URL}/api/`,
});
// Create a list to hold the request queue
const refreshAndRetryQueue = [];

// Flag to prevent multiple token refresh requests
let isRefreshing = false;
const handleResponseErrors = (error) => {
    if (_.has(error, 'response.status')) {
        return response_error_with_status_code(error);
    } else if (_.has(error, 'code') && _.has(error, 'message')) {
        Vue.$toast.open({id: 'api-error', message: _.get(error, 'message'), type: 'error'});
        return Promise.resolve(error);
    }
    return Promise.reject(error);
}
HTTP.interceptors.request.use((config) => {
    // Without 'application/json' the back-end not able to understand it's required to response in JSON format.
    config.headers.Accept = "application/json";
    // Auth bearer token for logged in users.
    if (!!store.getters.accessToken) {
        config.headers.authorization = `Bearer ${store.getters.accessToken}`;
    }
    // If the data is an instance of form data, set content type 'multiplart/form-data'.
    if (['POST', 'PUT', 'DELETE'].includes(config.method.toUpperCase()) && config.data instanceof FormData) {
        config.headers['Content-Type'] = 'multipart/form-data';
        if (!config.data.get('_method')) {
            config.data.append('_method', config.method);
        }
        config.method = 'post';
    }
    config.validateStatus = function (status) {
        return (status >= 200 && status < 300);
    };
    return config;
});
HTTP.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (_.has(error, 'response')) {
            const originalRequest = error.config;
            if (error.response.status === 401 && !originalRequest?._retry && !originalRequest?._forced_logout) {
                originalRequest._retry = true;
                if (!isRefreshing) {
                    isRefreshing = true;
                    try {
                        await navigator.locks.request("auth_token_refresh", async (lock) => {
                            const refreshTokenResponse = await _axios_refresh_token();
                            originalRequest.headers.authorization = `Bearer ${refreshTokenResponse.data.data.access_token}`;
                            // Retry all requests in the queue with the new token
                            refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
                                HTTP.request(config)
                                    .then((response) => resolve(response))
                                    .catch((err) => reject(err));
                            });
                            refreshAndRetryQueue.length = 0;
                        });
                        return HTTP(originalRequest);
                    } catch (e) {
                        console.log('Refresh token request error', e);
                        return handleResponseErrors(error);
                    } finally {
                        isRefreshing = false
                    }
                }
                // Add the original request to the queue
                return new Promise((resolve, reject) => {
                    refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
                });
            } else {
                return handleResponseErrors(error);
            }
        }
        return handleResponseErrors(error);
    }
);
export default HTTP;
