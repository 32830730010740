import _ from "lodash";
import Api from "@/service/api/_mix_api";

const state = {
    user_types: []
};
const mutations = {
    setUserType(state, data) {
        state.user_types = data;
    }
};
const actions = {
    getUserTypes(context, query) {
        const isForced = !!query;
        if (isForced || _.isEmpty(context.getters.user_types)) {
            Api.user_group.user_type(query)
            .then( response => { 
                context.commit('setUserType', [... response.data.data]) 
            })
        }
    }
};
const getters = {
    user_types: state => state.user_types,
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}