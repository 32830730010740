import Vue from 'vue';
import store from "@/store";
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import _ from "lodash";
window.Pusher = Pusher;
window.echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_ABLY_PUBLIC_KEY,
    wsHost: 'realtime-pusher.ably.io',
    wsPort: 443,
    disableStats: false,
    encrypted: true,
    cluster: 'eu'
})
export default {
    mounted() {
        window.echo.channel('role')
            .listen('.saved', (event) => {
                const authUserRoleHandle = store.getters.role?.handle || null;
                const {handle} = event;
                _.isEqual(authUserRoleHandle, handle) && store.dispatch('remoteUpdateScope');
            });
        window.echo.channel('user')
            .listen('.saved', (event)=> {
                const authUserId = _.toNumber(store.getters.user?.id || 0);
                const {id} = event;
                _.isEqual(_.toNumber(id), authUserId) && store.dispatch('remoteUpdateScope');
            });
        window.echo.channel('user-group')
            .listen('.saved', (event)=> {
                const authUserGroupHandle = store.getters.user?.user_group?.handle;
                const {handle} = event;
                _.isEqual(handle, authUserGroupHandle) && store.dispatch('remoteUpdateScope');
            });
    }
}