import { lazyLoad } from "@/routers/utils/helper";
const menu = (routeUuid) => {
    return;
};
export const routes = [
    {
        path: '/student',
        name: 'student.index',
        meta: {
            middleware: "auth", user_permission: [
                'student.view-all',
                'student.view-only-assigned',
                'student.view-only-matched-agency-address',
                'student.view-only-matched-agency'
            ]
        },
        component: lazyLoad('pages/student/Index')
    },
    {
        path: "/add-student",
        name: "student.create",
        meta: { middleware: "auth", user_permission: "student.add-or-edit" },
        component: lazyLoad("pages/student/Create")
    },
    {
        path: "/student/:student_id",
        meta: {
            middleware: "auth",
            user_permission: [
                'student.view-all',
                'student.view-only-assigned',
                'student.view-only-matched-agency-address',
                'student.view-only-matched-agency'
            ]
        },
        component: lazyLoad("pages/student/show/Layout"),
        children: [
            {
                path: "",
                name: 'student.show',
                component: lazyLoad('pages/student/show/GeneralInfo'),
                meta: {
                    middleware: "auth", user_permission: [
                        'student.view-all',
                        'student.view-only-assigned',
                        'student.view-only-matched-agency-address',
                        'student.view-only-matched-agency'
                    ]
                },
            },
            {
                path: "authorization",
                name: 'student.authorization',
                // meta: { middleware: "auth", user_permission: null },
                component: lazyLoad('pages/student/show/Authorization'),
            },
            {
                path: "availability",
                name: 'student.availability',
                // meta: { middleware: "auth", user_permission: null },
                component: lazyLoad('pages/student/show/Availability'),
            },
            {
                path: "staff",
                name: 'student.satff',
                meta: { middleware: "auth", user_permission: "student.staff.view" },
                component: lazyLoad('pages/student/show/StaffAssign'),
            },
            {
                path: "referring-provider",
                name: 'student.referring-provider.index',
                meta: { middleware: "auth", user_permission: "student.referring-provider.view" },
                component: lazyLoad('pages/student/show/ReferringProvider'),
            },
            {
                path: "add-referring-provider",
                name: 'student.referring-provider.create',
                meta: { middleware: "auth", user_permission: "student.referring-provider.edit" },
                component: lazyLoad('pages/student/parts/show/referring_provider/AddReferingProvider'),
            },
            {
                path: "edit-referring-provider/:referring_provider_id",
                name: 'student.referring-provider.show',
                meta: { middleware: "auth", user_permission: "student.referring-provider.view" },
                component: lazyLoad('pages/student/parts/show/referring_provider/EditReferingProvider'),
            },
            {
                path: "agency",
                name: 'student.agency',
                meta: { middleware: "auth", user_permission: "student.agency.view" },
                component: lazyLoad('pages/student/show/Agency'),
            },
            {
                path: "funder",
                name: 'student.funder',
                meta: { middleware: "auth", user_permission: "student.funder.view" },
                component: lazyLoad('pages/student/show/Funder'),
            },
            {
                path: "contacts",
                name: 'student.contacts',
                // meta: { middleware: "auth", user_permission: null },
                component: lazyLoad('pages/student/show/Contacts'),
            },
            {
                path: "add-contacts",
                name: 'student.contacts.create',
                // meta: { middleware: "auth", user_permission: null },
                component: lazyLoad('pages/student/parts/show/contacts/contact-add'),
            },
            {
                path: "contacts/:contact_id",
                name: 'student.contacts.show',
                // meta: { middleware: "auth", user_permission: null },
                component: lazyLoad('pages/student/parts/show/contacts/contact-edit'),
            },
        ]
    },
    {
        path: "/student/:student_id/authorization-add",
        name: 'student.authorization.create',
        component: lazyLoad('pages/student/parts/show/authorization/AuthorizationAdd'),
    },
    {
        path: "/student/:student_id/authorization-edit/:authorization_id",
        name: 'student.authorization.edit',
        component: lazyLoad('pages/student/parts/show/authorization/AuthorizationEdit'),
    },
    // {
    //     path: "/student/:student_id/diagnosis",
    //     name: "student.diagnosis",
    //     meta: {
    //         middleware: "auth", user_permission: "students-diagnosis-view", group: "student-diagnosis"
    //     },
    //     component: lazyLoad("pages/student/show/Diagnosis")
    // },
    // {
    //     path: "/student/:student_id/promptcode",
    //     name: "student.promptcode",
    //     meta: {
    //         middleware: "auth", user_permission: "Student List", group: "student-promptcode"
    //     },
    //     component: lazyLoad("pages/student/show/Promptcode")
    // },
    // {
    //     path: "/student/:student_id/funder",
    //     name: "student.funder",
    //     meta: {
    //         middleware: "auth", user_permission: "students-funder-view", group: "student-funder"
    //     },
    //     component: lazyLoad("pages/student/show/Funder")
    // },
    // {
    //     path: "/student/:student_id/activity-history",
    //     name: "student.history",
    //     meta: {
    //         middleware: "auth", group: "student-activity-history"
    //     },
    //     component: lazyLoad("pages/student/show/ActivityHistory")
    // }
];
