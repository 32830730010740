
export const routes = [
    {
        path: '/login',
        name: 'home',
        meta: { middleware : 'guest'},
        component: require("@/components/pages/auth/Login.vue").default // load sync home
    },
    // {
    //     path: '/org-login/:id/:token',
    //     name: 'org-login',
    //     meta: { layout: "PublicVue" , header :'o-header--no-bg-color', middleware : 'guest'},
    //     component: require("@/components/pages/auth/OrgListLogin.vue").default // load sync home
    // },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        meta: {middleware : 'guest'},
        component: require("@/components/pages/auth/ForgotPassword.vue").default // load sync home
    },
    {
        path: '/reset-password/:token/:encrypted_email/:encrypted_uuid',
        name: 'reset-password',
        meta: {middleware : 'guest'},
        component: require("@/components/pages/auth/ResetPassword.vue").default // load sync home
    },
];
