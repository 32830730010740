import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/payer-billing-code/:payer_id",
        name: "add-payer-billing-code",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"payer-billingcode-add",group:"payer-billing-code", breadcrumb: {
                label: "Add New Funder Billing Code",
                parent: "payer-plans"
            } },
        component: lazyLoad("protected/organization/PayerBillingCode/Add-payer-billingcode")
    },
    {
        path: "/payers/billing-code/:payer_id",
        name: "payer-billing-code",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"payer-billingcode-list",group:"payer-billing-code", breadcrumb: {
                label: "Funder Billing Code",
                parent: "payer-plans"
            } },
        component: lazyLoad("protected/organization/PayerBillingCode/Payer-billingcode")
    },
    {
        path: "/payers-billing-code/:billing_code_id",
        name: "edit-payer-billing-code",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"payer-billingcode-edit",group:"payer-billing-code", breadcrumb: {
                label: "Edit Billing Code",
                parent: "payer-plans"
            }},
        component: lazyLoad("protected/organization/PayerBillingCode/Edit-payer-billingcode")
    },
];
