import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/user-group-title",
        name: "title.index",
        meta: { middleware: "auth", user_permission: null },
        component: lazyLoad("pages/staff-title/Index")
    },
    // {
    //     path: "/add-user-group",
    //     name: "add-user-group",
    //     meta: { middleware: "login",user_permission:"role-add"},
    //     component: lazyLoad("protected/organization/UserGroup/add-group")
    // },
    // {
    //     path: "/edit-user-group/:group_id",
    //     name: "edit-user-group",
    //     meta: { middleware: "login",user_permission:"role-edit"},
    //     component: lazyLoad("protected/organization/UserGroup/edit-group")
    // },
];
