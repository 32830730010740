import router from "@/routers/router"
import _ from "lodash";
import store from "@/store";

export const menuPermission = (to) => {
    if (_.isString(to) || _.isObject(to)) {
        return _.get(router.match(to), 'meta.user_permission', true)
    }
    return null;
};
export const menuCombinePermissions = (menu) => {
    if (!_.isEmpty(menu)) {
        return [...menu].map(item => {
            return {
                ...item,
                permissionName: menuPermission(item?.to)
            }
        })
    }
    return [];
};
export const menuFilterWithPermission = (menu) => {
    if (!_.isEmpty(menu)) {
        return menuCombinePermissions([...menu]).filter(item => {
            if(_.isBoolean(item.permissionName)) {
                return item.permissionName;
            }
            if (_.isNull(item.permissionName)) {
                return store.getters.typeAdmin;
            }
            if (_.isArray(item.permissionName)) {
                return store.getters.canAny(item.permissionName);
            }
            return store.getters.can(item.permissionName);
        })
    }
    return [];
}