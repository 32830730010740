import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/target-promo-code",
        name: "target-promo-code",
        meta: { layout: "ProtectedVue", middleware: "auth", sidebar: "profile", user_permission: "hcodes-list", breadcrumb: 'Target Promo Code' },
        component: lazyLoad("protected/organization/TargetPromoCode/target-promo-code-list")
    },
    {
        path: "/add-target-promo-code",
        name: "add-target-promo-code",
        meta: { layout: "ProtectedVue", middleware: "auth", sidebar: "profile", user_permission: "hcodes-add", breadcrumb: {
                label: "Add New Target Promo Code",
                parent: "target-promo-code"
            } },
        component: lazyLoad("protected/organization/TargetPromoCode/target-promo-code-add")
    },
    {
        path: "/edit-target-promo-code/:tpc_id",
        name: "edit-target-promo-code",
        meta: { layout: "ProtectedVue", middleware: "auth", sidebar: "profile", user_permission: "hcodes-edit", breadcrumb: {
                label: "Edit Target Promo Code",
                parent: "target-promo-code"
            } },
        component: lazyLoad("protected/organization/TargetPromoCode/target-promo-code-edit")
    },
    {
        path: "/delete-target-promo-code",
        name: "delete-target-promo-code",
        meta: { layout: "ProtectedVue", middleware: "auth", sidebar: "profile", user_permission: "hcodes-list", breadcrumb: {
                label: "Deleted Target Promo Code",
                parent: "target-promo-code"
            } },
        component: lazyLoad("protected/organization/TargetPromoCode/target-promo-code-delete")
    },
];
