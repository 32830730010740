import _ from "lodash";
export const keyBoardEventIsCharacterType = (event) => {
    if (typeof event.which == "undefined") {
        // This is IE, which only fires keypress events for printable keys
        return true;
    } else if (typeof event.which == "number" && event.which > 0) {
        const isGeneralNumber = event.which >= 48 && event.which <= 59;
        const isAlpha = event.which >= 65 && event.which <= 90
        const isNumberPad = event.which >= 96 && event.which <= 105;
        return !event.ctrlKey && !event.metaKey && !event.altKey && (isGeneralNumber || isAlpha || isNumberPad);
    }
    return false;
}
export const keyBoardEventBackSpace = (event) => {
    return typeof event.which !== 'undefined' && (event.which === 8 || event.which === '8');
}
export const keyBoardEventArrowUp = (event) => {
    return typeof event.which !== 'undefined' && (event.which === 38 || event.which === '38');
}
export const keyBoardEventArrowDown = (event) => {
    return typeof event.which !== 'undefined' && (event.which === 40 || event.which === '40');
}
export const keyBoardEventArrowLeft = (event) => {
    return typeof event.which !== 'undefined' && (event.which === 37 || event.which === '37');
}
export const keyBoardEventArrowRight = (event) => {
    return typeof event.which !== 'undefined' && (event.which === 39 || event.which === '39');
}
export const keyBoardEventEnter = (event) => {
    return typeof event.which !== 'undefined' && (event.which === 13 || event.which === '13');
}
const includes = (str, query) => {
    /* istanbul ignore else */
    if (str === undefined) str = 'undefined'
    if (str === null) str = 'null'
    if (str === false) str = 'false'
    const text = str.toString().toLowerCase()
    return text.indexOf(query.trim()) !== -1
}
const includesInObject = (item, query, allowed_depth, current_depth) => {
    return Object.keys(item)
        .map(_i => {
            if (_.isString(item) || _.isNumber(item)) {
                return includes(item, query);
            }
            if (allowed_depth > current_depth && _.isObject(item[_i])) {
                return includesInObject(item[_i], current_depth);
            }
            return false;
        })
        .filter(_i => !!_i).length > 0;
}
export const searchInArray = (data, query, depth) => {
    depth = depth || 1;
    query = (query || '').toString().toLowerCase();
    return [...data].filter(item => {
        if (_.isString(item) || _.isNumber(item)) {
            return includes(item, query);
        }
        if (_.isObject(item)) {
            return includesInObject(item, query, depth, 1)
        }
        return false;
    });
}

export class TimeUtils {
    regex = /^\d{2}:\d{2}$/
    getValidTime(time) {
        if (time && _.isString(time) && this.regex.test(time)) {
            return time;
        }
    }

}