import {lazyLoad} from "@/routers/utils/helper";

export const routes = [
    {
        path: "/calendar",
        name: "calendar",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"organization-list"},
        component: lazyLoad("protected/calendar/calendar")
    },
    {
        path: "/global-calendar/:filter_date?",
        name: "global-calendar",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Calendar / List View", breadcrumb: 'Calendar'},
        component: lazyLoad("protected/calendar/GlobalCalendar")
    },
    {
        path: "/create-appointment",
        name: "create-appointment",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"session-form-template-add"},
        component: lazyLoad("protected/calendar/createAppointment")
    },
    {
        path: "/edit-appointment/:id",
        name: "edit-appointment",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"session-form-template-add"},
        component: lazyLoad("protected/calendar/createAppointment")
    },
    {
        path: "/appointment-list",
        name: "appointment-list",
        meta: { layout: "ProtectedVue",middleware: "auth",sidebar: "profile",user_permission:"Calendar / List View", breadcrumb: 'Appointments'},
        component: lazyLoad("protected/calendar/AppointmentList")
    },
];
