<script>
import {mapGetters} from "vuex";
import { TransitionSlide } from '@morev/vue-transitions';
let timeoutID = null;
export default {
  name: "GlobalToast",
  components: {
    TransitionSlide
  },
  computed: {
    ...mapGetters('global_notifications', {
      activeToastID: 'activeToastID',
      toasts: 'toasts',
      toast: 'activeToast'
    })
  },
  watch: {
    toast(){
      this.openToast();
    }
  },
  methods: {
    openToast() {
      if (this.toast) {
        timeoutID = setTimeout(() => {
          this.$store.dispatch('global_notifications/removeToast', {...this.toast});
        }, 3000);
      }
    },
    cancelToast() {
      !!timeoutID && clearTimeout(timeoutID);
      timeoutID = null;
      if (this.toast) {
        this.$store.dispatch('global_notifications/removeToast', {...this.toast});
      }
    },
    mouseEnter() {
      !!timeoutID && clearTimeout(timeoutID);
      timeoutID = null;
    }
  },
  mounted() {
    this.openToast();
  }
}
</script>

<template>
  <div class="app-alert-toast-area" v-if="toasts.length">
    <TransitionSlide group>
      <div class="app-alert-toast-container" v-for="toastItem in toasts" :key="toastItem.id" v-if="toastItem.id === activeToastID">
        <div class="app-alert-toast" :class="[toast.position]" @mouseenter="mouseEnter" @mouseleave="openToast">
          <div class="app-alert-toast--content" :class="[toast.type]">
            <div class="toast-message">{{toast.message}}</div>
            <div class="toast-action">
              <button type="button" @click.prevent="cancelToast">
                <font-awesome-icon :icon="['fas', 'circle-xmark']" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </TransitionSlide>
  </div>
</template>

<style scoped>

</style>